import { GraphQLResolveInfo } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum AbiCodeType {
  AbiListOptions = 'ABI_LIST_OPTIONS',
  Genders = 'GENDERS',
  Industries = 'INDUSTRIES',
  Jobs = 'JOBS',
  Titles = 'TITLES'
}

export type AbiCodes = {
  __typename?: 'AbiCodes';
  abiListOptions: Array<AbiListItem>;
  all: Array<Scalars['String']['output']>;
  genders: Array<AbiListItem>;
  industries: Array<AbiListItem>;
  jobs: Array<AbiListItem>;
  titles: Array<AbiListItem>;
};


export type AbiCodesAbiListOptionsArgs = {
  abiCode: Scalars['String']['input'];
};

export type AbiListItem = {
  __typename?: 'AbiListItem';
  vt: Scalars['String']['output'];
  vtCode: Scalars['String']['output'];
  vtDescription: Scalars['String']['output'];
};

export enum AccidentDescription {
  HitByThirdPartyInFront = 'HitByThirdPartyInFront',
  HitByThirdPartyInRear = 'HitByThirdPartyInRear',
  HitByThirdPartyInSide = 'HitByThirdPartyInSide',
  HitByThirdPartyWhenParked = 'HitByThirdPartyWhenParked',
  HitByThirdPartyWhenReversing = 'HitByThirdPartyWhenReversing',
  HitParkedThirdParty = 'HitParkedThirdParty',
  HitThirdPartyInFront = 'HitThirdPartyInFront',
  HitThirdPartyInRear = 'HitThirdPartyInRear',
  HitThirdPartyInSide = 'HitThirdPartyInSide',
  HitThirdPartyWhenParked = 'HitThirdPartyWhenParked',
  HitThirdPartyWhenReversing = 'HitThirdPartyWhenReversing',
  NoOtherVehicle = 'NoOtherVehicle'
}

export type AdditionalDriver = {
  __typename?: 'AdditionalDriver';
  businessMileage?: Maybe<Scalars['Int']['output']>;
  claims: Array<PolicyClaim>;
  classOfUse?: Maybe<ClassOfUse>;
  convictions: Array<PolicyConviction>;
  createdOn: Scalars['String']['output'];
  dateOfBirth: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  hasCancellations: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isMainDriver: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  licence: Licence;
  maritalStatus: MaritalStatus;
  medicalConditionsDeclared: Scalars['Boolean']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  primaryEmployment: Employment;
  relationshipToPolicyHolder: RelationshipToProposer;
  secondaryEmployment?: Maybe<Employment>;
  title: Scalars['String']['output'];
  ukResidencyDurationMonths: Scalars['Int']['output'];
  updatedOn: Scalars['String']['output'];
};

export type AdditionalDriverDetails = {
  name: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  firstLine: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  firstLine: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
};

export type AddressQuery = {
  __typename?: 'AddressQuery';
  findAddress: Array<FindAddressResult>;
  retrieveAddress: RetrieveAddressResult;
};


export type AddressQueryFindAddressArgs = {
  params: FindAddressParams;
};


export type AddressQueryRetrieveAddressArgs = {
  id: Scalars['String']['input'];
};

export enum AgentWorkflowStage {
  AwaitingDocuments = 'AWAITING_DOCUMENTS',
  PendingCancellation = 'PENDING_CANCELLATION',
  PolicyCancelled = 'POLICY_CANCELLED',
  PolicyVerified = 'POLICY_VERIFIED',
  ReadyToReview = 'READY_TO_REVIEW',
  Reviewing = 'REVIEWING',
  WithCustomer = 'WITH_CUSTOMER'
}

export enum Aggregator {
  CompareNi = 'COMPARE_NI'
}

export type AggregatorQuoteDetails = {
  __typename?: 'AggregatorQuoteDetails';
  /** @deprecated Replaced by compulsoryExcessAsPrice - to be removed once Money is implemented */
  compulsoryExcess: Scalars['Float']['output'];
  compulsoryExcessAsPrice: Price;
  insurer: Scalars['String']['output'];
  insurerSchemeReference: Scalars['String']['output'];
  magicLinkId: Scalars['String']['output'];
  premiumAsPrice: Price;
  /** @deprecated Replaced by premiumPriceBreakdownDetails - to be removed once Money is implemented */
  premiumPriceBreakdown?: Maybe<QuotePremiumPriceBreakdown>;
  premiumPriceBreakdownDetails?: Maybe<QuotePremiumPriceBreakdownDetails>;
  /** @deprecated Replaced by premiumAsPrice - to be removed once Money is implemented */
  price: Scalars['Float']['output'];
  quoteGenerationDate: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  referredBy?: Maybe<Aggregator>;
  sequenceNumber: Scalars['String']['output'];
  totalExtrasPrice?: Maybe<Price>;
  totalUnfinancedAmountPayable: Price;
  type?: Maybe<QuoteType>;
  /** @deprecated Replaced by voluntaryExcessAsPrice - to be removed once Money is implemented */
  voluntaryExcess: Scalars['Int']['output'];
  voluntaryExcessAsPrice: Price;
};

export type AggregatorQuoteExtra = {
  __typename?: 'AggregatorQuoteExtra';
  optionType: PolicyExtraOptionType;
  type: PolicyExtraType;
};

export type AggregatorQuoteExtraInput = {
  optionType?: InputMaybe<PolicyExtraOptionType>;
  type: PolicyExtraType;
};

export type AggregatorQuoteResult = {
  __typename?: 'AggregatorQuoteResult';
  additionalDrivers?: Maybe<Array<QuoteAdditionalDriverDetails>>;
  coverDetails: QuoteCoverDetails;
  personalDetails: QuotePersonalDetails;
  quoteDetails: AggregatorQuoteDetails;
  quoteExtras?: Maybe<Array<AggregatorQuoteExtra>>;
  quoteValidationErrors?: Maybe<Array<QuoteValidationError>>;
  vehicleDetails: QuoteVehicleDetails;
};

export enum AlarmInstaller {
  Manufacturer = 'MANUFACTURER',
  ManufacturerApproved = 'MANUFACTURER_APPROVED',
  Proposer = 'PROPOSER',
  Unknown = 'UNKNOWN',
  VsibAccredited = 'VSIB_ACCREDITED'
}

export enum AlcoholTestMethod {
  Blood = 'BLOOD',
  Breath = 'BREATH',
  Urine = 'URINE'
}

export type ApplePayFinancedPolicySetUp = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  installmentAmount: Scalars['Float']['input'];
  installments: Scalars['Int']['input'];
  loanSetUp: LoanSetUpRequest;
  payment: ApplePayPaymentRequest;
  proposedPolicy: ProposedPolicyRequest;
};

export type ApplePayFinancedPolicySetUpRequest = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  loanSetUp: LoanSetUpRequest;
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  payment: ApplePayPaymentRequest;
  proposerEmail: Scalars['String']['input'];
  proposerPhoneNumber: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type ApplePayFinancedProcessRenewalRequest = {
  loanRequest: LoanRequest;
  payment: ApplePayPaymentRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type ApplePayFullPolicySetUp = {
  payment: ApplePayPaymentRequest;
  proposedPolicy: ProposedPolicyRequest;
};

export type ApplePayFullPolicySetUpRequest = {
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  payment: ApplePayPaymentRequest;
  proposerEmail: Scalars['String']['input'];
  proposerPhoneNumber: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type ApplePayFullProcessRenewalRequest = {
  payment: ApplePayPaymentRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type ApplePayHeader = {
  ephemeralPublicKey: Scalars['String']['input'];
  publicKeyHash: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

export type ApplePayPaymentRequest = {
  token: ApplePayPaymentToken;
};

export type ApplePayPaymentToken = {
  data: Scalars['String']['input'];
  header: ApplePayHeader;
  signature: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type ApplePayPolicySetupMutation = {
  __typename?: 'ApplePayPolicySetupMutation';
  setUpFinancedPolicy: FinancedPolicyPaymentResponse;
  setUpFullPolicy: FullPolicyPaymentResponse;
};


export type ApplePayPolicySetupMutationSetUpFinancedPolicyArgs = {
  request: ApplePayFinancedPolicySetUpRequest;
};


export type ApplePayPolicySetupMutationSetUpFullPolicyArgs = {
  request: ApplePayFullPolicySetUpRequest;
};

export type ApplePayProcessRenewalMutation = {
  __typename?: 'ApplePayProcessRenewalMutation';
  processFinancedRenewal: FinancedPolicyPaymentResponse;
  processFullRenewal: FullPolicyPaymentResponse;
};


export type ApplePayProcessRenewalMutationProcessFinancedRenewalArgs = {
  request: ApplePayFinancedProcessRenewalRequest;
};


export type ApplePayProcessRenewalMutationProcessFullRenewalArgs = {
  request: ApplePayFullProcessRenewalRequest;
};

export type ApplePaySessionResponse = {
  __typename?: 'ApplePaySessionResponse';
  displayName: Scalars['String']['output'];
  domainName: Scalars['String']['output'];
  epochTimestamp: Scalars['Float']['output'];
  expiresAt: Scalars['Float']['output'];
  merchantIdentifier: Scalars['String']['output'];
  merchantSessionIdentifier: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  operationalAnalyticsIdentifier: Scalars['String']['output'];
  retries: Scalars['Int']['output'];
  signature: Scalars['String']['output'];
};

export enum AtFault {
  NoBlame = 'NoBlame',
  Partial = 'Partial',
  Pending = 'Pending',
  Yes = 'Yes'
}

export type BankDetailsQuery = {
  __typename?: 'BankDetailsQuery';
  validateBankDetails: ValidateBankDetailsResult;
};


export type BankDetailsQueryValidateBankDetailsArgs = {
  params: ValidateBankDetailsParams;
};

export enum BillingPeriod {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

export enum BreakInCoverDeclaration {
  Accepted = 'ACCEPTED',
  NotAccepted = 'NOT_ACCEPTED',
  NotRequired = 'NOT_REQUIRED'
}

export type ClaimMutation = {
  __typename?: 'ClaimMutation';
  submitClaim: Result;
};


export type ClaimMutationSubmitClaimArgs = {
  explanation: Scalars['String']['input'];
  incidentDate: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  policyId: Scalars['String']['input'];
};

export enum ClaimStatus {
  Pending = 'PENDING',
  Settled = 'SETTLED'
}

export type ClassOfUse = {
  __typename?: 'ClassOfUse';
  hasAnothersBusiness: Scalars['Boolean']['output'];
  hasCommuting: Scalars['Boolean']['output'];
  hasEmployersBusiness: Scalars['Boolean']['output'];
  hasPersonalBusiness: Scalars['Boolean']['output'];
  hasSoliciting: Scalars['Boolean']['output'];
};

export type CloseBrothersDocumentQuery = {
  __typename?: 'CloseBrothersDocumentQuery';
  directDebits: Scalars['String']['output'];
  preContract: Scalars['String']['output'];
  qAndA: Scalars['String']['output'];
};

export type CompanyCarExperience = {
  companyCarExperienceEndDate: Scalars['String']['input'];
  yearsNoClaimsBonus: Scalars['Int']['input'];
};

export type CompanyCarExperienceDetails = {
  __typename?: 'CompanyCarExperienceDetails';
  companyCarExperienceEndDate: Scalars['String']['output'];
  yearsNoClaimsBonus: Scalars['Int']['output'];
};

export type ConvictionOffenceInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  oldReference: Scalars['String']['input'];
};

export type ConvictionOffenceItem = {
  __typename?: 'ConvictionOffenceItem';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  oldReference: Scalars['String']['output'];
};

export type ConvictionOffenceItemInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  oldReference: Scalars['String']['input'];
};

export type ConvictionTypeInput = {
  id: Scalars['String']['input'];
  parentCategory: Scalars['String']['input'];
  parentCategoryDescription: Scalars['String']['input'];
};

export type ConvictionTypeItem = {
  __typename?: 'ConvictionTypeItem';
  id: Scalars['String']['output'];
  parentCategory: Scalars['String']['output'];
  parentCategoryDescription: Scalars['String']['output'];
};

export type ConvictionTypeItemInput = {
  id: Scalars['String']['input'];
  parentCategory: Scalars['String']['input'];
  parentCategoryDescription: Scalars['String']['input'];
};

export type ConvictionsQuery = {
  __typename?: 'ConvictionsQuery';
  offences: Array<ConvictionOffenceItem>;
  types: Array<ConvictionTypeItem>;
};


export type ConvictionsQueryOffencesArgs = {
  typeId: Scalars['String']['input'];
};

export enum CountryOfIssue {
  Other = 'OTHER',
  RepublicOfIreland = 'REPUBLIC_OF_IRELAND',
  UnitedKingdom = 'UNITED_KINGDOM'
}

export type CoverDetails = {
  __typename?: 'CoverDetails';
  annualPremium: Scalars['Int']['output'];
  breakInCoverDeclaration: BreakInCoverDeclaration;
  coverType: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  excess: Excess;
  firstStartDate: Scalars['String']['output'];
  /** @deprecated Moved to policy holder, this is required for old versions (< 1.12) of the mobile app */
  noClaimsBonusYears?: Maybe<Scalars['String']['output']>;
  noClaimsProtection: Scalars['Boolean']['output'];
  number?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
};

export enum CoverType {
  Comprehensive = 'Comprehensive',
  ThirdParty = 'Third_Party',
  ThirdPartyFireAndTheft = 'Third_Party_Fire_and_Theft'
}

export type Document = {
  __typename?: 'Document';
  fileSize?: Maybe<Scalars['Int']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  type: DocumentType;
  url: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export enum DocumentType {
  CertificateAndScheduleOfInsurance = 'CERTIFICATE_AND_SCHEDULE_OF_INSURANCE',
  CourtesyCar = 'COURTESY_CAR',
  ExcessInsurance = 'EXCESS_INSURANCE',
  ExcessInsuranceScheduleOfInsurance = 'EXCESS_INSURANCE_SCHEDULE_OF_INSURANCE',
  InsuranceProductInformation = 'INSURANCE_PRODUCT_INFORMATION',
  LegalExpenses = 'LEGAL_EXPENSES',
  NoClaimsBonusProtection = 'NO_CLAIMS_BONUS_PROTECTION',
  PolicyBooklet = 'POLICY_BOOKLET',
  PolicySummary = 'POLICY_SUMMARY',
  RoadsideAssistancePlatinum = 'ROADSIDE_ASSISTANCE_PLATINUM',
  SmartfobKeycare = 'SMARTFOB_KEYCARE',
  StatementOfFact = 'STATEMENT_OF_FACT',
  TermsOfBusinessAgreement = 'TERMS_OF_BUSINESS_AGREEMENT'
}

export type DocumentationQuery = {
  __typename?: 'DocumentationQuery';
  closeBrothers?: Maybe<CloseBrothersDocumentQuery>;
  policyBooklet?: Maybe<Document>;
  policyExtras?: Maybe<Array<Document>>;
  requestPrintedDocumentsV2?: Maybe<RequestPrintedDocumentsV2Result>;
  termsOfBusinessAgreement?: Maybe<Document>;
};


export type DocumentationQueryPolicyBookletArgs = {
  schemeReference: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type DocumentationQueryPolicyExtrasArgs = {
  policyExtraDocuments: Array<PolicyExtraDocument>;
};


export type DocumentationQueryRequestPrintedDocumentsV2Args = {
  params: RequestPrintedDocumentsV2Params;
};


export type DocumentationQueryTermsOfBusinessAgreementArgs = {
  version?: InputMaybe<Scalars['String']['input']>;
};

export enum DriverPosition {
  Left = 'Left',
  Right = 'Right'
}

export type DrivingBan = {
  __typename?: 'DrivingBan';
  duration: Scalars['Int']['output'];
  unit: Scalars['String']['output'];
};

export type EmailLatestSavedQuoteResult = {
  __typename?: 'EmailLatestSavedQuoteResult';
  email: Scalars['String']['output'];
};

export type Employment = {
  __typename?: 'Employment';
  industry?: Maybe<Scalars['String']['output']>;
  industryVtCode?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Scalars['String']['output']>;
  jobVtCode?: Maybe<Scalars['String']['output']>;
  status: EmploymentStatus;
};

export type EmploymentInput = {
  industry?: InputMaybe<Scalars['String']['input']>;
  industryVtCode?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<Scalars['String']['input']>;
  jobVtCode?: InputMaybe<Scalars['String']['input']>;
  status: EmploymentStatus;
};

export enum EmploymentStatus {
  Employed = 'Employed',
  FullTimeStudent = 'FullTimeStudent',
  HouseSpouse = 'HouseSpouse',
  IndependentMeans = 'IndependentMeans',
  Retired = 'Retired',
  SelfEmployed = 'SelfEmployed',
  Unemployed = 'Unemployed',
  UnemployedDueToDisability = 'UnemployedDueToDisability',
  VoluntaryWorker = 'VoluntaryWorker'
}

export type Excess = {
  __typename?: 'Excess';
  compulsory: Scalars['Int']['output'];
  voluntary: Scalars['Int']['output'];
};

export type FailedQuoteResult = {
  __typename?: 'FailedQuoteResult';
  magicLinkId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type FetchProspectiveLoansResponse = {
  __typename?: 'FetchProspectiveLoansResponse';
  extrasTotalAmount?: Maybe<Price>;
  prospectiveLoans?: Maybe<Array<ProspectiveLoanWithPrice>>;
  totalUnfinancedAmountPayable: Price;
};

export type FinanceInformation = {
  adminFee: Scalars['String']['input'];
  annualPercentageRate: Scalars['String']['input'];
  deposit: Scalars['String']['input'];
  financeFee: Scalars['String']['input'];
  interestRate: Scalars['String']['input'];
  numberOfInstalments?: InputMaybe<Scalars['Int']['input']>;
  premium: Scalars['String']['input'];
  pricePerMonth: Scalars['String']['input'];
  totalFinance: Scalars['String']['input'];
};

export type FinancedPolicyPaymentResponse = {
  __typename?: 'FinancedPolicyPaymentResponse';
  result: FinancedPolicyPaymentResult;
  threeDSecureResponse?: Maybe<ThreeDSecureResponse>;
};

export enum FinancedPolicyPaymentResult {
  PolicyStartDateExpired = 'POLICY_START_DATE_EXPIRED',
  RenewalNoticeDoesNotExist = 'RENEWAL_NOTICE_DOES_NOT_EXIST',
  Requires_3DSecure = 'REQUIRES_3D_SECURE',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  UnableToMakeDepositPayment = 'UNABLE_TO_MAKE_DEPOSIT_PAYMENT',
  UnableToProcessZeroDepositRenewal = 'UNABLE_TO_PROCESS_ZERO_DEPOSIT_RENEWAL'
}

export type FindAddressParams = {
  id?: InputMaybe<Scalars['String']['input']>;
  search: Scalars['String']['input'];
};

export type FindAddressResult = {
  __typename?: 'FindAddressResult';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isAddress: Scalars['Boolean']['output'];
};

export enum FuelType {
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  Hydrogen = 'HYDROGEN',
  Petrol = 'PETROL'
}

export type FullPolicyPaymentResponse = {
  __typename?: 'FullPolicyPaymentResponse';
  result: FullPolicyPaymentResult;
  threeDSecureResponse?: Maybe<ThreeDSecureResponse>;
};

export enum FullPolicyPaymentResult {
  PolicyStartDateExpired = 'POLICY_START_DATE_EXPIRED',
  RenewalNoticeDoesNotExist = 'RENEWAL_NOTICE_DOES_NOT_EXIST',
  Requires_3DSecure = 'REQUIRES_3D_SECURE',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  UnableToMakePayment = 'UNABLE_TO_MAKE_PAYMENT'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GooglePayFinancedPolicySetUp = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  installmentAmount: Scalars['Float']['input'];
  installments: Scalars['Int']['input'];
  loanSetUp: LoanSetUpRequest;
  payment: GooglePayPaymentRequest;
  proposedPolicy: ProposedPolicyRequest;
};

export type GooglePayFinancedPolicySetUpRequest = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  loanSetUp: LoanSetUpRequest;
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  payment: GooglePayPaymentRequest;
  proposerEmail: Scalars['String']['input'];
  proposerPhoneNumber: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type GooglePayFinancedProcessRenewalRequest = {
  loanRequest: LoanRequest;
  payment: GooglePayPaymentRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type GooglePayFullPolicySetUp = {
  payment: GooglePayPaymentRequest;
  proposedPolicy: ProposedPolicyRequest;
};

export type GooglePayFullPolicySetUpRequest = {
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  payment: GooglePayPaymentRequest;
  proposerEmail: Scalars['String']['input'];
  proposerPhoneNumber: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type GooglePayFullProcessRenewalRequest = {
  payment: GooglePayPaymentRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type GooglePayPaymentRequest = {
  token: GooglePayPaymentToken;
};

export type GooglePayPaymentToken = {
  protocolVersion: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  signedMessage: Scalars['String']['input'];
};

export type GooglePayPolicySetupMutation = {
  __typename?: 'GooglePayPolicySetupMutation';
  setUpFinancedPolicy: FinancedPolicyPaymentResponse;
  setUpFullPolicy: FullPolicyPaymentResponse;
};


export type GooglePayPolicySetupMutationSetUpFinancedPolicyArgs = {
  request: GooglePayFinancedPolicySetUpRequest;
};


export type GooglePayPolicySetupMutationSetUpFullPolicyArgs = {
  request: GooglePayFullPolicySetUpRequest;
};

export type GooglePayProcessRenewalMutation = {
  __typename?: 'GooglePayProcessRenewalMutation';
  processFinancedRenewal: FinancedPolicyPaymentResponse;
  processFullRenewal: FullPolicyPaymentResponse;
};


export type GooglePayProcessRenewalMutationProcessFinancedRenewalArgs = {
  request: GooglePayFinancedProcessRenewalRequest;
};


export type GooglePayProcessRenewalMutationProcessFullRenewalArgs = {
  request: GooglePayFullProcessRenewalRequest;
};

export type HealthCheck = {
  __typename?: 'HealthCheck';
  errorMessage?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  response?: Maybe<Scalars['String']['output']>;
  status: HealthCheckStatus;
};

export type HealthCheckResponse = {
  __typename?: 'HealthCheckResponse';
  healthChecks: Array<Maybe<HealthCheck>>;
  status: HealthCheckStatus;
};

export enum HealthCheckStatus {
  Down = 'DOWN',
  Up = 'UP'
}

export enum HealthChecks {
  CloseBrothers = 'CloseBrothers',
  GlobalPayments = 'GlobalPayments',
  HostedEquotes = 'HostedEquotes',
  InfoService = 'InfoService',
  Loqate = 'Loqate',
  UkVehicleDatabase = 'UKVehicleDatabase'
}

export type HostedPaymentPageFinancedPolicySetUp = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  installmentAmount: Scalars['Float']['input'];
  installments: Scalars['Int']['input'];
  loanSetUp: LoanSetUpRequest;
  payment: HostedPaymentPagePaymentRequest;
  proposedPolicy: ProposedPolicyRequest;
};

export type HostedPaymentPageFinancedPolicySetUpRequest = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  loanSetUp: LoanSetUpRequest;
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  payment: HostedPaymentPagePaymentRequest;
  proposerEmail: Scalars['String']['input'];
  proposerPhoneNumber: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type HostedPaymentPageFinancedProcessRenewalRequest = {
  loanRequest: LoanRequest;
  payment: HostedPaymentPagePaymentRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type HostedPaymentPageFullPolicySetUp = {
  payment: HostedPaymentPagePaymentRequest;
  proposedPolicy: ProposedPolicyRequest;
};

export type HostedPaymentPageFullPolicySetUpRequest = {
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  payment: HostedPaymentPagePaymentRequest;
  proposerEmail: Scalars['String']['input'];
  proposerPhoneNumber: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type HostedPaymentPageFullProcessRenewalRequest = {
  payment: HostedPaymentPagePaymentRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type HostedPaymentPagePaymentRequest = {
  cardholderName: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
  threeDSecureTransactionReference?: InputMaybe<Scalars['String']['input']>;
};

export type HostedPaymentPagePolicySetupMutation = {
  __typename?: 'HostedPaymentPagePolicySetupMutation';
  setUpFinancedPolicy: FinancedPolicyPaymentResponse;
  setUpFullPolicy: FullPolicyPaymentResponse;
};


export type HostedPaymentPagePolicySetupMutationSetUpFinancedPolicyArgs = {
  request: HostedPaymentPageFinancedPolicySetUpRequest;
};


export type HostedPaymentPagePolicySetupMutationSetUpFullPolicyArgs = {
  request: HostedPaymentPageFullPolicySetUpRequest;
};

export type HostedPaymentPageProcessRenewalMutation = {
  __typename?: 'HostedPaymentPageProcessRenewalMutation';
  processFinancedRenewal: FinancedPolicyPaymentResponse;
  processFullRenewal: FullPolicyPaymentResponse;
};


export type HostedPaymentPageProcessRenewalMutationProcessFinancedRenewalArgs = {
  request: HostedPaymentPageFinancedProcessRenewalRequest;
};


export type HostedPaymentPageProcessRenewalMutationProcessFullRenewalArgs = {
  request: HostedPaymentPageFullProcessRenewalRequest;
};

export enum ImportType {
  Eu = 'EU',
  NonEu = 'NonEU'
}

export enum IncidentType {
  Accident = 'Accident',
  Fire = 'Fire',
  Malicious = 'Malicious',
  Riot = 'Riot',
  Storm = 'Storm',
  Theft = 'Theft',
  Vandalism = 'Vandalism',
  Windscreen = 'Windscreen'
}

export type InsurerSchemeQuote = {
  __typename?: 'InsurerSchemeQuote';
  /** @deprecated Replaced by compulsoryExcessPrice - to be removed once Money is implemented */
  compulsoryExcess: Scalars['Float']['output'];
  compulsoryExcessPrice: Price;
  insurer: Scalars['String']['output'];
  insurerSchemeReference: Scalars['String']['output'];
  /** @deprecated Replaced by premiumPrice - to be removed once Money is implemented */
  premium: Scalars['Float']['output'];
  premiumPrice: Price;
  /** @deprecated Replaced by premiumPriceBreakdownDetails - to be removed once Money is implemented */
  premiumPriceBreakdown?: Maybe<QuotePremiumPriceBreakdown>;
  premiumPriceBreakdownDetails?: Maybe<QuotePremiumPriceBreakdownDetails>;
  quoteType: QuoteType;
  reference: Scalars['String']['output'];
  sequenceNumber: Scalars['Int']['output'];
  totalUnfinancedAmountPayable: Price;
  /** @deprecated Replaced by voluntaryExcessPrice - to be removed once Money is implemented */
  voluntaryExcess: Scalars['Int']['output'];
  voluntaryExcessPrice: Price;
};

export type Licence = {
  __typename?: 'Licence';
  countryOfIssue: CountryOfIssue;
  dateObtained?: Maybe<Scalars['String']['output']>;
  durationMonths?: Maybe<Scalars['Int']['output']>;
  type: LicenceType;
};

export type LicenceInput = {
  countryOfIssue: CountryOfIssue;
  dateObtained?: InputMaybe<Scalars['String']['input']>;
  durationMonths: Scalars['Int']['input'];
  type: LicenceType;
};

export enum LicenceType {
  Disqualified = 'DISQUALIFIED',
  Eu = 'EU',
  Foreign = 'FOREIGN',
  Full = 'FULL',
  International = 'INTERNATIONAL',
  NeverHeld = 'NEVER_HELD',
  Provisional = 'PROVISIONAL',
  Restricted_3YearsOrMore = 'RESTRICTED_3_YEARS_OR_MORE',
  RestrictedLessThan_3Years = 'RESTRICTED_LESS_THAN_3_YEARS',
  Revoked = 'REVOKED'
}

export type LoadQuoteResult = {
  __typename?: 'LoadQuoteResult';
  policyStartDate: Scalars['String']['output'];
  quoteGenerationDate: Scalars['String']['output'];
  result: SuccessfulQuoteResult;
  serialisedQuote: Scalars['String']['output'];
};

export type LoanDepositOption = {
  __typename?: 'LoanDepositOption';
  percentage: Scalars['Int']['output'];
  price: Price;
};

export type LoanRequest = {
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankSortCode?: InputMaybe<Scalars['String']['input']>;
  brokerLoanReference: Scalars['String']['input'];
  depositPercentage: Scalars['Int']['input'];
  rateReference: Scalars['String']['input'];
};

export type LoanSetUpRequest = {
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankSortCode?: InputMaybe<Scalars['String']['input']>;
  brokerLoanReference: Scalars['String']['input'];
  rateReference: Scalars['String']['input'];
};

export enum MaritalStatus {
  CivilPartnership = 'CIVIL_PARTNERSHIP',
  Divorced = 'DIVORCED',
  Estranged = 'ESTRANGED',
  Married = 'MARRIED',
  MarriedCommonLaw = 'MARRIED_COMMON_LAW',
  Partnered = 'PARTNERED',
  Separated = 'SEPARATED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export enum MedicalCondition {
  DvlaAwareNoRestrictions = 'DvlaAwareNoRestrictions',
  DvlaUnaware = 'DvlaUnaware',
  RestrictionFiveYears = 'RestrictionFiveYears',
  RestrictionOneYear = 'RestrictionOneYear',
  RestrictionThreeYears = 'RestrictionThreeYears',
  RestrictionTwoYears = 'RestrictionTwoYears'
}

export enum MerchantType {
  Closebrothers = 'CLOSEBROTHERS',
  Globalpay = 'GLOBALPAY'
}

export type MidTermAdjustmentMetadata = {
  __typename?: 'MidTermAdjustmentMetadata';
  effectiveDate: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  total: Price;
};

export enum ModificationType {
  AlloyWheels = 'AlloyWheels',
  Other = 'Other',
  WindowTint = 'WindowTint'
}

export type Mutation = {
  __typename?: 'Mutation';
  claim: ClaimMutation;
  policyHolder: PolicyHolderMutation;
  policySetup: PolicySetupMutation;
  quote: QuoteMutation;
  renewals: RenewalsMutation;
};

export type NamedDriverExperience = {
  yearsNoClaimsBonus: Scalars['Int']['input'];
};

export type NamedDriverExperienceDetails = {
  __typename?: 'NamedDriverExperienceDetails';
  yearsNoClaimsBonus: Scalars['Int']['output'];
};

export enum NoClaimsAffected {
  No = 'No',
  Protected = 'Protected',
  Yes = 'Yes'
}

export type NoClaimsDiscount = {
  __typename?: 'NoClaimsDiscount';
  averageDiscount: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type NoClaimsDiscountProtectionDataResult = {
  __typename?: 'NoClaimsDiscountProtectionDataResult';
  discounts: Array<NoClaimsDiscount>;
  stepbackRules: Array<StepbackRules>;
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  loanReference?: Maybe<Scalars['String']['output']>;
  merchant: MerchantType;
  paymentDate: Scalars['String']['output'];
  purchaseReference: Scalars['String']['output'];
  transactionInformation?: Maybe<Scalars['String']['output']>;
  type: BillingPeriod;
};

export type Policy = {
  __typename?: 'Policy';
  additionalDrivers: Array<AdditionalDriver>;
  branchReference: Scalars['String']['output'];
  clientReference: Scalars['String']['output'];
  coverDetails: CoverDetails;
  documentation: PolicyDocumentation;
  effectiveDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  insurerName: Scalars['String']['output'];
  insurerReference?: Maybe<Scalars['String']['output']>;
  metadata: PolicyMetadata;
  paymentDetails?: Maybe<PaymentDetails>;
  policyExtrasMetadata?: Maybe<Array<PolicyExtraMetadata>>;
  policyHolderId: Scalars['String']['output'];
  policyReference: Scalars['String']['output'];
  policyStatus: PolicyStatus;
  quoteDetails?: Maybe<QuoteDetails>;
  renewalNotice?: Maybe<RenewalNotice>;
  schemeReference: Scalars['String']['output'];
  vehicleDetails: PolicyVehicleDetails;
};

export type PolicyClaim = {
  __typename?: 'PolicyClaim';
  accidentDescription?: Maybe<AccidentDescription>;
  atFault: AtFault;
  bodilyInjuryOccurred: Scalars['Boolean']['output'];
  date: Scalars['String']['output'];
  incidentType: IncidentType;
  noClaimsAffected?: Maybe<NoClaimsAffected>;
  value: Scalars['Int']['output'];
  whichPolicyClaimedAgainst: WhichPolicyClaimedAgainst;
};

export type PolicyConviction = {
  __typename?: 'PolicyConviction';
  alcoholLevel?: Maybe<Scalars['Int']['output']>;
  date: Scalars['String']['output'];
  drivingBan?: Maybe<DrivingBan>;
  fine: Scalars['Int']['output'];
  offence: Scalars['String']['output'];
  parentCategoryDescription?: Maybe<Scalars['String']['output']>;
  penaltyPoints: Scalars['Int']['output'];
  testMethod?: Maybe<AlcoholTestMethod>;
  type: Scalars['String']['output'];
};

export type PolicyDocument = {
  __typename?: 'PolicyDocument';
  effectiveDate: Scalars['String']['output'];
  fileSize?: Maybe<Scalars['Int']['output']>;
  type: DocumentType;
  url: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type PolicyDocumentInput = {
  effectiveDate: Scalars['String']['input'];
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  type: DocumentType;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type PolicyDocumentation = {
  __typename?: 'PolicyDocumentation';
  active?: Maybe<Array<PolicyDocument>>;
  archived?: Maybe<Array<PolicyDocument>>;
  upcoming?: Maybe<Array<PolicyDocument>>;
};

export type PolicyExtraCost = {
  __typename?: 'PolicyExtraCost';
  gross: Scalars['String']['output'];
  net: Scalars['String']['output'];
  tax: Scalars['String']['output'];
};

export type PolicyExtraDocument = {
  type: PolicyExtraType;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type PolicyExtraMetadata = {
  __typename?: 'PolicyExtraMetadata';
  endDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  optionType?: Maybe<PolicyExtraOptionType>;
  policyExtraCost?: Maybe<PolicyExtraCost>;
  startDate: Scalars['String']['output'];
  type: PolicyExtraType;
};

export enum PolicyExtraOptionType {
  CourtesyCar = 'CourtesyCar',
  ExcessInsurance1 = 'ExcessInsurance1',
  ExcessInsurance2 = 'ExcessInsurance2',
  ExcessInsurance3 = 'ExcessInsurance3',
  ExcessInsurance4 = 'ExcessInsurance4',
  ExcessInsurance5 = 'ExcessInsurance5',
  LegalExpenses = 'LegalExpenses',
  RoadsideAssistancePlatinum = 'RoadsideAssistancePlatinum',
  SmartfobKeycare = 'SmartfobKeycare'
}

export enum PolicyExtraType {
  CourtesyCar = 'CourtesyCar',
  ExcessInsurance = 'ExcessInsurance',
  LegalExpenses = 'LegalExpenses',
  RoadsideAssistancePlatinum = 'RoadsideAssistancePlatinum',
  SmartfobKeycare = 'SmartfobKeycare'
}

export type PolicyHolder = {
  __typename?: 'PolicyHolder';
  accountReminderSentOn?: Maybe<Scalars['String']['output']>;
  address: Address;
  businessMileage?: Maybe<Scalars['Int']['output']>;
  claims: Array<PolicyClaim>;
  classOfUse?: Maybe<ClassOfUse>;
  clientRef?: Maybe<Scalars['String']['output']>;
  convictions: Array<PolicyConviction>;
  createdOn: Scalars['String']['output'];
  dateOfBirth: Scalars['String']['output'];
  effectiveDate: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  hasCancellations: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isHomeowner?: Maybe<Scalars['Boolean']['output']>;
  isMainDriver: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  licence: Licence;
  maritalStatus: MaritalStatus;
  medicalCondition?: Maybe<MedicalCondition>;
  medicalConditionsDeclared: Scalars['Boolean']['output'];
  metadata: PolicyHolderMetadata;
  mileage?: Maybe<Scalars['Int']['output']>;
  noClaimsBonusYears?: Maybe<Scalars['String']['output']>;
  primaryEmployment: Employment;
  secondaryEmployment?: Maybe<Employment>;
  title: Scalars['String']['output'];
  ukResidencyDurationMonths: Scalars['Int']['output'];
  updatedOn: Scalars['String']['output'];
};

export type PolicyHolderMetadata = {
  __typename?: 'PolicyHolderMetadata';
  email: Scalars['String']['output'];
  emailMarketingEnabled: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  smsMarketingEnabled: Scalars['Boolean']['output'];
};

export type PolicyHolderMutation = {
  __typename?: 'PolicyHolderMutation';
  emailConfirmPasswordChange: Result;
  updateMetadataMarketingPreferences: UpdatePolicyHolderMarketingPreferencesResult;
};


export type PolicyHolderMutationUpdateMetadataMarketingPreferencesArgs = {
  emailMarketingEnabled: Scalars['Boolean']['input'];
  smsMarketingEnabled: Scalars['Boolean']['input'];
};

export type PolicyList = {
  __typename?: 'PolicyList';
  active?: Maybe<Array<Policy>>;
  cancelled?: Maybe<Array<Policy>>;
  expired?: Maybe<Array<Policy>>;
  upcoming?: Maybe<Array<Policy>>;
};

export type PolicyMetadata = {
  __typename?: 'PolicyMetadata';
  agentWorkflowStage: AgentWorkflowStage;
  agentWorkflowStageUpdatedOn?: Maybe<Scalars['String']['output']>;
  coverEndDate: Scalars['String']['output'];
  coverStartDate: Scalars['String']['output'];
  policyHolderId: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  renewalInviteStatus?: Maybe<RenewalInviteStatus>;
};

export type PolicyProposer = {
  address: AddressInput;
  businessMileage?: InputMaybe<Scalars['Int']['input']>;
  claims: Array<ProposedPolicyClaim>;
  classOfUse: ProposedClassOfUse;
  convictions: Array<ProposedPolicyConviction>;
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  isHomeowner?: InputMaybe<Scalars['Boolean']['input']>;
  isMainDriver: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  licence: LicenceInput;
  maritalStatus: MaritalStatus;
  medicalCondition?: InputMaybe<MedicalCondition>;
  medicalConditionsDeclared: Scalars['Boolean']['input'];
  mileage?: InputMaybe<Scalars['Int']['input']>;
  noClaimsBonusYears: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  primaryEmployment: EmploymentInput;
  secondaryEmployment?: InputMaybe<EmploymentInput>;
  title: Scalars['String']['input'];
  ukResidencyDurationMonths: Scalars['Int']['input'];
};

export type PolicySetupMutation = {
  __typename?: 'PolicySetupMutation';
  applePay: ApplePayPolicySetupMutation;
  googlePay: GooglePayPolicySetupMutation;
  hostedPaymentPage: HostedPaymentPagePolicySetupMutation;
  zeroDeposit: ZeroDepositPolicySetupMutation;
};

export type PolicySetupQuery = {
  __typename?: 'PolicySetupQuery';
  applePaySession: ApplePaySessionResponse;
  fetchProspectiveLoans: FetchProspectiveLoansResponse;
  googlePayMerchantId: Scalars['String']['output'];
  paymentAccessToken: Scalars['String']['output'];
  paymentMerchantId: Scalars['String']['output'];
  policyExtras: Array<ProspectivePolicyExtra>;
};


export type PolicySetupQueryFetchProspectiveLoansArgs = {
  depositPercentage?: InputMaybe<Scalars['Int']['input']>;
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};


export type PolicySetupQueryPolicyExtrasArgs = {
  policyExtraStartDate?: InputMaybe<Scalars['String']['input']>;
};

export enum PolicyStatus {
  Live = 'LIVE',
  Pending = 'PENDING',
  PolicyCancelled = 'POLICY_CANCELLED',
  VersionCancelled = 'VERSION_CANCELLED'
}

export type PolicyVehicleDetails = {
  __typename?: 'PolicyVehicleDetails';
  carCode?: Maybe<Scalars['String']['output']>;
  dateOfPurchase?: Maybe<Scalars['String']['output']>;
  hasTrackingDeviceInstalled: Scalars['Boolean']['output'];
  immobiliserType?: Maybe<Scalars['String']['output']>;
  keeper: VehicleHolder;
  modifications: Array<ModificationType>;
  owner: VehicleHolder;
  registeredVehicle: RegisteredVehicle;
  storedAtNight: StoredAtNight;
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['String']['output'];
  currency: Scalars['String']['output'];
};

export enum ProposedClassOfUse {
  Social = 'SOCIAL',
  SocialAndCommuting = 'SOCIAL_AND_COMMUTING',
  SocialCommutingAndBusiness = 'SOCIAL_COMMUTING_AND_BUSINESS'
}

export type ProposedPolicyAdditionalDriver = {
  businessMileage?: InputMaybe<Scalars['Int']['input']>;
  claims: Array<ProposedPolicyClaim>;
  convictions: Array<ProposedPolicyConviction>;
  dateOfBirth: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  hasCancellations: Scalars['Boolean']['input'];
  isMainDriver: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  licence: LicenceInput;
  maritalStatus: MaritalStatus;
  medicalCondition?: InputMaybe<MedicalCondition>;
  medicalConditionsDeclared: Scalars['Boolean']['input'];
  mileage?: InputMaybe<Scalars['Int']['input']>;
  primaryEmployment: EmploymentInput;
  relationshipToPolicyHolder: RelationshipToProposer;
  secondaryEmployment?: InputMaybe<EmploymentInput>;
  title: Scalars['String']['input'];
  ukResidencyDurationMonths: Scalars['Int']['input'];
};

export type ProposedPolicyClaim = {
  accidentDescription?: InputMaybe<AccidentDescription>;
  atFault: AtFault;
  bodilyInjuryOccurred: Scalars['Boolean']['input'];
  date: Scalars['String']['input'];
  incidentType: IncidentType;
  noClaimsAffected: NoClaimsAffected;
  status: ClaimStatus;
  value: Scalars['Int']['input'];
  whichPolicyClaimedAgainst: WhichPolicyClaimedAgainst;
};

export type ProposedPolicyConviction = {
  alcoholLevel?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['String']['input'];
  disqualificationPeriodMonths: Scalars['Int']['input'];
  fine: Scalars['Int']['input'];
  offence: ConvictionOffenceInput;
  penaltyPoints: Scalars['Int']['input'];
  testMethod?: InputMaybe<AlcoholTestMethod>;
  type: ConvictionTypeInput;
};

export type ProposedPolicyCoverDetails = {
  breakInCoverDeclaration: BreakInCoverDeclaration;
  coverType: Scalars['String']['input'];
  noClaimsProtection: Scalars['Boolean']['input'];
  voluntaryExcess: Scalars['Int']['input'];
};

export type ProposedPolicyExtra = {
  optionType?: InputMaybe<PolicyExtraOptionType>;
  type: PolicyExtraType;
};

export type ProposedPolicyRequest = {
  additionalDrivers: Array<ProposedPolicyAdditionalDriver>;
  coverDetails: ProposedPolicyCoverDetails;
  insurerScheme: Scalars['String']['input'];
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  policyExtras: Array<ProposedPolicyExtra>;
  proposer: PolicyProposer;
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
  vehicleDetails: ProposedPolicyVehicleDetails;
};

export type ProposedPolicyVehicle = {
  abi?: InputMaybe<Scalars['String']['input']>;
  bodyStyle?: InputMaybe<Scalars['String']['input']>;
  commercialVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  driverPosition?: InputMaybe<DriverPosition>;
  engineCapacityCc?: InputMaybe<Scalars['String']['input']>;
  engineCapacityLitres?: InputMaybe<Scalars['String']['input']>;
  estimatedValue?: InputMaybe<Scalars['Int']['input']>;
  fuelType: Scalars['String']['input'];
  importType?: InputMaybe<ImportType>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  seats?: InputMaybe<Scalars['Int']['input']>;
  transmissionType: Scalars['String']['input'];
  trim?: InputMaybe<Scalars['String']['input']>;
  yearOfManufacture: Scalars['String']['input'];
};

export type ProposedPolicyVehicleDetails = {
  dateOfPurchase: Scalars['String']['input'];
  hasTrackingDeviceInstalled: Scalars['Boolean']['input'];
  immobiliserType?: InputMaybe<Scalars['String']['input']>;
  keeper: VehicleHolder;
  modifications: Array<ModificationType>;
  owner: VehicleHolder;
  registeredVehicle: ProposedPolicyVehicle;
  storedAtNight: StoredAtNight;
  vehicleAlarm?: InputMaybe<VehicleAlarm>;
  yearOfRegistration?: InputMaybe<Scalars['String']['input']>;
};

export type ProposedRenewalRequest = {
  policyExtras: Array<QuoteExtra>;
  policyId: Scalars['String']['input'];
};

export type ProspectiveLoan = {
  __typename?: 'ProspectiveLoan';
  apr: Scalars['Float']['output'];
  firstInstalmentAmount: Scalars['Float']['output'];
  numberOfInstalments: Scalars['Int']['output'];
  rate: Rate;
  subsequentInstalmentAmounts: Scalars['Float']['output'];
  totalAmountPayable: Scalars['Float']['output'];
};

export type ProspectiveLoanWithPrice = {
  __typename?: 'ProspectiveLoanWithPrice';
  apr: Scalars['Float']['output'];
  financeFee: Price;
  firstInstalmentAmount: Price;
  loanDepositOptions: Array<LoanDepositOption>;
  numberOfInstalments: Scalars['Int']['output'];
  rateReference: Scalars['String']['output'];
  subsequentInstalmentAmounts: Price;
  totalAmountPayable: Price;
  totalAmountWithoutExtras?: Maybe<Price>;
  totalFinance: Price;
};

export type ProspectivePolicyExtra = {
  __typename?: 'ProspectivePolicyExtra';
  document: Document;
  options: Array<ProspectivePolicyExtraOption>;
  type: PolicyExtraType;
};

export type ProspectivePolicyExtraOption = {
  __typename?: 'ProspectivePolicyExtraOption';
  description?: Maybe<Scalars['String']['output']>;
  excessProtectedAmount?: Maybe<Price>;
  optionType: PolicyExtraOptionType;
  price: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  address: AddressQuery;
  bankDetails: BankDetailsQuery;
  convictions: ConvictionsQuery;
  documentation: DocumentationQuery;
  generatePolicyHolderDocumentUploadPresignedUrl: UploadPresignedUrl;
  healthCheck: HealthCheckResponse;
  listPolicies: PolicyList;
  midTermAdjustmentMetadata?: Maybe<Array<MidTermAdjustmentMetadata>>;
  /** @deprecated To be removed after mobile apps have some time to more over to version 1.9) */
  policies: Array<Policy>;
  policy: Policy;
  policyHolder: PolicyHolder;
  policySetup: PolicySetupQuery;
  quote: QuoteQuery;
  renewals: RenewalsQuery;
  vehicle: VehicleQuery;
  zendesk: ZendeskQuery;
};


export type QueryGeneratePolicyHolderDocumentUploadPresignedUrlArgs = {
  documentType?: InputMaybe<UploadedDocumentType>;
  policyId: Scalars['String']['input'];
};


export type QueryHealthCheckArgs = {
  healthChecks: Array<HealthChecks>;
};


export type QueryMidTermAdjustmentMetadataArgs = {
  policyId: Scalars['String']['input'];
};


export type QueryPolicyArgs = {
  id: Scalars['String']['input'];
};

export type QuoteAdditionalDriver = {
  convictions: Array<QuoteConviction>;
  dateOfBirth: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  hasCancellations?: InputMaybe<Scalars['Boolean']['input']>;
  householdNumberOfCars?: InputMaybe<Scalars['String']['input']>;
  incidents: Array<QuoteIncident>;
  isMainDriver: Scalars['Boolean']['input'];
  licenceCountry: CountryOfIssue;
  licenceDurationMonths: Scalars['Int']['input'];
  licenceObtainedDate?: InputMaybe<Scalars['String']['input']>;
  licenceType: LicenceType;
  maritalStatus: MaritalStatus;
  medicalCondition?: InputMaybe<MedicalCondition>;
  primaryEmployment: QuoteEmployment;
  proposedClassOfUse?: InputMaybe<ProposedClassOfUse>;
  relationshipToProposer: RelationshipToProposer;
  secondaryEmployment?: InputMaybe<QuoteEmployment>;
  surname: Scalars['String']['input'];
  title: Scalars['String']['input'];
  ukResidencyDurationMonths: Scalars['Int']['input'];
};

export type QuoteAdditionalDriverDetails = {
  __typename?: 'QuoteAdditionalDriverDetails';
  convictions: Array<QuoteConvictionDetails>;
  dateOfBirth: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  hasCancellations: Scalars['Boolean']['output'];
  hasNonMotoringConvictions: Scalars['Boolean']['output'];
  householdNumberOfCars?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  incidents: Array<QuoteIncidentDetails>;
  isMainDriver: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  licenceCountry: CountryOfIssue;
  licenceDurationMonths: Scalars['Int']['output'];
  licenceType: LicenceType;
  maritalStatus: MaritalStatus;
  medicalCondition?: Maybe<MedicalCondition>;
  primaryEmployment: QuoteEmploymentDetails;
  proposedClassOfUse: ProposedClassOfUse;
  relationshipToProposer: RelationshipToProposer;
  secondaryEmployment?: Maybe<QuoteEmploymentDetails>;
  title: AbiListItem;
  ukResidencyDurationMonths: Scalars['Int']['output'];
};

export type QuoteConviction = {
  date: Scalars['String']['input'];
  disqualificationPeriodMonths: Scalars['Int']['input'];
  fine: Scalars['Int']['input'];
  offence?: InputMaybe<ConvictionOffenceItemInput>;
  offenceCode: Scalars['String']['input'];
  offenceCodeNumber: Scalars['String']['input'];
  penaltyPoints: Scalars['Int']['input'];
  sampleLevel?: InputMaybe<Scalars['Int']['input']>;
  sampleType?: InputMaybe<AlcoholTestMethod>;
  type?: InputMaybe<ConvictionTypeItemInput>;
};

export type QuoteConvictionDetails = {
  __typename?: 'QuoteConvictionDetails';
  date: Scalars['String']['output'];
  disqualificationPeriodMonths: Scalars['Int']['output'];
  fine: Scalars['Int']['output'];
  offence: ConvictionOffenceItem;
  offenceCode: Scalars['String']['output'];
  offenceCodeNumber: Scalars['String']['output'];
  penaltyPoints: Scalars['Int']['output'];
  sampleLevel?: Maybe<Scalars['Int']['output']>;
  sampleType?: Maybe<AlcoholTestMethod>;
  type: ConvictionTypeItem;
};

export type QuoteCover = {
  breakInCoverDeclaration: BreakInCoverDeclaration;
  companyCarExperience?: InputMaybe<CompanyCarExperience>;
  coverType: Scalars['String']['input'];
  namedDriverExperience?: InputMaybe<NamedDriverExperience>;
  periodBetweenInsurance?: InputMaybe<Scalars['Int']['input']>;
  previouslyHeldInsurance: Scalars['Boolean']['input'];
  protectNoClaimsBonus: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  totalBusinessMileage?: InputMaybe<Scalars['Int']['input']>;
  totalPrivateMileage: Scalars['Int']['input'];
  voluntaryExcess?: InputMaybe<Scalars['Int']['input']>;
  yearsNoClaimsBonus: Scalars['Int']['input'];
};

export type QuoteCoverDetails = {
  __typename?: 'QuoteCoverDetails';
  breakInCoverDeclaration?: Maybe<BreakInCoverDeclaration>;
  businessMileage?: Maybe<Scalars['Int']['output']>;
  companyCarExperience?: Maybe<CompanyCarExperienceDetails>;
  coverType: CoverType;
  mileage: Scalars['Int']['output'];
  namedDriverExperience?: Maybe<NamedDriverExperienceDetails>;
  periodBetweenInsurance?: Maybe<Scalars['Int']['output']>;
  previouslyHeldInsurance: Scalars['Boolean']['output'];
  protectNoClaimsBonus: Scalars['Boolean']['output'];
  startDate: Scalars['String']['output'];
  voluntaryExcess: Scalars['Int']['output'];
  yearsNoClaimsBonus: Scalars['Int']['output'];
};

export type QuoteDetails = {
  __typename?: 'QuoteDetails';
  quoteId: Scalars['String']['output'];
  quoteReference: Scalars['String']['output'];
  quoteSequence: Scalars['Int']['output'];
  quoteType?: Maybe<QuoteType>;
};

export type QuoteDocuments = {
  __typename?: 'QuoteDocuments';
  insuranceProductInformation?: Maybe<Scalars['String']['output']>;
  policyBooklet?: Maybe<Scalars['String']['output']>;
};

export type QuoteEmployment = {
  industry: Scalars['String']['input'];
  job: Scalars['String']['input'];
  status: EmploymentStatus;
};

export type QuoteEmploymentDetails = {
  __typename?: 'QuoteEmploymentDetails';
  industry: AbiListItem;
  job: AbiListItem;
  status: EmploymentStatus;
};

export type QuoteExtra = {
  optionType: PolicyExtraOptionType;
  type: PolicyExtraType;
};

export type QuoteExtras = {
  policyExtras?: InputMaybe<Array<QuoteExtra>>;
};

export type QuoteIncident = {
  accidentDescription?: InputMaybe<AccidentDescription>;
  atFault: AtFault;
  bodilyInjuryOccurred: Scalars['Boolean']['input'];
  date: Scalars['String']['input'];
  incidentType: IncidentType;
  noClaimsAffected: NoClaimsAffected;
  status: ClaimStatus;
  value: Scalars['Int']['input'];
  whichPolicyClaimedAgainst: WhichPolicyClaimedAgainst;
};

export type QuoteIncidentDetails = {
  __typename?: 'QuoteIncidentDetails';
  accidentDescription?: Maybe<AccidentDescription>;
  atFault: AtFault;
  bodilyInjuryOccurred: Scalars['Boolean']['output'];
  date: Scalars['String']['output'];
  incidentType: IncidentType;
  noClaimsAffected: NoClaimsAffected;
  status: ClaimStatus;
  value: Scalars['Int']['output'];
  whichPolicyClaimedAgainst: WhichPolicyClaimedAgainst;
};

export type QuoteMutation = {
  __typename?: 'QuoteMutation';
  getAggregatorQuote: AggregatorQuoteResult;
  getQuotes: QuotesResult;
  getRebrokedQuote?: Maybe<RebrokedQuoteResult>;
  saveQuotes?: Maybe<SaveQuoteResult>;
  upsertQuoteExtras: UpsertQuoteExtrasResponse;
};


export type QuoteMutationGetAggregatorQuoteArgs = {
  policyExtras?: InputMaybe<Array<AggregatorQuoteExtraInput>>;
  quoteReference: Scalars['String']['input'];
};


export type QuoteMutationGetQuotesArgs = {
  additionalDrivers: Array<QuoteAdditionalDriver>;
  cover: QuoteCover;
  proposer: QuoteProposer;
  quoteExtras: QuoteExtras;
  referredBy?: InputMaybe<Aggregator>;
  vehicle: QuoteVehicle;
};


export type QuoteMutationGetRebrokedQuoteArgs = {
  extraOptionTypes?: InputMaybe<Array<PolicyExtraOptionType>>;
  policyId: Scalars['String']['input'];
  protectNoClaimsBonus: Scalars['Boolean']['input'];
};


export type QuoteMutationSaveQuotesArgs = {
  params: SaveQuoteParams;
};


export type QuoteMutationUpsertQuoteExtrasArgs = {
  depositPercentage?: InputMaybe<Scalars['Int']['input']>;
  extraOptionTypes?: InputMaybe<Array<PolicyExtraOptionType>>;
  magicLinkId?: InputMaybe<Scalars['String']['input']>;
  quoteReference: Scalars['String']['input'];
  quoteSequence: Scalars['Int']['input'];
  shouldLoadLoans?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuotePersonalDetails = {
  __typename?: 'QuotePersonalDetails';
  address: RetrieveAddressResult;
  convictions?: Maybe<Array<QuoteConvictionDetails>>;
  dateOfBirth: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  hasCancellations: Scalars['Boolean']['output'];
  hasNonMotoringConvictions: Scalars['Boolean']['output'];
  incidents?: Maybe<Array<QuoteIncidentDetails>>;
  isHomeowner: Scalars['Boolean']['output'];
  isMainDriver: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  licenceCountry: CountryOfIssue;
  licenceDurationMonths: Scalars['Int']['output'];
  licenceType: LicenceType;
  maritalStatus: MaritalStatus;
  medicalCondition?: Maybe<MedicalCondition>;
  phoneNumber: Scalars['String']['output'];
  primaryEmployment: QuoteEmploymentDetails;
  proposedClassOfUse: ProposedClassOfUse;
  secondaryEmployment?: Maybe<QuoteEmploymentDetails>;
  soliciting: Scalars['Boolean']['output'];
  storedAtNight: StoredAtNight;
  title: AbiListItem;
  ukResidencyDurationMonths: Scalars['Int']['output'];
};

export type QuotePremiumPriceBreakdown = {
  __typename?: 'QuotePremiumPriceBreakdown';
  adminFee: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  originalPrice: Scalars['Float']['output'];
};

export type QuotePremiumPriceBreakdownDetails = {
  __typename?: 'QuotePremiumPriceBreakdownDetails';
  adminFee: Price;
  discount: Price;
  originalPrice: Price;
};

export type QuoteProposer = {
  address: AddressInput;
  convictions: Array<QuoteConviction>;
  dateOfBirth: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender: Gender;
  incidents: Array<QuoteIncident>;
  isHomeowner: Scalars['Boolean']['input'];
  isMainDriver: Scalars['Boolean']['input'];
  licenceCountry: CountryOfIssue;
  licenceDurationMonths: Scalars['Int']['input'];
  licenceObtainedDate?: InputMaybe<Scalars['String']['input']>;
  licenceType: LicenceType;
  maritalStatus: MaritalStatus;
  medicalCondition?: InputMaybe<MedicalCondition>;
  phoneNumber: Scalars['String']['input'];
  primaryEmployment: QuoteEmployment;
  proposedClassOfUse?: InputMaybe<ProposedClassOfUse>;
  secondaryEmployment?: InputMaybe<QuoteEmployment>;
  soliciting?: InputMaybe<Scalars['Boolean']['input']>;
  storedAtNight: StoredAtNight;
  surname: Scalars['String']['input'];
  title: Scalars['String']['input'];
  ukResidencyDurationMonths: Scalars['Int']['input'];
};

export type QuoteQuery = {
  __typename?: 'QuoteQuery';
  abiCodes: AbiCodes;
  documents: QuoteDocuments;
  doesPolicyHolderExist: Scalars['Boolean']['output'];
  emailLatestSavedQuote?: Maybe<EmailLatestSavedQuoteResult>;
  loadQuotes?: Maybe<LoadQuoteResult>;
  noClaimsDiscountProtectionCost?: Maybe<Scalars['Float']['output']>;
  noClaimsDiscountProtectionData: NoClaimsDiscountProtectionDataResult;
};


export type QuoteQueryDocumentsArgs = {
  coverType: Scalars['String']['input'];
  effectiveDate: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  schemeReference: Scalars['String']['input'];
};


export type QuoteQueryDoesPolicyHolderExistArgs = {
  email: Scalars['String']['input'];
};


export type QuoteQueryEmailLatestSavedQuoteArgs = {
  email: Scalars['String']['input'];
};


export type QuoteQueryLoadQuotesArgs = {
  magicLinkId: Scalars['String']['input'];
  quoteReference: Scalars['String']['input'];
  sequenceNumber: Scalars['Int']['input'];
};


export type QuoteQueryNoClaimsDiscountProtectionCostArgs = {
  reference: Scalars['String']['input'];
  sequenceNumber: Scalars['Int']['input'];
};


export type QuoteQueryNoClaimsDiscountProtectionDataArgs = {
  coverStartDate: Scalars['String']['input'];
  schemeCode: Scalars['String']['input'];
};

export enum QuoteType {
  CompanyCar = 'COMPANY_CAR',
  Default = 'DEFAULT',
  NamedDriver = 'NAMED_DRIVER',
  NoClaimsBonus = 'NO_CLAIMS_BONUS'
}

export type QuoteValidationError = {
  __typename?: 'QuoteValidationError';
  driverId?: Maybe<Scalars['String']['output']>;
  errorUUID: Scalars['String']['output'];
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  section: QuoteValidationErrorSection;
};

export enum QuoteValidationErrorSection {
  AdditionalDriver = 'ADDITIONAL_DRIVER',
  CoverDetails = 'COVER_DETAILS',
  PersonalDetails = 'PERSONAL_DETAILS',
  VehicleDetails = 'VEHICLE_DETAILS'
}

export type QuoteVehicle = {
  abi: Scalars['String']['input'];
  bodyStyle: Scalars['String']['input'];
  commercialVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfPurchase?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  driverPosition?: InputMaybe<DriverPosition>;
  engineCapacityCc?: InputMaybe<Scalars['String']['input']>;
  engineCapacityLitres?: InputMaybe<Scalars['String']['input']>;
  estimatedValue: Scalars['Int']['input'];
  fuelType: Scalars['String']['input'];
  hasTracker: Scalars['Boolean']['input'];
  importType?: InputMaybe<ImportType>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  keeper: VehicleHolder;
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  modifications: Array<ModificationType>;
  owner: VehicleHolder;
  registration?: InputMaybe<Scalars['String']['input']>;
  seats: Scalars['Int']['input'];
  transmissionType: Scalars['String']['input'];
  trim?: InputMaybe<Scalars['String']['input']>;
  vehicleAlarm?: InputMaybe<VehicleAlarm>;
  yearOfManufacture: Scalars['String']['input'];
};

export type QuoteVehicleDetails = {
  __typename?: 'QuoteVehicleDetails';
  abi: Scalars['String']['output'];
  bodyStyle?: Maybe<Scalars['String']['output']>;
  dateOfPurchase?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  driverPosition?: Maybe<DriverPosition>;
  engineCapacityCc?: Maybe<Scalars['Float']['output']>;
  engineCapacityLitres?: Maybe<Scalars['Float']['output']>;
  estimatedValue: Scalars['Int']['output'];
  fuelType: Scalars['String']['output'];
  hasTracker: Scalars['Boolean']['output'];
  importType?: Maybe<ImportType>;
  isCommercialVehicle?: Maybe<Scalars['Boolean']['output']>;
  isImported?: Maybe<Scalars['Boolean']['output']>;
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  modifications: Array<ModificationType>;
  registeredKeeper: VehicleHolder;
  registration?: Maybe<Scalars['String']['output']>;
  seats: Scalars['Int']['output'];
  transmissionType: Scalars['String']['output'];
  trim?: Maybe<Scalars['String']['output']>;
  vehicleAlarm?: Maybe<VehicleAlarmDetails>;
  vehicleOwner: VehicleHolder;
  yearOfManufacture: Scalars['Int']['output'];
};

export type QuotesResult = {
  __typename?: 'QuotesResult';
  adminFee: Price;
  magicLinkId: Scalars['String']['output'];
  quotes?: Maybe<Array<InsurerSchemeQuote>>;
};

export type Rate = {
  __typename?: 'Rate';
  apr: Scalars['Float']['output'];
  depositAmount: Scalars['Float']['output'];
  numberOfInstalments: Scalars['Int']['output'];
  rateReference: Scalars['String']['output'];
};

export type RebrokedQuoteResult = {
  __typename?: 'RebrokedQuoteResult';
  magicLinkId: Scalars['String']['output'];
  noClaimsBonusProtectionCost?: Maybe<Price>;
  quote?: Maybe<InsurerSchemeQuote>;
  totalExtrasPrice?: Maybe<Price>;
};

export type RegisteredVehicle = {
  __typename?: 'RegisteredVehicle';
  abi?: Maybe<Scalars['String']['output']>;
  bodyStyle: Scalars['String']['output'];
  commercialVehicle?: Maybe<Scalars['Boolean']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  driverPosition?: Maybe<DriverPosition>;
  engineCapacityCc?: Maybe<Scalars['String']['output']>;
  engineCapacityLitres?: Maybe<Scalars['String']['output']>;
  estimatedValue?: Maybe<Scalars['Int']['output']>;
  fuelType: Scalars['String']['output'];
  importType?: Maybe<ImportType>;
  imported?: Maybe<Scalars['Boolean']['output']>;
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  registration: Scalars['String']['output'];
  seats?: Maybe<Scalars['Int']['output']>;
  transmissionType: Scalars['String']['output'];
  trim: Scalars['String']['output'];
  yearOfManufacture: Scalars['String']['output'];
};

export enum RelationshipToProposer {
  BrotherOrSister = 'BROTHER_OR_SISTER',
  BusinessPartner = 'BUSINESS_PARTNER',
  CommonLawSpouse = 'COMMON_LAW_SPOUSE',
  DaughterOrSon = 'DAUGHTER_OR_SON',
  DaughterOrSonInLaw = 'DAUGHTER_OR_SON_IN_LAW',
  Director = 'DIRECTOR',
  Family = 'FAMILY',
  Grandchild = 'GRANDCHILD',
  Grandparent = 'GRANDPARENT',
  Guardian = 'GUARDIAN',
  Lodger = 'LODGER',
  NotApplicable = 'NOT_APPLICABLE',
  Parent = 'PARENT',
  PartnerCivil = 'PARTNER_CIVIL',
  PartnerOfFamily = 'PARTNER_OF_FAMILY',
  ProposersEmployee = 'PROPOSERS_EMPLOYEE',
  ProposersEmployer = 'PROPOSERS_EMPLOYER',
  SisterOrBrotherInLaw = 'SISTER_OR_BROTHER_IN_LAW',
  Spouse = 'SPOUSE',
  Tenant = 'TENANT',
  Unrelated = 'UNRELATED'
}

export type RenewalDocuments = {
  __typename?: 'RenewalDocuments';
  insuranceProductInformation: Scalars['String']['output'];
  policyBooklet: Scalars['String']['output'];
};

export enum RenewalInviteStatus {
  AwaitingInvite = 'AWAITING_INVITE',
  AwaitingReminder = 'AWAITING_REMINDER',
  Complete = 'COMPLETE',
  DuplicateInviteReceived = 'DUPLICATE_INVITE_RECEIVED',
  InviteInProgress = 'INVITE_IN_PROGRESS',
  InviteNotReceived = 'INVITE_NOT_RECEIVED',
  MtaDetected = 'MTA_DETECTED',
  RebrokeOnly = 'REBROKE_ONLY',
  RebrokeOnlyWithException = 'REBROKE_ONLY_WITH_EXCEPTION',
  RenewalOffered = 'RENEWAL_OFFERED',
  RenewalOfferedWithException = 'RENEWAL_OFFERED_WITH_EXCEPTION'
}

export type RenewalNotice = {
  __typename?: 'RenewalNotice';
  adminFee: Price;
  annualised: Price;
  breakdownCover: Price;
  brokerDiscount: Price;
  canRenewOnline: Scalars['Boolean']['output'];
  excessInsurance: Price;
  expiryTimestamp: Scalars['String']['output'];
  insurer: Scalars['String']['output'];
  keyCare: Price;
  lastYearsPremium: Price;
  legalExpenses: Price;
  renewalExcess: Price;
  renewalPremium: Price;
  renewalTimestamp: Scalars['String']['output'];
  renewalTotal: Price;
  replacementCar: Price;
};

export type RenewalsMutation = {
  __typename?: 'RenewalsMutation';
  applePay: ApplePayProcessRenewalMutation;
  googlePay: GooglePayProcessRenewalMutation;
  hostedPaymentPage: HostedPaymentPageProcessRenewalMutation;
  zeroDeposit: ZeroDepositFinancedProcessRenewalMutation;
};

export type RenewalsQuery = {
  __typename?: 'RenewalsQuery';
  documents: RenewalDocuments;
  getProspectiveRenewalLoans: FetchProspectiveLoansResponse;
  renewalNotice?: Maybe<RenewalNotice>;
};


export type RenewalsQueryDocumentsArgs = {
  policyId: Scalars['String']['input'];
};


export type RenewalsQueryGetProspectiveRenewalLoansArgs = {
  depositPercentage: Scalars['Int']['input'];
  policyExtras: Array<QuoteExtra>;
  policyId: Scalars['String']['input'];
};


export type RenewalsQueryRenewalNoticeArgs = {
  policyId: Scalars['String']['input'];
};

export enum RequestPrintedDocumentsState {
  BatchLimitHit = 'BATCH_LIMIT_HIT',
  RequestSent = 'REQUEST_SENT',
  WeeklyLimitHit = 'WEEKLY_LIMIT_HIT'
}

export type RequestPrintedDocumentsV2Params = {
  documentsToPrint: Array<PolicyDocumentInput>;
  policyEffectiveDate: Scalars['String']['input'];
  policyId: Scalars['String']['input'];
  policyReference: Scalars['String']['input'];
};

export type RequestPrintedDocumentsV2Result = {
  __typename?: 'RequestPrintedDocumentsV2Result';
  lastRequestedDate?: Maybe<Scalars['String']['output']>;
  state: RequestPrintedDocumentsState;
};

export enum Result {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type RetrieveAddressResult = {
  __typename?: 'RetrieveAddressResult';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  firstLine: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
};

export type SaveQuoteParams = {
  additionalDrivers?: InputMaybe<Array<AdditionalDriverDetails>>;
  coverType: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  magicLinkId: Scalars['String']['input'];
  policyExtras?: InputMaybe<Array<QuoteExtra>>;
  policyStartDate: Scalars['String']['input'];
  quoteAdditionalDrivers?: InputMaybe<Array<QuoteAdditionalDriver>>;
  reference: Scalars['String']['input'];
  sequenceNumber: Scalars['Int']['input'];
  serialisedQuote: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type SaveQuoteResult = {
  __typename?: 'SaveQuoteResult';
  magicLinkId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  sequenceNumber: Scalars['Int']['output'];
};

export type StepbackRule = {
  __typename?: 'StepbackRule';
  claims: Scalars['Int']['output'];
  nonProtectedYears: Scalars['Int']['output'];
  protectedYears: Scalars['Int']['output'];
};

export type StepbackRules = {
  __typename?: 'StepbackRules';
  rules: Array<StepbackRule>;
  years: Scalars['Int']['output'];
  yearsAtRenewal: Scalars['Int']['output'];
};

export enum StoredAtNight {
  Carpark = 'CARPARK',
  Carport = 'CARPORT',
  Garage = 'GARAGE',
  LockedCompound = 'LOCKED_COMPOUND',
  /** @deprecated Use 'GARAGE' now as we do not need to distinguish between locked and unlocked */
  LockedGarage = 'LOCKED_GARAGE',
  OnTheDriveway = 'ON_THE_DRIVEWAY',
  OnTheStreet = 'ON_THE_STREET',
  Other = 'OTHER',
  PrivatePremises = 'PRIVATE_PREMISES',
  SecurePrivatePremises = 'SECURE_PRIVATE_PREMISES',
  UnlockedCompound = 'UNLOCKED_COMPOUND',
  /** @deprecated Use 'GARAGE' now as we do not need to distinguish between locked and unlocked */
  UnlockedGarage = 'UNLOCKED_GARAGE',
  UnlockedPrivatePremises = 'UNLOCKED_PRIVATE_PREMISES'
}

export type SuccessfulQuoteResult = {
  __typename?: 'SuccessfulQuoteResult';
  classOfUse?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by compulsoryExcessAsPrice - to be removed once Money is implemented */
  compulsoryExcess: Scalars['Float']['output'];
  compulsoryExcessAsPrice: Price;
  insurer: Scalars['String']['output'];
  insurerSchemeReference: Scalars['String']['output'];
  magicLinkId: Scalars['String']['output'];
  premiumAsPrice: Price;
  /** @deprecated Replaced by premiumPriceBreakdownAsPrice - to be removed once Money is implemented */
  premiumPriceBreakdown?: Maybe<QuotePremiumPriceBreakdown>;
  premiumPriceBreakdownDetails?: Maybe<QuotePremiumPriceBreakdownDetails>;
  /** @deprecated Replaced by premiumAsPrice - to be removed once Money is implemented */
  price: Scalars['Float']['output'];
  quoteType?: Maybe<QuoteType>;
  reference: Scalars['String']['output'];
  referredBy?: Maybe<Aggregator>;
  sequenceNumber: Scalars['Int']['output'];
  totalExtrasPrice?: Maybe<Price>;
  totalUnfinancedAmountPayable: Price;
  /** @deprecated Replaced by voluntaryExcessAsPrice - to be removed once Money is implemented */
  voluntaryExcess: Scalars['Int']['output'];
  voluntaryExcessAsPrice: Price;
};

export type ThreeDSecureResponse = {
  __typename?: 'ThreeDSecureResponse';
  acsTransactionId: Scalars['String']['output'];
  authenticationRequestType: Scalars['String']['output'];
  authenticationSource: Scalars['String']['output'];
  cardholderResponseInfo: Scalars['String']['output'];
  challenge: ThreeDSecureResponseChallenge;
  challengeMandated: Scalars['String']['output'];
  dsTransactionId: Scalars['String']['output'];
  messageCategory: Scalars['String']['output'];
  messageVersion: Scalars['String']['output'];
  mpi?: Maybe<ThreeDSecureResponseMpi>;
  notificationLambdaOrigin: Scalars['String']['output'];
  serverTransactionId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  statusReason: Scalars['String']['output'];
};

export type ThreeDSecureResponseChallenge = {
  __typename?: 'ThreeDSecureResponseChallenge';
  encodedChallengeRequest: Scalars['String']['output'];
  requestUrl: Scalars['String']['output'];
};

export type ThreeDSecureResponseMpi = {
  __typename?: 'ThreeDSecureResponseMpi';
  authenticationValue: Scalars['String']['output'];
  eci: Scalars['String']['output'];
};

export enum TransmissionType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type UpdatePolicyHolderMarketingPreferencesResult = {
  __typename?: 'UpdatePolicyHolderMarketingPreferencesResult';
  emailMarketingEnabled: Scalars['Boolean']['output'];
  smsMarketingEnabled: Scalars['Boolean']['output'];
};

export type UploadPresignedUrl = {
  __typename?: 'UploadPresignedUrl';
  url: Scalars['String']['output'];
};

export enum UploadedDocumentType {
  BreakInCoverDeclaration = 'BREAK_IN_COVER_DECLARATION',
  CancellationRequest = 'CANCELLATION_REQUEST',
  CompanyCarBonusProof = 'COMPANY_CAR_BONUS_PROOF',
  Complaint = 'COMPLAINT',
  Compliment = 'COMPLIMENT',
  Covernote = 'COVERNOTE',
  CueCheck = 'CUE_CHECK',
  DrivingExperienceLetter = 'DRIVING_EXPERIENCE_LETTER',
  EndorsementSchedule = 'ENDORSEMENT_SCHEDULE',
  Licence = 'LICENCE',
  LicencePaperBack = 'LICENCE_PAPER_BACK',
  LicencePaperFront = 'LICENCE_PAPER_FRONT',
  LicencePlasticBack = 'LICENCE_PLASTIC_BACK',
  LicencePlasticFront = 'LICENCE_PLASTIC_FRONT',
  NamedDriverExperienceProof = 'NAMED_DRIVER_EXPERIENCE_PROOF',
  NcbProof = 'NCB_PROOF',
  Other = 'OTHER',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  Sorn = 'SORN',
  Unknown = 'UNKNOWN',
  YoungDriverDeclaration = 'YOUNG_DRIVER_DECLARATION'
}

export type UpsertQuoteExtrasResponse = {
  __typename?: 'UpsertQuoteExtrasResponse';
  prospectiveLoans?: Maybe<Array<ProspectiveLoanWithPrice>>;
  totalExtrasCost?: Maybe<Price>;
  totalUnfinancedAmountPayable: Price;
};

export type ValidateBankDetailsParams = {
  accountNumber: Scalars['String']['input'];
  sortCode: Scalars['String']['input'];
};

export type ValidateBankDetailsResult = {
  __typename?: 'ValidateBankDetailsResult';
  isCorrect: Scalars['Boolean']['output'];
};

export type VehicleAlarm = {
  fittedBy?: InputMaybe<AlarmInstaller>;
  hasAlarm: Scalars['Boolean']['input'];
};

export type VehicleAlarmDetails = {
  __typename?: 'VehicleAlarmDetails';
  fittedBy?: Maybe<AlarmInstaller>;
  hasAlarm: Scalars['Boolean']['output'];
};

export enum VehicleHolder {
  CivilPartner = 'CIVIL_PARTNER',
  CivilPartnerOfDirectorOrPartner = 'CIVIL_PARTNER_OF_DIRECTOR_OR_PARTNER',
  CommonLawPartner = 'COMMON_LAW_PARTNER',
  Company = 'COMPANY',
  CompanyDirector = 'COMPANY_DIRECTOR',
  Driver_2 = 'DRIVER_2',
  Driver_3 = 'DRIVER_3',
  Driver_4 = 'DRIVER_4',
  Driver_5 = 'DRIVER_5',
  Employee = 'EMPLOYEE',
  Employer = 'EMPLOYER',
  Garage = 'GARAGE',
  Other = 'OTHER',
  OtherFamily = 'OTHER_FAMILY',
  Parent = 'PARENT',
  PolicyHolder = 'POLICY_HOLDER',
  Sibling = 'SIBLING',
  SonOrDaughter = 'SON_OR_DAUGHTER',
  Spouse = 'SPOUSE',
  SpouseOfDirectorOrPartner = 'SPOUSE_OF_DIRECTOR_OR_PARTNER',
  VehicleLeasingCompany = 'VEHICLE_LEASING_COMPANY'
}

export type VehicleOptions = {
  __typename?: 'VehicleOptions';
  engineCapacityLitres: Array<Scalars['String']['output']>;
  fuelType: Array<Scalars['String']['output']>;
  transmissionType: Array<Scalars['String']['output']>;
  trim: Array<Scalars['String']['output']>;
  yearsOfManufacture: Array<Scalars['String']['output']>;
};

export type VehicleOptionsFilters = {
  engineCapacityLitres?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<Scalars['String']['input']>;
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  transmissionType?: InputMaybe<Scalars['String']['input']>;
  trim?: InputMaybe<Scalars['String']['input']>;
  yearsOfManufacture?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleQuery = {
  __typename?: 'VehicleQuery';
  makes: Array<Scalars['String']['output']>;
  models: Array<Scalars['String']['output']>;
  registrationLookup: RegisteredVehicle;
  vehicleOptions: VehicleOptions;
  vehicleSpecLookup: Array<VehicleSpecLookupItem>;
  warmUp?: Maybe<Scalars['String']['output']>;
};


export type VehicleQueryModelsArgs = {
  make: Scalars['String']['input'];
};


export type VehicleQueryRegistrationLookupArgs = {
  registrationNumber: Scalars['String']['input'];
};


export type VehicleQueryVehicleOptionsArgs = {
  optionsFilters: VehicleOptionsFilters;
};


export type VehicleQueryVehicleSpecLookupArgs = {
  specLookupFilters: VehicleSpecLookupFilters;
};

export type VehicleSpecLookupFilters = {
  engineCapacityLitres?: InputMaybe<Scalars['String']['input']>;
  fuelType: Scalars['String']['input'];
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  transmissionType: Scalars['String']['input'];
  trim: Scalars['String']['input'];
  yearOfManufacture: Scalars['String']['input'];
};

export type VehicleSpecLookupItem = {
  __typename?: 'VehicleSpecLookupItem';
  abi: Scalars['String']['output'];
  bodyStyle: Scalars['String']['output'];
  brakeHorsepower: Scalars['String']['output'];
  doors: Scalars['Int']['output'];
  engineCapacityCc: Scalars['String']['output'];
  engineCapacityLitres: Scalars['String']['output'];
  fuelType: Scalars['String']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  transmissionType: Scalars['String']['output'];
  trim: Scalars['String']['output'];
  yearOfManufactureEnd: Scalars['String']['output'];
  yearOfManufactureStart: Scalars['String']['output'];
};

export enum WhichPolicyClaimedAgainst {
  Company = 'Company',
  Other = 'Other',
  Own = 'Own',
  Proposers = 'Proposers'
}

export type ZendeskAuthenticationTokenResult = {
  __typename?: 'ZendeskAuthenticationTokenResult';
  token: Scalars['String']['output'];
};

export type ZendeskQuery = {
  __typename?: 'ZendeskQuery';
  generateAuthenticationToken?: Maybe<ZendeskAuthenticationTokenResult>;
};

export type ZeroDepositFinancedProcessRenewalMutation = {
  __typename?: 'ZeroDepositFinancedProcessRenewalMutation';
  processFinancedRenewal: FinancedPolicyPaymentResponse;
};


export type ZeroDepositFinancedProcessRenewalMutationProcessFinancedRenewalArgs = {
  request: ZeroDepositFinancedProcessRenewalRequest;
};

export type ZeroDepositFinancedProcessRenewalRequest = {
  loanRequest: LoanRequest;
  proposedRenewalRequest: ProposedRenewalRequest;
};

export type ZeroDepositPolicySetUpRequest = {
  depositPercentage: Scalars['Int']['input'];
  financeInformation: FinanceInformation;
  loanSetUp: LoanSetUpRequest;
  proposerEmail?: InputMaybe<Scalars['String']['input']>;
  proposerPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  quoteReference: Scalars['String']['input'];
  quoteSequenceNumber: Scalars['Int']['input'];
};

export type ZeroDepositPolicySetupMutation = {
  __typename?: 'ZeroDepositPolicySetupMutation';
  setUpFinancedPolicy: FinancedPolicyPaymentResponse;
};


export type ZeroDepositPolicySetupMutationSetUpFinancedPolicyArgs = {
  request: ZeroDepositPolicySetUpRequest;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AbiCodeType: AbiCodeType;
  AbiCodes: ResolverTypeWrapper<AbiCodes>;
  AbiListItem: ResolverTypeWrapper<AbiListItem>;
  AccidentDescription: AccidentDescription;
  AdditionalDriver: ResolverTypeWrapper<AdditionalDriver>;
  AdditionalDriverDetails: AdditionalDriverDetails;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  AddressQuery: ResolverTypeWrapper<AddressQuery>;
  AgentWorkflowStage: AgentWorkflowStage;
  Aggregator: Aggregator;
  AggregatorQuoteDetails: ResolverTypeWrapper<AggregatorQuoteDetails>;
  AggregatorQuoteExtra: ResolverTypeWrapper<AggregatorQuoteExtra>;
  AggregatorQuoteExtraInput: AggregatorQuoteExtraInput;
  AggregatorQuoteResult: ResolverTypeWrapper<AggregatorQuoteResult>;
  AlarmInstaller: AlarmInstaller;
  AlcoholTestMethod: AlcoholTestMethod;
  ApplePayFinancedPolicySetUp: ApplePayFinancedPolicySetUp;
  ApplePayFinancedPolicySetUpRequest: ApplePayFinancedPolicySetUpRequest;
  ApplePayFinancedProcessRenewalRequest: ApplePayFinancedProcessRenewalRequest;
  ApplePayFullPolicySetUp: ApplePayFullPolicySetUp;
  ApplePayFullPolicySetUpRequest: ApplePayFullPolicySetUpRequest;
  ApplePayFullProcessRenewalRequest: ApplePayFullProcessRenewalRequest;
  ApplePayHeader: ApplePayHeader;
  ApplePayPaymentRequest: ApplePayPaymentRequest;
  ApplePayPaymentToken: ApplePayPaymentToken;
  ApplePayPolicySetupMutation: ResolverTypeWrapper<ApplePayPolicySetupMutation>;
  ApplePayProcessRenewalMutation: ResolverTypeWrapper<ApplePayProcessRenewalMutation>;
  ApplePaySessionResponse: ResolverTypeWrapper<ApplePaySessionResponse>;
  AtFault: AtFault;
  BankDetailsQuery: ResolverTypeWrapper<BankDetailsQuery>;
  BillingPeriod: BillingPeriod;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BreakInCoverDeclaration: BreakInCoverDeclaration;
  ClaimMutation: ResolverTypeWrapper<ClaimMutation>;
  ClaimStatus: ClaimStatus;
  ClassOfUse: ResolverTypeWrapper<ClassOfUse>;
  CloseBrothersDocumentQuery: ResolverTypeWrapper<CloseBrothersDocumentQuery>;
  CompanyCarExperience: CompanyCarExperience;
  CompanyCarExperienceDetails: ResolverTypeWrapper<CompanyCarExperienceDetails>;
  ConvictionOffenceInput: ConvictionOffenceInput;
  ConvictionOffenceItem: ResolverTypeWrapper<ConvictionOffenceItem>;
  ConvictionOffenceItemInput: ConvictionOffenceItemInput;
  ConvictionTypeInput: ConvictionTypeInput;
  ConvictionTypeItem: ResolverTypeWrapper<ConvictionTypeItem>;
  ConvictionTypeItemInput: ConvictionTypeItemInput;
  ConvictionsQuery: ResolverTypeWrapper<ConvictionsQuery>;
  CountryOfIssue: CountryOfIssue;
  CoverDetails: ResolverTypeWrapper<CoverDetails>;
  CoverType: CoverType;
  Document: ResolverTypeWrapper<Document>;
  DocumentType: DocumentType;
  DocumentationQuery: ResolverTypeWrapper<DocumentationQuery>;
  DriverPosition: DriverPosition;
  DrivingBan: ResolverTypeWrapper<DrivingBan>;
  EmailLatestSavedQuoteResult: ResolverTypeWrapper<EmailLatestSavedQuoteResult>;
  Employment: ResolverTypeWrapper<Employment>;
  EmploymentInput: EmploymentInput;
  EmploymentStatus: EmploymentStatus;
  Excess: ResolverTypeWrapper<Excess>;
  FailedQuoteResult: ResolverTypeWrapper<FailedQuoteResult>;
  FetchProspectiveLoansResponse: ResolverTypeWrapper<FetchProspectiveLoansResponse>;
  FinanceInformation: FinanceInformation;
  FinancedPolicyPaymentResponse: ResolverTypeWrapper<FinancedPolicyPaymentResponse>;
  FinancedPolicyPaymentResult: FinancedPolicyPaymentResult;
  FindAddressParams: FindAddressParams;
  FindAddressResult: ResolverTypeWrapper<FindAddressResult>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FuelType: FuelType;
  FullPolicyPaymentResponse: ResolverTypeWrapper<FullPolicyPaymentResponse>;
  FullPolicyPaymentResult: FullPolicyPaymentResult;
  Gender: Gender;
  GooglePayFinancedPolicySetUp: GooglePayFinancedPolicySetUp;
  GooglePayFinancedPolicySetUpRequest: GooglePayFinancedPolicySetUpRequest;
  GooglePayFinancedProcessRenewalRequest: GooglePayFinancedProcessRenewalRequest;
  GooglePayFullPolicySetUp: GooglePayFullPolicySetUp;
  GooglePayFullPolicySetUpRequest: GooglePayFullPolicySetUpRequest;
  GooglePayFullProcessRenewalRequest: GooglePayFullProcessRenewalRequest;
  GooglePayPaymentRequest: GooglePayPaymentRequest;
  GooglePayPaymentToken: GooglePayPaymentToken;
  GooglePayPolicySetupMutation: ResolverTypeWrapper<GooglePayPolicySetupMutation>;
  GooglePayProcessRenewalMutation: ResolverTypeWrapper<GooglePayProcessRenewalMutation>;
  HealthCheck: ResolverTypeWrapper<HealthCheck>;
  HealthCheckResponse: ResolverTypeWrapper<HealthCheckResponse>;
  HealthCheckStatus: HealthCheckStatus;
  HealthChecks: HealthChecks;
  HostedPaymentPageFinancedPolicySetUp: HostedPaymentPageFinancedPolicySetUp;
  HostedPaymentPageFinancedPolicySetUpRequest: HostedPaymentPageFinancedPolicySetUpRequest;
  HostedPaymentPageFinancedProcessRenewalRequest: HostedPaymentPageFinancedProcessRenewalRequest;
  HostedPaymentPageFullPolicySetUp: HostedPaymentPageFullPolicySetUp;
  HostedPaymentPageFullPolicySetUpRequest: HostedPaymentPageFullPolicySetUpRequest;
  HostedPaymentPageFullProcessRenewalRequest: HostedPaymentPageFullProcessRenewalRequest;
  HostedPaymentPagePaymentRequest: HostedPaymentPagePaymentRequest;
  HostedPaymentPagePolicySetupMutation: ResolverTypeWrapper<HostedPaymentPagePolicySetupMutation>;
  HostedPaymentPageProcessRenewalMutation: ResolverTypeWrapper<HostedPaymentPageProcessRenewalMutation>;
  ImportType: ImportType;
  IncidentType: IncidentType;
  InsurerSchemeQuote: ResolverTypeWrapper<InsurerSchemeQuote>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Licence: ResolverTypeWrapper<Licence>;
  LicenceInput: LicenceInput;
  LicenceType: LicenceType;
  LoadQuoteResult: ResolverTypeWrapper<LoadQuoteResult>;
  LoanDepositOption: ResolverTypeWrapper<LoanDepositOption>;
  LoanRequest: LoanRequest;
  LoanSetUpRequest: LoanSetUpRequest;
  MaritalStatus: MaritalStatus;
  MedicalCondition: MedicalCondition;
  MerchantType: MerchantType;
  MidTermAdjustmentMetadata: ResolverTypeWrapper<MidTermAdjustmentMetadata>;
  ModificationType: ModificationType;
  Mutation: ResolverTypeWrapper<{}>;
  NamedDriverExperience: NamedDriverExperience;
  NamedDriverExperienceDetails: ResolverTypeWrapper<NamedDriverExperienceDetails>;
  NoClaimsAffected: NoClaimsAffected;
  NoClaimsDiscount: ResolverTypeWrapper<NoClaimsDiscount>;
  NoClaimsDiscountProtectionDataResult: ResolverTypeWrapper<NoClaimsDiscountProtectionDataResult>;
  PaymentDetails: ResolverTypeWrapper<PaymentDetails>;
  Policy: ResolverTypeWrapper<Policy>;
  PolicyClaim: ResolverTypeWrapper<PolicyClaim>;
  PolicyConviction: ResolverTypeWrapper<PolicyConviction>;
  PolicyDocument: ResolverTypeWrapper<PolicyDocument>;
  PolicyDocumentInput: PolicyDocumentInput;
  PolicyDocumentation: ResolverTypeWrapper<PolicyDocumentation>;
  PolicyExtraCost: ResolverTypeWrapper<PolicyExtraCost>;
  PolicyExtraDocument: PolicyExtraDocument;
  PolicyExtraMetadata: ResolverTypeWrapper<PolicyExtraMetadata>;
  PolicyExtraOptionType: PolicyExtraOptionType;
  PolicyExtraType: PolicyExtraType;
  PolicyHolder: ResolverTypeWrapper<PolicyHolder>;
  PolicyHolderMetadata: ResolverTypeWrapper<PolicyHolderMetadata>;
  PolicyHolderMutation: ResolverTypeWrapper<PolicyHolderMutation>;
  PolicyList: ResolverTypeWrapper<PolicyList>;
  PolicyMetadata: ResolverTypeWrapper<PolicyMetadata>;
  PolicyProposer: PolicyProposer;
  PolicySetupMutation: ResolverTypeWrapper<PolicySetupMutation>;
  PolicySetupQuery: ResolverTypeWrapper<PolicySetupQuery>;
  PolicyStatus: PolicyStatus;
  PolicyVehicleDetails: ResolverTypeWrapper<PolicyVehicleDetails>;
  Price: ResolverTypeWrapper<Price>;
  ProposedClassOfUse: ProposedClassOfUse;
  ProposedPolicyAdditionalDriver: ProposedPolicyAdditionalDriver;
  ProposedPolicyClaim: ProposedPolicyClaim;
  ProposedPolicyConviction: ProposedPolicyConviction;
  ProposedPolicyCoverDetails: ProposedPolicyCoverDetails;
  ProposedPolicyExtra: ProposedPolicyExtra;
  ProposedPolicyRequest: ProposedPolicyRequest;
  ProposedPolicyVehicle: ProposedPolicyVehicle;
  ProposedPolicyVehicleDetails: ProposedPolicyVehicleDetails;
  ProposedRenewalRequest: ProposedRenewalRequest;
  ProspectiveLoan: ResolverTypeWrapper<ProspectiveLoan>;
  ProspectiveLoanWithPrice: ResolverTypeWrapper<ProspectiveLoanWithPrice>;
  ProspectivePolicyExtra: ResolverTypeWrapper<ProspectivePolicyExtra>;
  ProspectivePolicyExtraOption: ResolverTypeWrapper<ProspectivePolicyExtraOption>;
  Query: ResolverTypeWrapper<{}>;
  QuoteAdditionalDriver: QuoteAdditionalDriver;
  QuoteAdditionalDriverDetails: ResolverTypeWrapper<QuoteAdditionalDriverDetails>;
  QuoteConviction: QuoteConviction;
  QuoteConvictionDetails: ResolverTypeWrapper<QuoteConvictionDetails>;
  QuoteCover: QuoteCover;
  QuoteCoverDetails: ResolverTypeWrapper<QuoteCoverDetails>;
  QuoteDetails: ResolverTypeWrapper<QuoteDetails>;
  QuoteDocuments: ResolverTypeWrapper<QuoteDocuments>;
  QuoteEmployment: QuoteEmployment;
  QuoteEmploymentDetails: ResolverTypeWrapper<QuoteEmploymentDetails>;
  QuoteExtra: QuoteExtra;
  QuoteExtras: QuoteExtras;
  QuoteIncident: QuoteIncident;
  QuoteIncidentDetails: ResolverTypeWrapper<QuoteIncidentDetails>;
  QuoteMutation: ResolverTypeWrapper<QuoteMutation>;
  QuotePersonalDetails: ResolverTypeWrapper<QuotePersonalDetails>;
  QuotePremiumPriceBreakdown: ResolverTypeWrapper<QuotePremiumPriceBreakdown>;
  QuotePremiumPriceBreakdownDetails: ResolverTypeWrapper<QuotePremiumPriceBreakdownDetails>;
  QuoteProposer: QuoteProposer;
  QuoteQuery: ResolverTypeWrapper<QuoteQuery>;
  QuoteType: QuoteType;
  QuoteValidationError: ResolverTypeWrapper<QuoteValidationError>;
  QuoteValidationErrorSection: QuoteValidationErrorSection;
  QuoteVehicle: QuoteVehicle;
  QuoteVehicleDetails: ResolverTypeWrapper<QuoteVehicleDetails>;
  QuotesResult: ResolverTypeWrapper<QuotesResult>;
  Rate: ResolverTypeWrapper<Rate>;
  RebrokedQuoteResult: ResolverTypeWrapper<RebrokedQuoteResult>;
  RegisteredVehicle: ResolverTypeWrapper<RegisteredVehicle>;
  RelationshipToProposer: RelationshipToProposer;
  RenewalDocuments: ResolverTypeWrapper<RenewalDocuments>;
  RenewalInviteStatus: RenewalInviteStatus;
  RenewalNotice: ResolverTypeWrapper<RenewalNotice>;
  RenewalsMutation: ResolverTypeWrapper<RenewalsMutation>;
  RenewalsQuery: ResolverTypeWrapper<RenewalsQuery>;
  RequestPrintedDocumentsState: RequestPrintedDocumentsState;
  RequestPrintedDocumentsV2Params: RequestPrintedDocumentsV2Params;
  RequestPrintedDocumentsV2Result: ResolverTypeWrapper<RequestPrintedDocumentsV2Result>;
  Result: Result;
  RetrieveAddressResult: ResolverTypeWrapper<RetrieveAddressResult>;
  SaveQuoteParams: SaveQuoteParams;
  SaveQuoteResult: ResolverTypeWrapper<SaveQuoteResult>;
  StepbackRule: ResolverTypeWrapper<StepbackRule>;
  StepbackRules: ResolverTypeWrapper<StepbackRules>;
  StoredAtNight: StoredAtNight;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SuccessfulQuoteResult: ResolverTypeWrapper<SuccessfulQuoteResult>;
  ThreeDSecureResponse: ResolverTypeWrapper<ThreeDSecureResponse>;
  ThreeDSecureResponseChallenge: ResolverTypeWrapper<ThreeDSecureResponseChallenge>;
  ThreeDSecureResponseMpi: ResolverTypeWrapper<ThreeDSecureResponseMpi>;
  TransmissionType: TransmissionType;
  UpdatePolicyHolderMarketingPreferencesResult: ResolverTypeWrapper<UpdatePolicyHolderMarketingPreferencesResult>;
  UploadPresignedUrl: ResolverTypeWrapper<UploadPresignedUrl>;
  UploadedDocumentType: UploadedDocumentType;
  UpsertQuoteExtrasResponse: ResolverTypeWrapper<UpsertQuoteExtrasResponse>;
  ValidateBankDetailsParams: ValidateBankDetailsParams;
  ValidateBankDetailsResult: ResolverTypeWrapper<ValidateBankDetailsResult>;
  VehicleAlarm: VehicleAlarm;
  VehicleAlarmDetails: ResolverTypeWrapper<VehicleAlarmDetails>;
  VehicleHolder: VehicleHolder;
  VehicleOptions: ResolverTypeWrapper<VehicleOptions>;
  VehicleOptionsFilters: VehicleOptionsFilters;
  VehicleQuery: ResolverTypeWrapper<VehicleQuery>;
  VehicleSpecLookupFilters: VehicleSpecLookupFilters;
  VehicleSpecLookupItem: ResolverTypeWrapper<VehicleSpecLookupItem>;
  WhichPolicyClaimedAgainst: WhichPolicyClaimedAgainst;
  ZendeskAuthenticationTokenResult: ResolverTypeWrapper<ZendeskAuthenticationTokenResult>;
  ZendeskQuery: ResolverTypeWrapper<ZendeskQuery>;
  ZeroDepositFinancedProcessRenewalMutation: ResolverTypeWrapper<ZeroDepositFinancedProcessRenewalMutation>;
  ZeroDepositFinancedProcessRenewalRequest: ZeroDepositFinancedProcessRenewalRequest;
  ZeroDepositPolicySetUpRequest: ZeroDepositPolicySetUpRequest;
  ZeroDepositPolicySetupMutation: ResolverTypeWrapper<ZeroDepositPolicySetupMutation>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AbiCodes: AbiCodes;
  AbiListItem: AbiListItem;
  AdditionalDriver: AdditionalDriver;
  AdditionalDriverDetails: AdditionalDriverDetails;
  Address: Address;
  AddressInput: AddressInput;
  AddressQuery: AddressQuery;
  AggregatorQuoteDetails: AggregatorQuoteDetails;
  AggregatorQuoteExtra: AggregatorQuoteExtra;
  AggregatorQuoteExtraInput: AggregatorQuoteExtraInput;
  AggregatorQuoteResult: AggregatorQuoteResult;
  ApplePayFinancedPolicySetUp: ApplePayFinancedPolicySetUp;
  ApplePayFinancedPolicySetUpRequest: ApplePayFinancedPolicySetUpRequest;
  ApplePayFinancedProcessRenewalRequest: ApplePayFinancedProcessRenewalRequest;
  ApplePayFullPolicySetUp: ApplePayFullPolicySetUp;
  ApplePayFullPolicySetUpRequest: ApplePayFullPolicySetUpRequest;
  ApplePayFullProcessRenewalRequest: ApplePayFullProcessRenewalRequest;
  ApplePayHeader: ApplePayHeader;
  ApplePayPaymentRequest: ApplePayPaymentRequest;
  ApplePayPaymentToken: ApplePayPaymentToken;
  ApplePayPolicySetupMutation: ApplePayPolicySetupMutation;
  ApplePayProcessRenewalMutation: ApplePayProcessRenewalMutation;
  ApplePaySessionResponse: ApplePaySessionResponse;
  BankDetailsQuery: BankDetailsQuery;
  Boolean: Scalars['Boolean']['output'];
  ClaimMutation: ClaimMutation;
  ClassOfUse: ClassOfUse;
  CloseBrothersDocumentQuery: CloseBrothersDocumentQuery;
  CompanyCarExperience: CompanyCarExperience;
  CompanyCarExperienceDetails: CompanyCarExperienceDetails;
  ConvictionOffenceInput: ConvictionOffenceInput;
  ConvictionOffenceItem: ConvictionOffenceItem;
  ConvictionOffenceItemInput: ConvictionOffenceItemInput;
  ConvictionTypeInput: ConvictionTypeInput;
  ConvictionTypeItem: ConvictionTypeItem;
  ConvictionTypeItemInput: ConvictionTypeItemInput;
  ConvictionsQuery: ConvictionsQuery;
  CoverDetails: CoverDetails;
  Document: Document;
  DocumentationQuery: DocumentationQuery;
  DrivingBan: DrivingBan;
  EmailLatestSavedQuoteResult: EmailLatestSavedQuoteResult;
  Employment: Employment;
  EmploymentInput: EmploymentInput;
  Excess: Excess;
  FailedQuoteResult: FailedQuoteResult;
  FetchProspectiveLoansResponse: FetchProspectiveLoansResponse;
  FinanceInformation: FinanceInformation;
  FinancedPolicyPaymentResponse: FinancedPolicyPaymentResponse;
  FindAddressParams: FindAddressParams;
  FindAddressResult: FindAddressResult;
  Float: Scalars['Float']['output'];
  FullPolicyPaymentResponse: FullPolicyPaymentResponse;
  GooglePayFinancedPolicySetUp: GooglePayFinancedPolicySetUp;
  GooglePayFinancedPolicySetUpRequest: GooglePayFinancedPolicySetUpRequest;
  GooglePayFinancedProcessRenewalRequest: GooglePayFinancedProcessRenewalRequest;
  GooglePayFullPolicySetUp: GooglePayFullPolicySetUp;
  GooglePayFullPolicySetUpRequest: GooglePayFullPolicySetUpRequest;
  GooglePayFullProcessRenewalRequest: GooglePayFullProcessRenewalRequest;
  GooglePayPaymentRequest: GooglePayPaymentRequest;
  GooglePayPaymentToken: GooglePayPaymentToken;
  GooglePayPolicySetupMutation: GooglePayPolicySetupMutation;
  GooglePayProcessRenewalMutation: GooglePayProcessRenewalMutation;
  HealthCheck: HealthCheck;
  HealthCheckResponse: HealthCheckResponse;
  HostedPaymentPageFinancedPolicySetUp: HostedPaymentPageFinancedPolicySetUp;
  HostedPaymentPageFinancedPolicySetUpRequest: HostedPaymentPageFinancedPolicySetUpRequest;
  HostedPaymentPageFinancedProcessRenewalRequest: HostedPaymentPageFinancedProcessRenewalRequest;
  HostedPaymentPageFullPolicySetUp: HostedPaymentPageFullPolicySetUp;
  HostedPaymentPageFullPolicySetUpRequest: HostedPaymentPageFullPolicySetUpRequest;
  HostedPaymentPageFullProcessRenewalRequest: HostedPaymentPageFullProcessRenewalRequest;
  HostedPaymentPagePaymentRequest: HostedPaymentPagePaymentRequest;
  HostedPaymentPagePolicySetupMutation: HostedPaymentPagePolicySetupMutation;
  HostedPaymentPageProcessRenewalMutation: HostedPaymentPageProcessRenewalMutation;
  InsurerSchemeQuote: InsurerSchemeQuote;
  Int: Scalars['Int']['output'];
  Licence: Licence;
  LicenceInput: LicenceInput;
  LoadQuoteResult: LoadQuoteResult;
  LoanDepositOption: LoanDepositOption;
  LoanRequest: LoanRequest;
  LoanSetUpRequest: LoanSetUpRequest;
  MidTermAdjustmentMetadata: MidTermAdjustmentMetadata;
  Mutation: {};
  NamedDriverExperience: NamedDriverExperience;
  NamedDriverExperienceDetails: NamedDriverExperienceDetails;
  NoClaimsDiscount: NoClaimsDiscount;
  NoClaimsDiscountProtectionDataResult: NoClaimsDiscountProtectionDataResult;
  PaymentDetails: PaymentDetails;
  Policy: Policy;
  PolicyClaim: PolicyClaim;
  PolicyConviction: PolicyConviction;
  PolicyDocument: PolicyDocument;
  PolicyDocumentInput: PolicyDocumentInput;
  PolicyDocumentation: PolicyDocumentation;
  PolicyExtraCost: PolicyExtraCost;
  PolicyExtraDocument: PolicyExtraDocument;
  PolicyExtraMetadata: PolicyExtraMetadata;
  PolicyHolder: PolicyHolder;
  PolicyHolderMetadata: PolicyHolderMetadata;
  PolicyHolderMutation: PolicyHolderMutation;
  PolicyList: PolicyList;
  PolicyMetadata: PolicyMetadata;
  PolicyProposer: PolicyProposer;
  PolicySetupMutation: PolicySetupMutation;
  PolicySetupQuery: PolicySetupQuery;
  PolicyVehicleDetails: PolicyVehicleDetails;
  Price: Price;
  ProposedPolicyAdditionalDriver: ProposedPolicyAdditionalDriver;
  ProposedPolicyClaim: ProposedPolicyClaim;
  ProposedPolicyConviction: ProposedPolicyConviction;
  ProposedPolicyCoverDetails: ProposedPolicyCoverDetails;
  ProposedPolicyExtra: ProposedPolicyExtra;
  ProposedPolicyRequest: ProposedPolicyRequest;
  ProposedPolicyVehicle: ProposedPolicyVehicle;
  ProposedPolicyVehicleDetails: ProposedPolicyVehicleDetails;
  ProposedRenewalRequest: ProposedRenewalRequest;
  ProspectiveLoan: ProspectiveLoan;
  ProspectiveLoanWithPrice: ProspectiveLoanWithPrice;
  ProspectivePolicyExtra: ProspectivePolicyExtra;
  ProspectivePolicyExtraOption: ProspectivePolicyExtraOption;
  Query: {};
  QuoteAdditionalDriver: QuoteAdditionalDriver;
  QuoteAdditionalDriverDetails: QuoteAdditionalDriverDetails;
  QuoteConviction: QuoteConviction;
  QuoteConvictionDetails: QuoteConvictionDetails;
  QuoteCover: QuoteCover;
  QuoteCoverDetails: QuoteCoverDetails;
  QuoteDetails: QuoteDetails;
  QuoteDocuments: QuoteDocuments;
  QuoteEmployment: QuoteEmployment;
  QuoteEmploymentDetails: QuoteEmploymentDetails;
  QuoteExtra: QuoteExtra;
  QuoteExtras: QuoteExtras;
  QuoteIncident: QuoteIncident;
  QuoteIncidentDetails: QuoteIncidentDetails;
  QuoteMutation: QuoteMutation;
  QuotePersonalDetails: QuotePersonalDetails;
  QuotePremiumPriceBreakdown: QuotePremiumPriceBreakdown;
  QuotePremiumPriceBreakdownDetails: QuotePremiumPriceBreakdownDetails;
  QuoteProposer: QuoteProposer;
  QuoteQuery: QuoteQuery;
  QuoteValidationError: QuoteValidationError;
  QuoteVehicle: QuoteVehicle;
  QuoteVehicleDetails: QuoteVehicleDetails;
  QuotesResult: QuotesResult;
  Rate: Rate;
  RebrokedQuoteResult: RebrokedQuoteResult;
  RegisteredVehicle: RegisteredVehicle;
  RenewalDocuments: RenewalDocuments;
  RenewalNotice: RenewalNotice;
  RenewalsMutation: RenewalsMutation;
  RenewalsQuery: RenewalsQuery;
  RequestPrintedDocumentsV2Params: RequestPrintedDocumentsV2Params;
  RequestPrintedDocumentsV2Result: RequestPrintedDocumentsV2Result;
  RetrieveAddressResult: RetrieveAddressResult;
  SaveQuoteParams: SaveQuoteParams;
  SaveQuoteResult: SaveQuoteResult;
  StepbackRule: StepbackRule;
  StepbackRules: StepbackRules;
  String: Scalars['String']['output'];
  SuccessfulQuoteResult: SuccessfulQuoteResult;
  ThreeDSecureResponse: ThreeDSecureResponse;
  ThreeDSecureResponseChallenge: ThreeDSecureResponseChallenge;
  ThreeDSecureResponseMpi: ThreeDSecureResponseMpi;
  UpdatePolicyHolderMarketingPreferencesResult: UpdatePolicyHolderMarketingPreferencesResult;
  UploadPresignedUrl: UploadPresignedUrl;
  UpsertQuoteExtrasResponse: UpsertQuoteExtrasResponse;
  ValidateBankDetailsParams: ValidateBankDetailsParams;
  ValidateBankDetailsResult: ValidateBankDetailsResult;
  VehicleAlarm: VehicleAlarm;
  VehicleAlarmDetails: VehicleAlarmDetails;
  VehicleOptions: VehicleOptions;
  VehicleOptionsFilters: VehicleOptionsFilters;
  VehicleQuery: VehicleQuery;
  VehicleSpecLookupFilters: VehicleSpecLookupFilters;
  VehicleSpecLookupItem: VehicleSpecLookupItem;
  ZendeskAuthenticationTokenResult: ZendeskAuthenticationTokenResult;
  ZendeskQuery: ZendeskQuery;
  ZeroDepositFinancedProcessRenewalMutation: ZeroDepositFinancedProcessRenewalMutation;
  ZeroDepositFinancedProcessRenewalRequest: ZeroDepositFinancedProcessRenewalRequest;
  ZeroDepositPolicySetUpRequest: ZeroDepositPolicySetUpRequest;
  ZeroDepositPolicySetupMutation: ZeroDepositPolicySetupMutation;
}>;

export type Aws_Api_KeyDirectiveArgs = { };

export type Aws_Api_KeyDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Api_KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AbiCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['AbiCodes'] = ResolversParentTypes['AbiCodes']> = ResolversObject<{
  abiListOptions?: Resolver<Array<ResolversTypes['AbiListItem']>, ParentType, ContextType, RequireFields<AbiCodesAbiListOptionsArgs, 'abiCode'>>;
  all?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  genders?: Resolver<Array<ResolversTypes['AbiListItem']>, ParentType, ContextType>;
  industries?: Resolver<Array<ResolversTypes['AbiListItem']>, ParentType, ContextType>;
  jobs?: Resolver<Array<ResolversTypes['AbiListItem']>, ParentType, ContextType>;
  titles?: Resolver<Array<ResolversTypes['AbiListItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AbiListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AbiListItem'] = ResolversParentTypes['AbiListItem']> = ResolversObject<{
  vt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vtCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vtDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdditionalDriverResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalDriver'] = ResolversParentTypes['AdditionalDriver']> = ResolversObject<{
  businessMileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  claims?: Resolver<Array<ResolversTypes['PolicyClaim']>, ParentType, ContextType>;
  classOfUse?: Resolver<Maybe<ResolversTypes['ClassOfUse']>, ParentType, ContextType>;
  convictions?: Resolver<Array<ResolversTypes['PolicyConviction']>, ParentType, ContextType>;
  createdOn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  hasCancellations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isMainDriver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licence?: Resolver<ResolversTypes['Licence'], ParentType, ContextType>;
  maritalStatus?: Resolver<ResolversTypes['MaritalStatus'], ParentType, ContextType>;
  medicalConditionsDeclared?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  primaryEmployment?: Resolver<ResolversTypes['Employment'], ParentType, ContextType>;
  relationshipToPolicyHolder?: Resolver<ResolversTypes['RelationshipToProposer'], ParentType, ContextType>;
  secondaryEmployment?: Resolver<Maybe<ResolversTypes['Employment']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ukResidencyDurationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedOn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = ResolversObject<{
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postcode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressQuery'] = ResolversParentTypes['AddressQuery']> = ResolversObject<{
  findAddress?: Resolver<Array<ResolversTypes['FindAddressResult']>, ParentType, ContextType, RequireFields<AddressQueryFindAddressArgs, 'params'>>;
  retrieveAddress?: Resolver<ResolversTypes['RetrieveAddressResult'], ParentType, ContextType, RequireFields<AddressQueryRetrieveAddressArgs, 'id'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AggregatorQuoteDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatorQuoteDetails'] = ResolversParentTypes['AggregatorQuoteDetails']> = ResolversObject<{
  compulsoryExcess?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  compulsoryExcessAsPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  insurer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurerSchemeReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  magicLinkId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  premiumAsPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  premiumPriceBreakdown?: Resolver<Maybe<ResolversTypes['QuotePremiumPriceBreakdown']>, ParentType, ContextType>;
  premiumPriceBreakdownDetails?: Resolver<Maybe<ResolversTypes['QuotePremiumPriceBreakdownDetails']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quoteGenerationDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referredBy?: Resolver<Maybe<ResolversTypes['Aggregator']>, ParentType, ContextType>;
  sequenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalExtrasPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalUnfinancedAmountPayable?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['QuoteType']>, ParentType, ContextType>;
  voluntaryExcess?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voluntaryExcessAsPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AggregatorQuoteExtraResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatorQuoteExtra'] = ResolversParentTypes['AggregatorQuoteExtra']> = ResolversObject<{
  optionType?: Resolver<ResolversTypes['PolicyExtraOptionType'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PolicyExtraType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AggregatorQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatorQuoteResult'] = ResolversParentTypes['AggregatorQuoteResult']> = ResolversObject<{
  additionalDrivers?: Resolver<Maybe<Array<ResolversTypes['QuoteAdditionalDriverDetails']>>, ParentType, ContextType>;
  coverDetails?: Resolver<ResolversTypes['QuoteCoverDetails'], ParentType, ContextType>;
  personalDetails?: Resolver<ResolversTypes['QuotePersonalDetails'], ParentType, ContextType>;
  quoteDetails?: Resolver<ResolversTypes['AggregatorQuoteDetails'], ParentType, ContextType>;
  quoteExtras?: Resolver<Maybe<Array<ResolversTypes['AggregatorQuoteExtra']>>, ParentType, ContextType>;
  quoteValidationErrors?: Resolver<Maybe<Array<ResolversTypes['QuoteValidationError']>>, ParentType, ContextType>;
  vehicleDetails?: Resolver<ResolversTypes['QuoteVehicleDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApplePayPolicySetupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplePayPolicySetupMutation'] = ResolversParentTypes['ApplePayPolicySetupMutation']> = ResolversObject<{
  setUpFinancedPolicy?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<ApplePayPolicySetupMutationSetUpFinancedPolicyArgs, 'request'>>;
  setUpFullPolicy?: Resolver<ResolversTypes['FullPolicyPaymentResponse'], ParentType, ContextType, RequireFields<ApplePayPolicySetupMutationSetUpFullPolicyArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApplePayProcessRenewalMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplePayProcessRenewalMutation'] = ResolversParentTypes['ApplePayProcessRenewalMutation']> = ResolversObject<{
  processFinancedRenewal?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<ApplePayProcessRenewalMutationProcessFinancedRenewalArgs, 'request'>>;
  processFullRenewal?: Resolver<ResolversTypes['FullPolicyPaymentResponse'], ParentType, ContextType, RequireFields<ApplePayProcessRenewalMutationProcessFullRenewalArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApplePaySessionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplePaySessionResponse'] = ResolversParentTypes['ApplePaySessionResponse']> = ResolversObject<{
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  domainName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  epochTimestamp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  merchantIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantSessionIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nonce?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operationalAnalyticsIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  retries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankDetailsQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankDetailsQuery'] = ResolversParentTypes['BankDetailsQuery']> = ResolversObject<{
  validateBankDetails?: Resolver<ResolversTypes['ValidateBankDetailsResult'], ParentType, ContextType, RequireFields<BankDetailsQueryValidateBankDetailsArgs, 'params'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClaimMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimMutation'] = ResolversParentTypes['ClaimMutation']> = ResolversObject<{
  submitClaim?: Resolver<ResolversTypes['Result'], ParentType, ContextType, RequireFields<ClaimMutationSubmitClaimArgs, 'explanation' | 'incidentDate' | 'phoneNumber' | 'policyId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClassOfUseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClassOfUse'] = ResolversParentTypes['ClassOfUse']> = ResolversObject<{
  hasAnothersBusiness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasCommuting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasEmployersBusiness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPersonalBusiness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSoliciting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloseBrothersDocumentQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloseBrothersDocumentQuery'] = ResolversParentTypes['CloseBrothersDocumentQuery']> = ResolversObject<{
  directDebits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qAndA?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyCarExperienceDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCarExperienceDetails'] = ResolversParentTypes['CompanyCarExperienceDetails']> = ResolversObject<{
  companyCarExperienceEndDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  yearsNoClaimsBonus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConvictionOffenceItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConvictionOffenceItem'] = ResolversParentTypes['ConvictionOffenceItem']> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oldReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConvictionTypeItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConvictionTypeItem'] = ResolversParentTypes['ConvictionTypeItem']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentCategoryDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConvictionsQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConvictionsQuery'] = ResolversParentTypes['ConvictionsQuery']> = ResolversObject<{
  offences?: Resolver<Array<ResolversTypes['ConvictionOffenceItem']>, ParentType, ContextType, RequireFields<ConvictionsQueryOffencesArgs, 'typeId'>>;
  types?: Resolver<Array<ResolversTypes['ConvictionTypeItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoverDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoverDetails'] = ResolversParentTypes['CoverDetails']> = ResolversObject<{
  annualPremium?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  breakInCoverDeclaration?: Resolver<ResolversTypes['BreakInCoverDeclaration'], ParentType, ContextType>;
  coverType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  excess?: Resolver<ResolversTypes['Excess'], ParentType, ContextType>;
  firstStartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  noClaimsBonusYears?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noClaimsProtection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = ResolversObject<{
  fileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastModifiedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DocumentType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DocumentationQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentationQuery'] = ResolversParentTypes['DocumentationQuery']> = ResolversObject<{
  closeBrothers?: Resolver<Maybe<ResolversTypes['CloseBrothersDocumentQuery']>, ParentType, ContextType>;
  policyBooklet?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<DocumentationQueryPolicyBookletArgs, 'schemeReference'>>;
  policyExtras?: Resolver<Maybe<Array<ResolversTypes['Document']>>, ParentType, ContextType, RequireFields<DocumentationQueryPolicyExtrasArgs, 'policyExtraDocuments'>>;
  requestPrintedDocumentsV2?: Resolver<Maybe<ResolversTypes['RequestPrintedDocumentsV2Result']>, ParentType, ContextType, RequireFields<DocumentationQueryRequestPrintedDocumentsV2Args, 'params'>>;
  termsOfBusinessAgreement?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, Partial<DocumentationQueryTermsOfBusinessAgreementArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DrivingBanResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrivingBan'] = ResolversParentTypes['DrivingBan']> = ResolversObject<{
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailLatestSavedQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailLatestSavedQuoteResult'] = ResolversParentTypes['EmailLatestSavedQuoteResult']> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmploymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employment'] = ResolversParentTypes['Employment']> = ResolversObject<{
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  industryVtCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  job?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobVtCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EmploymentStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExcessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Excess'] = ResolversParentTypes['Excess']> = ResolversObject<{
  compulsory?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voluntary?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FailedQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FailedQuoteResult'] = ResolversParentTypes['FailedQuoteResult']> = ResolversObject<{
  magicLinkId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchProspectiveLoansResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchProspectiveLoansResponse'] = ResolversParentTypes['FetchProspectiveLoansResponse']> = ResolversObject<{
  extrasTotalAmount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  prospectiveLoans?: Resolver<Maybe<Array<ResolversTypes['ProspectiveLoanWithPrice']>>, ParentType, ContextType>;
  totalUnfinancedAmountPayable?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancedPolicyPaymentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancedPolicyPaymentResponse'] = ResolversParentTypes['FinancedPolicyPaymentResponse']> = ResolversObject<{
  result?: Resolver<ResolversTypes['FinancedPolicyPaymentResult'], ParentType, ContextType>;
  threeDSecureResponse?: Resolver<Maybe<ResolversTypes['ThreeDSecureResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FindAddressResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FindAddressResult'] = ResolversParentTypes['FindAddressResult']> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAddress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FullPolicyPaymentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FullPolicyPaymentResponse'] = ResolversParentTypes['FullPolicyPaymentResponse']> = ResolversObject<{
  result?: Resolver<ResolversTypes['FullPolicyPaymentResult'], ParentType, ContextType>;
  threeDSecureResponse?: Resolver<Maybe<ResolversTypes['ThreeDSecureResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GooglePayPolicySetupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GooglePayPolicySetupMutation'] = ResolversParentTypes['GooglePayPolicySetupMutation']> = ResolversObject<{
  setUpFinancedPolicy?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<GooglePayPolicySetupMutationSetUpFinancedPolicyArgs, 'request'>>;
  setUpFullPolicy?: Resolver<ResolversTypes['FullPolicyPaymentResponse'], ParentType, ContextType, RequireFields<GooglePayPolicySetupMutationSetUpFullPolicyArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GooglePayProcessRenewalMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GooglePayProcessRenewalMutation'] = ResolversParentTypes['GooglePayProcessRenewalMutation']> = ResolversObject<{
  processFinancedRenewal?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<GooglePayProcessRenewalMutationProcessFinancedRenewalArgs, 'request'>>;
  processFullRenewal?: Resolver<ResolversTypes['FullPolicyPaymentResponse'], ParentType, ContextType, RequireFields<GooglePayProcessRenewalMutationProcessFullRenewalArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HealthCheckResolvers<ContextType = any, ParentType extends ResolversParentTypes['HealthCheck'] = ResolversParentTypes['HealthCheck']> = ResolversObject<{
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['HealthCheckStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HealthCheckResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['HealthCheckResponse'] = ResolversParentTypes['HealthCheckResponse']> = ResolversObject<{
  healthChecks?: Resolver<Array<Maybe<ResolversTypes['HealthCheck']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['HealthCheckStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HostedPaymentPagePolicySetupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostedPaymentPagePolicySetupMutation'] = ResolversParentTypes['HostedPaymentPagePolicySetupMutation']> = ResolversObject<{
  setUpFinancedPolicy?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<HostedPaymentPagePolicySetupMutationSetUpFinancedPolicyArgs, 'request'>>;
  setUpFullPolicy?: Resolver<ResolversTypes['FullPolicyPaymentResponse'], ParentType, ContextType, RequireFields<HostedPaymentPagePolicySetupMutationSetUpFullPolicyArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HostedPaymentPageProcessRenewalMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostedPaymentPageProcessRenewalMutation'] = ResolversParentTypes['HostedPaymentPageProcessRenewalMutation']> = ResolversObject<{
  processFinancedRenewal?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<HostedPaymentPageProcessRenewalMutationProcessFinancedRenewalArgs, 'request'>>;
  processFullRenewal?: Resolver<ResolversTypes['FullPolicyPaymentResponse'], ParentType, ContextType, RequireFields<HostedPaymentPageProcessRenewalMutationProcessFullRenewalArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InsurerSchemeQuoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsurerSchemeQuote'] = ResolversParentTypes['InsurerSchemeQuote']> = ResolversObject<{
  compulsoryExcess?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  compulsoryExcessPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  insurer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurerSchemeReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  premium?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  premiumPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  premiumPriceBreakdown?: Resolver<Maybe<ResolversTypes['QuotePremiumPriceBreakdown']>, ParentType, ContextType>;
  premiumPriceBreakdownDetails?: Resolver<Maybe<ResolversTypes['QuotePremiumPriceBreakdownDetails']>, ParentType, ContextType>;
  quoteType?: Resolver<ResolversTypes['QuoteType'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sequenceNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnfinancedAmountPayable?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  voluntaryExcess?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voluntaryExcessPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LicenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Licence'] = ResolversParentTypes['Licence']> = ResolversObject<{
  countryOfIssue?: Resolver<ResolversTypes['CountryOfIssue'], ParentType, ContextType>;
  dateObtained?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationMonths?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['LicenceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoadQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoadQuoteResult'] = ResolversParentTypes['LoadQuoteResult']> = ResolversObject<{
  policyStartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quoteGenerationDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['SuccessfulQuoteResult'], ParentType, ContextType>;
  serialisedQuote?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoanDepositOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoanDepositOption'] = ResolversParentTypes['LoanDepositOption']> = ResolversObject<{
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MidTermAdjustmentMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MidTermAdjustmentMetadata'] = ResolversParentTypes['MidTermAdjustmentMetadata']> = ResolversObject<{
  effectiveDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  claim?: Resolver<ResolversTypes['ClaimMutation'], ParentType, ContextType>;
  policyHolder?: Resolver<ResolversTypes['PolicyHolderMutation'], ParentType, ContextType>;
  policySetup?: Resolver<ResolversTypes['PolicySetupMutation'], ParentType, ContextType>;
  quote?: Resolver<ResolversTypes['QuoteMutation'], ParentType, ContextType>;
  renewals?: Resolver<ResolversTypes['RenewalsMutation'], ParentType, ContextType>;
}>;

export type NamedDriverExperienceDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NamedDriverExperienceDetails'] = ResolversParentTypes['NamedDriverExperienceDetails']> = ResolversObject<{
  yearsNoClaimsBonus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NoClaimsDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['NoClaimsDiscount'] = ResolversParentTypes['NoClaimsDiscount']> = ResolversObject<{
  averageDiscount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NoClaimsDiscountProtectionDataResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NoClaimsDiscountProtectionDataResult'] = ResolversParentTypes['NoClaimsDiscountProtectionDataResult']> = ResolversObject<{
  discounts?: Resolver<Array<ResolversTypes['NoClaimsDiscount']>, ParentType, ContextType>;
  stepbackRules?: Resolver<Array<ResolversTypes['StepbackRules']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentDetails'] = ResolversParentTypes['PaymentDetails']> = ResolversObject<{
  loanReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchant?: Resolver<ResolversTypes['MerchantType'], ParentType, ContextType>;
  paymentDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['BillingPeriod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Policy'] = ResolversParentTypes['Policy']> = ResolversObject<{
  additionalDrivers?: Resolver<Array<ResolversTypes['AdditionalDriver']>, ParentType, ContextType>;
  branchReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coverDetails?: Resolver<ResolversTypes['CoverDetails'], ParentType, ContextType>;
  documentation?: Resolver<ResolversTypes['PolicyDocumentation'], ParentType, ContextType>;
  effectiveDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['PolicyMetadata'], ParentType, ContextType>;
  paymentDetails?: Resolver<Maybe<ResolversTypes['PaymentDetails']>, ParentType, ContextType>;
  policyExtrasMetadata?: Resolver<Maybe<Array<ResolversTypes['PolicyExtraMetadata']>>, ParentType, ContextType>;
  policyHolderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policyReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policyStatus?: Resolver<ResolversTypes['PolicyStatus'], ParentType, ContextType>;
  quoteDetails?: Resolver<Maybe<ResolversTypes['QuoteDetails']>, ParentType, ContextType>;
  renewalNotice?: Resolver<Maybe<ResolversTypes['RenewalNotice']>, ParentType, ContextType>;
  schemeReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vehicleDetails?: Resolver<ResolversTypes['PolicyVehicleDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyClaimResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyClaim'] = ResolversParentTypes['PolicyClaim']> = ResolversObject<{
  accidentDescription?: Resolver<Maybe<ResolversTypes['AccidentDescription']>, ParentType, ContextType>;
  atFault?: Resolver<ResolversTypes['AtFault'], ParentType, ContextType>;
  bodilyInjuryOccurred?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  incidentType?: Resolver<ResolversTypes['IncidentType'], ParentType, ContextType>;
  noClaimsAffected?: Resolver<Maybe<ResolversTypes['NoClaimsAffected']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  whichPolicyClaimedAgainst?: Resolver<ResolversTypes['WhichPolicyClaimedAgainst'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyConvictionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyConviction'] = ResolversParentTypes['PolicyConviction']> = ResolversObject<{
  alcoholLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  drivingBan?: Resolver<Maybe<ResolversTypes['DrivingBan']>, ParentType, ContextType>;
  fine?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offence?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentCategoryDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  testMethod?: Resolver<Maybe<ResolversTypes['AlcoholTestMethod']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyDocument'] = ResolversParentTypes['PolicyDocument']> = ResolversObject<{
  effectiveDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DocumentType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyDocumentationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyDocumentation'] = ResolversParentTypes['PolicyDocumentation']> = ResolversObject<{
  active?: Resolver<Maybe<Array<ResolversTypes['PolicyDocument']>>, ParentType, ContextType>;
  archived?: Resolver<Maybe<Array<ResolversTypes['PolicyDocument']>>, ParentType, ContextType>;
  upcoming?: Resolver<Maybe<Array<ResolversTypes['PolicyDocument']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyExtraCostResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyExtraCost'] = ResolversParentTypes['PolicyExtraCost']> = ResolversObject<{
  gross?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  net?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tax?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyExtraMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyExtraMetadata'] = ResolversParentTypes['PolicyExtraMetadata']> = ResolversObject<{
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionType?: Resolver<Maybe<ResolversTypes['PolicyExtraOptionType']>, ParentType, ContextType>;
  policyExtraCost?: Resolver<Maybe<ResolversTypes['PolicyExtraCost']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PolicyExtraType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyHolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyHolder'] = ResolversParentTypes['PolicyHolder']> = ResolversObject<{
  accountReminderSentOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  businessMileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  claims?: Resolver<Array<ResolversTypes['PolicyClaim']>, ParentType, ContextType>;
  classOfUse?: Resolver<Maybe<ResolversTypes['ClassOfUse']>, ParentType, ContextType>;
  clientRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  convictions?: Resolver<Array<ResolversTypes['PolicyConviction']>, ParentType, ContextType>;
  createdOn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  effectiveDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  hasCancellations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isHomeowner?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isMainDriver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licence?: Resolver<ResolversTypes['Licence'], ParentType, ContextType>;
  maritalStatus?: Resolver<ResolversTypes['MaritalStatus'], ParentType, ContextType>;
  medicalCondition?: Resolver<Maybe<ResolversTypes['MedicalCondition']>, ParentType, ContextType>;
  medicalConditionsDeclared?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['PolicyHolderMetadata'], ParentType, ContextType>;
  mileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  noClaimsBonusYears?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryEmployment?: Resolver<ResolversTypes['Employment'], ParentType, ContextType>;
  secondaryEmployment?: Resolver<Maybe<ResolversTypes['Employment']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ukResidencyDurationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedOn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyHolderMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyHolderMetadata'] = ResolversParentTypes['PolicyHolderMetadata']> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailMarketingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smsMarketingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyHolderMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyHolderMutation'] = ResolversParentTypes['PolicyHolderMutation']> = ResolversObject<{
  emailConfirmPasswordChange?: Resolver<ResolversTypes['Result'], ParentType, ContextType>;
  updateMetadataMarketingPreferences?: Resolver<ResolversTypes['UpdatePolicyHolderMarketingPreferencesResult'], ParentType, ContextType, RequireFields<PolicyHolderMutationUpdateMetadataMarketingPreferencesArgs, 'emailMarketingEnabled' | 'smsMarketingEnabled'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyListResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyList'] = ResolversParentTypes['PolicyList']> = ResolversObject<{
  active?: Resolver<Maybe<Array<ResolversTypes['Policy']>>, ParentType, ContextType>;
  cancelled?: Resolver<Maybe<Array<ResolversTypes['Policy']>>, ParentType, ContextType>;
  expired?: Resolver<Maybe<Array<ResolversTypes['Policy']>>, ParentType, ContextType>;
  upcoming?: Resolver<Maybe<Array<ResolversTypes['Policy']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyMetadata'] = ResolversParentTypes['PolicyMetadata']> = ResolversObject<{
  agentWorkflowStage?: Resolver<ResolversTypes['AgentWorkflowStage'], ParentType, ContextType>;
  agentWorkflowStageUpdatedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coverEndDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coverStartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policyHolderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  renewalInviteStatus?: Resolver<Maybe<ResolversTypes['RenewalInviteStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicySetupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicySetupMutation'] = ResolversParentTypes['PolicySetupMutation']> = ResolversObject<{
  applePay?: Resolver<ResolversTypes['ApplePayPolicySetupMutation'], ParentType, ContextType>;
  googlePay?: Resolver<ResolversTypes['GooglePayPolicySetupMutation'], ParentType, ContextType>;
  hostedPaymentPage?: Resolver<ResolversTypes['HostedPaymentPagePolicySetupMutation'], ParentType, ContextType>;
  zeroDeposit?: Resolver<ResolversTypes['ZeroDepositPolicySetupMutation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicySetupQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicySetupQuery'] = ResolversParentTypes['PolicySetupQuery']> = ResolversObject<{
  applePaySession?: Resolver<ResolversTypes['ApplePaySessionResponse'], ParentType, ContextType>;
  fetchProspectiveLoans?: Resolver<ResolversTypes['FetchProspectiveLoansResponse'], ParentType, ContextType, RequireFields<PolicySetupQueryFetchProspectiveLoansArgs, 'quoteReference' | 'quoteSequenceNumber'>>;
  googlePayMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policyExtras?: Resolver<Array<ResolversTypes['ProspectivePolicyExtra']>, ParentType, ContextType, Partial<PolicySetupQueryPolicyExtrasArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PolicyVehicleDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PolicyVehicleDetails'] = ResolversParentTypes['PolicyVehicleDetails']> = ResolversObject<{
  carCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfPurchase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasTrackingDeviceInstalled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  immobiliserType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  keeper?: Resolver<ResolversTypes['VehicleHolder'], ParentType, ContextType>;
  modifications?: Resolver<Array<ResolversTypes['ModificationType']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['VehicleHolder'], ParentType, ContextType>;
  registeredVehicle?: Resolver<ResolversTypes['RegisteredVehicle'], ParentType, ContextType>;
  storedAtNight?: Resolver<ResolversTypes['StoredAtNight'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = ResolversObject<{
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProspectiveLoanResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProspectiveLoan'] = ResolversParentTypes['ProspectiveLoan']> = ResolversObject<{
  apr?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstInstalmentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfInstalments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Rate'], ParentType, ContextType>;
  subsequentInstalmentAmounts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalAmountPayable?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProspectiveLoanWithPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProspectiveLoanWithPrice'] = ResolversParentTypes['ProspectiveLoanWithPrice']> = ResolversObject<{
  apr?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  financeFee?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  firstInstalmentAmount?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  loanDepositOptions?: Resolver<Array<ResolversTypes['LoanDepositOption']>, ParentType, ContextType>;
  numberOfInstalments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rateReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subsequentInstalmentAmounts?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  totalAmountPayable?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  totalAmountWithoutExtras?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalFinance?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProspectivePolicyExtraResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProspectivePolicyExtra'] = ResolversParentTypes['ProspectivePolicyExtra']> = ResolversObject<{
  document?: Resolver<ResolversTypes['Document'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['ProspectivePolicyExtraOption']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PolicyExtraType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProspectivePolicyExtraOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProspectivePolicyExtraOption'] = ResolversParentTypes['ProspectivePolicyExtraOption']> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  excessProtectedAmount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  optionType?: Resolver<ResolversTypes['PolicyExtraOptionType'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  address?: Resolver<ResolversTypes['AddressQuery'], ParentType, ContextType>;
  bankDetails?: Resolver<ResolversTypes['BankDetailsQuery'], ParentType, ContextType>;
  convictions?: Resolver<ResolversTypes['ConvictionsQuery'], ParentType, ContextType>;
  documentation?: Resolver<ResolversTypes['DocumentationQuery'], ParentType, ContextType>;
  generatePolicyHolderDocumentUploadPresignedUrl?: Resolver<ResolversTypes['UploadPresignedUrl'], ParentType, ContextType, RequireFields<QueryGeneratePolicyHolderDocumentUploadPresignedUrlArgs, 'policyId'>>;
  healthCheck?: Resolver<ResolversTypes['HealthCheckResponse'], ParentType, ContextType, RequireFields<QueryHealthCheckArgs, 'healthChecks'>>;
  listPolicies?: Resolver<ResolversTypes['PolicyList'], ParentType, ContextType>;
  midTermAdjustmentMetadata?: Resolver<Maybe<Array<ResolversTypes['MidTermAdjustmentMetadata']>>, ParentType, ContextType, RequireFields<QueryMidTermAdjustmentMetadataArgs, 'policyId'>>;
  policies?: Resolver<Array<ResolversTypes['Policy']>, ParentType, ContextType>;
  policy?: Resolver<ResolversTypes['Policy'], ParentType, ContextType, RequireFields<QueryPolicyArgs, 'id'>>;
  policyHolder?: Resolver<ResolversTypes['PolicyHolder'], ParentType, ContextType>;
  policySetup?: Resolver<ResolversTypes['PolicySetupQuery'], ParentType, ContextType>;
  quote?: Resolver<ResolversTypes['QuoteQuery'], ParentType, ContextType>;
  renewals?: Resolver<ResolversTypes['RenewalsQuery'], ParentType, ContextType>;
  vehicle?: Resolver<ResolversTypes['VehicleQuery'], ParentType, ContextType>;
  zendesk?: Resolver<ResolversTypes['ZendeskQuery'], ParentType, ContextType>;
}>;

export type QuoteAdditionalDriverDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteAdditionalDriverDetails'] = ResolversParentTypes['QuoteAdditionalDriverDetails']> = ResolversObject<{
  convictions?: Resolver<Array<ResolversTypes['QuoteConvictionDetails']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  hasCancellations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNonMotoringConvictions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  householdNumberOfCars?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  incidents?: Resolver<Array<ResolversTypes['QuoteIncidentDetails']>, ParentType, ContextType>;
  isMainDriver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licenceCountry?: Resolver<ResolversTypes['CountryOfIssue'], ParentType, ContextType>;
  licenceDurationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  licenceType?: Resolver<ResolversTypes['LicenceType'], ParentType, ContextType>;
  maritalStatus?: Resolver<ResolversTypes['MaritalStatus'], ParentType, ContextType>;
  medicalCondition?: Resolver<Maybe<ResolversTypes['MedicalCondition']>, ParentType, ContextType>;
  primaryEmployment?: Resolver<ResolversTypes['QuoteEmploymentDetails'], ParentType, ContextType>;
  proposedClassOfUse?: Resolver<ResolversTypes['ProposedClassOfUse'], ParentType, ContextType>;
  relationshipToProposer?: Resolver<ResolversTypes['RelationshipToProposer'], ParentType, ContextType>;
  secondaryEmployment?: Resolver<Maybe<ResolversTypes['QuoteEmploymentDetails']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['AbiListItem'], ParentType, ContextType>;
  ukResidencyDurationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteConvictionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteConvictionDetails'] = ResolversParentTypes['QuoteConvictionDetails']> = ResolversObject<{
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disqualificationPeriodMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fine?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offence?: Resolver<ResolversTypes['ConvictionOffenceItem'], ParentType, ContextType>;
  offenceCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offenceCodeNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  penaltyPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sampleLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sampleType?: Resolver<Maybe<ResolversTypes['AlcoholTestMethod']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ConvictionTypeItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteCoverDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteCoverDetails'] = ResolversParentTypes['QuoteCoverDetails']> = ResolversObject<{
  breakInCoverDeclaration?: Resolver<Maybe<ResolversTypes['BreakInCoverDeclaration']>, ParentType, ContextType>;
  businessMileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  companyCarExperience?: Resolver<Maybe<ResolversTypes['CompanyCarExperienceDetails']>, ParentType, ContextType>;
  coverType?: Resolver<ResolversTypes['CoverType'], ParentType, ContextType>;
  mileage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  namedDriverExperience?: Resolver<Maybe<ResolversTypes['NamedDriverExperienceDetails']>, ParentType, ContextType>;
  periodBetweenInsurance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  previouslyHeldInsurance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  protectNoClaimsBonus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  voluntaryExcess?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  yearsNoClaimsBonus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteDetails'] = ResolversParentTypes['QuoteDetails']> = ResolversObject<{
  quoteId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quoteReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quoteSequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quoteType?: Resolver<Maybe<ResolversTypes['QuoteType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteDocumentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteDocuments'] = ResolversParentTypes['QuoteDocuments']> = ResolversObject<{
  insuranceProductInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  policyBooklet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteEmploymentDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteEmploymentDetails'] = ResolversParentTypes['QuoteEmploymentDetails']> = ResolversObject<{
  industry?: Resolver<ResolversTypes['AbiListItem'], ParentType, ContextType>;
  job?: Resolver<ResolversTypes['AbiListItem'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EmploymentStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteIncidentDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteIncidentDetails'] = ResolversParentTypes['QuoteIncidentDetails']> = ResolversObject<{
  accidentDescription?: Resolver<Maybe<ResolversTypes['AccidentDescription']>, ParentType, ContextType>;
  atFault?: Resolver<ResolversTypes['AtFault'], ParentType, ContextType>;
  bodilyInjuryOccurred?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  incidentType?: Resolver<ResolversTypes['IncidentType'], ParentType, ContextType>;
  noClaimsAffected?: Resolver<ResolversTypes['NoClaimsAffected'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ClaimStatus'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  whichPolicyClaimedAgainst?: Resolver<ResolversTypes['WhichPolicyClaimedAgainst'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteMutation'] = ResolversParentTypes['QuoteMutation']> = ResolversObject<{
  getAggregatorQuote?: Resolver<ResolversTypes['AggregatorQuoteResult'], ParentType, ContextType, RequireFields<QuoteMutationGetAggregatorQuoteArgs, 'quoteReference'>>;
  getQuotes?: Resolver<ResolversTypes['QuotesResult'], ParentType, ContextType, RequireFields<QuoteMutationGetQuotesArgs, 'additionalDrivers' | 'cover' | 'proposer' | 'quoteExtras' | 'vehicle'>>;
  getRebrokedQuote?: Resolver<Maybe<ResolversTypes['RebrokedQuoteResult']>, ParentType, ContextType, RequireFields<QuoteMutationGetRebrokedQuoteArgs, 'policyId' | 'protectNoClaimsBonus'>>;
  saveQuotes?: Resolver<Maybe<ResolversTypes['SaveQuoteResult']>, ParentType, ContextType, RequireFields<QuoteMutationSaveQuotesArgs, 'params'>>;
  upsertQuoteExtras?: Resolver<ResolversTypes['UpsertQuoteExtrasResponse'], ParentType, ContextType, RequireFields<QuoteMutationUpsertQuoteExtrasArgs, 'quoteReference' | 'quoteSequence'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuotePersonalDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotePersonalDetails'] = ResolversParentTypes['QuotePersonalDetails']> = ResolversObject<{
  address?: Resolver<ResolversTypes['RetrieveAddressResult'], ParentType, ContextType>;
  convictions?: Resolver<Maybe<Array<ResolversTypes['QuoteConvictionDetails']>>, ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  hasCancellations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNonMotoringConvictions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  incidents?: Resolver<Maybe<Array<ResolversTypes['QuoteIncidentDetails']>>, ParentType, ContextType>;
  isHomeowner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMainDriver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licenceCountry?: Resolver<ResolversTypes['CountryOfIssue'], ParentType, ContextType>;
  licenceDurationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  licenceType?: Resolver<ResolversTypes['LicenceType'], ParentType, ContextType>;
  maritalStatus?: Resolver<ResolversTypes['MaritalStatus'], ParentType, ContextType>;
  medicalCondition?: Resolver<Maybe<ResolversTypes['MedicalCondition']>, ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primaryEmployment?: Resolver<ResolversTypes['QuoteEmploymentDetails'], ParentType, ContextType>;
  proposedClassOfUse?: Resolver<ResolversTypes['ProposedClassOfUse'], ParentType, ContextType>;
  secondaryEmployment?: Resolver<Maybe<ResolversTypes['QuoteEmploymentDetails']>, ParentType, ContextType>;
  soliciting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  storedAtNight?: Resolver<ResolversTypes['StoredAtNight'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['AbiListItem'], ParentType, ContextType>;
  ukResidencyDurationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuotePremiumPriceBreakdownResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotePremiumPriceBreakdown'] = ResolversParentTypes['QuotePremiumPriceBreakdown']> = ResolversObject<{
  adminFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  originalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuotePremiumPriceBreakdownDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotePremiumPriceBreakdownDetails'] = ResolversParentTypes['QuotePremiumPriceBreakdownDetails']> = ResolversObject<{
  adminFee?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  originalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteQuery'] = ResolversParentTypes['QuoteQuery']> = ResolversObject<{
  abiCodes?: Resolver<ResolversTypes['AbiCodes'], ParentType, ContextType>;
  documents?: Resolver<ResolversTypes['QuoteDocuments'], ParentType, ContextType, RequireFields<QuoteQueryDocumentsArgs, 'coverType' | 'effectiveDate' | 'quoteReference' | 'schemeReference'>>;
  doesPolicyHolderExist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QuoteQueryDoesPolicyHolderExistArgs, 'email'>>;
  emailLatestSavedQuote?: Resolver<Maybe<ResolversTypes['EmailLatestSavedQuoteResult']>, ParentType, ContextType, RequireFields<QuoteQueryEmailLatestSavedQuoteArgs, 'email'>>;
  loadQuotes?: Resolver<Maybe<ResolversTypes['LoadQuoteResult']>, ParentType, ContextType, RequireFields<QuoteQueryLoadQuotesArgs, 'magicLinkId' | 'quoteReference' | 'sequenceNumber'>>;
  noClaimsDiscountProtectionCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, RequireFields<QuoteQueryNoClaimsDiscountProtectionCostArgs, 'reference' | 'sequenceNumber'>>;
  noClaimsDiscountProtectionData?: Resolver<ResolversTypes['NoClaimsDiscountProtectionDataResult'], ParentType, ContextType, RequireFields<QuoteQueryNoClaimsDiscountProtectionDataArgs, 'coverStartDate' | 'schemeCode'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteValidationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteValidationError'] = ResolversParentTypes['QuoteValidationError']> = ResolversObject<{
  driverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errorUUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  section?: Resolver<ResolversTypes['QuoteValidationErrorSection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteVehicleDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteVehicleDetails'] = ResolversParentTypes['QuoteVehicleDetails']> = ResolversObject<{
  abi?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bodyStyle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfPurchase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  driverPosition?: Resolver<Maybe<ResolversTypes['DriverPosition']>, ParentType, ContextType>;
  engineCapacityCc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engineCapacityLitres?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  estimatedValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasTracker?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  importType?: Resolver<Maybe<ResolversTypes['ImportType']>, ParentType, ContextType>;
  isCommercialVehicle?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isImported?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modifications?: Resolver<Array<ResolversTypes['ModificationType']>, ParentType, ContextType>;
  registeredKeeper?: Resolver<ResolversTypes['VehicleHolder'], ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seats?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trim?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleAlarm?: Resolver<Maybe<ResolversTypes['VehicleAlarmDetails']>, ParentType, ContextType>;
  vehicleOwner?: Resolver<ResolversTypes['VehicleHolder'], ParentType, ContextType>;
  yearOfManufacture?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuotesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotesResult'] = ResolversParentTypes['QuotesResult']> = ResolversObject<{
  adminFee?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  magicLinkId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quotes?: Resolver<Maybe<Array<ResolversTypes['InsurerSchemeQuote']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate']> = ResolversObject<{
  apr?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  depositAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfInstalments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rateReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RebrokedQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RebrokedQuoteResult'] = ResolversParentTypes['RebrokedQuoteResult']> = ResolversObject<{
  magicLinkId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  noClaimsBonusProtectionCost?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  quote?: Resolver<Maybe<ResolversTypes['InsurerSchemeQuote']>, ParentType, ContextType>;
  totalExtrasPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegisteredVehicleResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredVehicle'] = ResolversParentTypes['RegisteredVehicle']> = ResolversObject<{
  abi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bodyStyle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commercialVehicle?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  doors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  driverPosition?: Resolver<Maybe<ResolversTypes['DriverPosition']>, ParentType, ContextType>;
  engineCapacityCc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engineCapacityLitres?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimatedValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fuelType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  importType?: Resolver<Maybe<ResolversTypes['ImportType']>, ParentType, ContextType>;
  imported?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  registration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  yearOfManufacture?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RenewalDocumentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RenewalDocuments'] = ResolversParentTypes['RenewalDocuments']> = ResolversObject<{
  insuranceProductInformation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policyBooklet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RenewalNoticeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RenewalNotice'] = ResolversParentTypes['RenewalNotice']> = ResolversObject<{
  adminFee?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  annualised?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  breakdownCover?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  brokerDiscount?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  canRenewOnline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  excessInsurance?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  expiryTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keyCare?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  lastYearsPremium?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  legalExpenses?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  renewalExcess?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  renewalPremium?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  renewalTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  renewalTotal?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  replacementCar?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RenewalsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RenewalsMutation'] = ResolversParentTypes['RenewalsMutation']> = ResolversObject<{
  applePay?: Resolver<ResolversTypes['ApplePayProcessRenewalMutation'], ParentType, ContextType>;
  googlePay?: Resolver<ResolversTypes['GooglePayProcessRenewalMutation'], ParentType, ContextType>;
  hostedPaymentPage?: Resolver<ResolversTypes['HostedPaymentPageProcessRenewalMutation'], ParentType, ContextType>;
  zeroDeposit?: Resolver<ResolversTypes['ZeroDepositFinancedProcessRenewalMutation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RenewalsQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RenewalsQuery'] = ResolversParentTypes['RenewalsQuery']> = ResolversObject<{
  documents?: Resolver<ResolversTypes['RenewalDocuments'], ParentType, ContextType, RequireFields<RenewalsQueryDocumentsArgs, 'policyId'>>;
  getProspectiveRenewalLoans?: Resolver<ResolversTypes['FetchProspectiveLoansResponse'], ParentType, ContextType, RequireFields<RenewalsQueryGetProspectiveRenewalLoansArgs, 'depositPercentage' | 'policyExtras' | 'policyId'>>;
  renewalNotice?: Resolver<Maybe<ResolversTypes['RenewalNotice']>, ParentType, ContextType, RequireFields<RenewalsQueryRenewalNoticeArgs, 'policyId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestPrintedDocumentsV2ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestPrintedDocumentsV2Result'] = ResolversParentTypes['RequestPrintedDocumentsV2Result']> = ResolversObject<{
  lastRequestedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['RequestPrintedDocumentsState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RetrieveAddressResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RetrieveAddressResult'] = ResolversParentTypes['RetrieveAddressResult']> = ResolversObject<{
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postcode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SaveQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaveQuoteResult'] = ResolversParentTypes['SaveQuoteResult']> = ResolversObject<{
  magicLinkId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sequenceNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StepbackRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepbackRule'] = ResolversParentTypes['StepbackRule']> = ResolversObject<{
  claims?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nonProtectedYears?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  protectedYears?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StepbackRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepbackRules'] = ResolversParentTypes['StepbackRules']> = ResolversObject<{
  rules?: Resolver<Array<ResolversTypes['StepbackRule']>, ParentType, ContextType>;
  years?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  yearsAtRenewal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuccessfulQuoteResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuccessfulQuoteResult'] = ResolversParentTypes['SuccessfulQuoteResult']> = ResolversObject<{
  classOfUse?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compulsoryExcess?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  compulsoryExcessAsPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  insurer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurerSchemeReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  magicLinkId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  premiumAsPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  premiumPriceBreakdown?: Resolver<Maybe<ResolversTypes['QuotePremiumPriceBreakdown']>, ParentType, ContextType>;
  premiumPriceBreakdownDetails?: Resolver<Maybe<ResolversTypes['QuotePremiumPriceBreakdownDetails']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quoteType?: Resolver<Maybe<ResolversTypes['QuoteType']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referredBy?: Resolver<Maybe<ResolversTypes['Aggregator']>, ParentType, ContextType>;
  sequenceNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalExtrasPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalUnfinancedAmountPayable?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  voluntaryExcess?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voluntaryExcessAsPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ThreeDSecureResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThreeDSecureResponse'] = ResolversParentTypes['ThreeDSecureResponse']> = ResolversObject<{
  acsTransactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authenticationRequestType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authenticationSource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cardholderResponseInfo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  challenge?: Resolver<ResolversTypes['ThreeDSecureResponseChallenge'], ParentType, ContextType>;
  challengeMandated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dsTransactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mpi?: Resolver<Maybe<ResolversTypes['ThreeDSecureResponseMpi']>, ParentType, ContextType>;
  notificationLambdaOrigin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serverTransactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ThreeDSecureResponseChallengeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThreeDSecureResponseChallenge'] = ResolversParentTypes['ThreeDSecureResponseChallenge']> = ResolversObject<{
  encodedChallengeRequest?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ThreeDSecureResponseMpiResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThreeDSecureResponseMpi'] = ResolversParentTypes['ThreeDSecureResponseMpi']> = ResolversObject<{
  authenticationValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eci?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePolicyHolderMarketingPreferencesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePolicyHolderMarketingPreferencesResult'] = ResolversParentTypes['UpdatePolicyHolderMarketingPreferencesResult']> = ResolversObject<{
  emailMarketingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  smsMarketingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadPresignedUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadPresignedUrl'] = ResolversParentTypes['UploadPresignedUrl']> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpsertQuoteExtrasResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertQuoteExtrasResponse'] = ResolversParentTypes['UpsertQuoteExtrasResponse']> = ResolversObject<{
  prospectiveLoans?: Resolver<Maybe<Array<ResolversTypes['ProspectiveLoanWithPrice']>>, ParentType, ContextType>;
  totalExtrasCost?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalUnfinancedAmountPayable?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValidateBankDetailsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidateBankDetailsResult'] = ResolversParentTypes['ValidateBankDetailsResult']> = ResolversObject<{
  isCorrect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VehicleAlarmDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleAlarmDetails'] = ResolversParentTypes['VehicleAlarmDetails']> = ResolversObject<{
  fittedBy?: Resolver<Maybe<ResolversTypes['AlarmInstaller']>, ParentType, ContextType>;
  hasAlarm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VehicleOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleOptions'] = ResolversParentTypes['VehicleOptions']> = ResolversObject<{
  engineCapacityLitres?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  fuelType?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  transmissionType?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  trim?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  yearsOfManufacture?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VehicleQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleQuery'] = ResolversParentTypes['VehicleQuery']> = ResolversObject<{
  makes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  models?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<VehicleQueryModelsArgs, 'make'>>;
  registrationLookup?: Resolver<ResolversTypes['RegisteredVehicle'], ParentType, ContextType, RequireFields<VehicleQueryRegistrationLookupArgs, 'registrationNumber'>>;
  vehicleOptions?: Resolver<ResolversTypes['VehicleOptions'], ParentType, ContextType, RequireFields<VehicleQueryVehicleOptionsArgs, 'optionsFilters'>>;
  vehicleSpecLookup?: Resolver<Array<ResolversTypes['VehicleSpecLookupItem']>, ParentType, ContextType, RequireFields<VehicleQueryVehicleSpecLookupArgs, 'specLookupFilters'>>;
  warmUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VehicleSpecLookupItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleSpecLookupItem'] = ResolversParentTypes['VehicleSpecLookupItem']> = ResolversObject<{
  abi?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bodyStyle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  brakeHorsepower?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  doors?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  engineCapacityCc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engineCapacityLitres?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fuelType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  make?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transmissionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trim?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  yearOfManufactureEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  yearOfManufactureStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZendeskAuthenticationTokenResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZendeskAuthenticationTokenResult'] = ResolversParentTypes['ZendeskAuthenticationTokenResult']> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZendeskQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZendeskQuery'] = ResolversParentTypes['ZendeskQuery']> = ResolversObject<{
  generateAuthenticationToken?: Resolver<Maybe<ResolversTypes['ZendeskAuthenticationTokenResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZeroDepositFinancedProcessRenewalMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZeroDepositFinancedProcessRenewalMutation'] = ResolversParentTypes['ZeroDepositFinancedProcessRenewalMutation']> = ResolversObject<{
  processFinancedRenewal?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<ZeroDepositFinancedProcessRenewalMutationProcessFinancedRenewalArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZeroDepositPolicySetupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZeroDepositPolicySetupMutation'] = ResolversParentTypes['ZeroDepositPolicySetupMutation']> = ResolversObject<{
  setUpFinancedPolicy?: Resolver<ResolversTypes['FinancedPolicyPaymentResponse'], ParentType, ContextType, RequireFields<ZeroDepositPolicySetupMutationSetUpFinancedPolicyArgs, 'request'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  AbiCodes?: AbiCodesResolvers<ContextType>;
  AbiListItem?: AbiListItemResolvers<ContextType>;
  AdditionalDriver?: AdditionalDriverResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AddressQuery?: AddressQueryResolvers<ContextType>;
  AggregatorQuoteDetails?: AggregatorQuoteDetailsResolvers<ContextType>;
  AggregatorQuoteExtra?: AggregatorQuoteExtraResolvers<ContextType>;
  AggregatorQuoteResult?: AggregatorQuoteResultResolvers<ContextType>;
  ApplePayPolicySetupMutation?: ApplePayPolicySetupMutationResolvers<ContextType>;
  ApplePayProcessRenewalMutation?: ApplePayProcessRenewalMutationResolvers<ContextType>;
  ApplePaySessionResponse?: ApplePaySessionResponseResolvers<ContextType>;
  BankDetailsQuery?: BankDetailsQueryResolvers<ContextType>;
  ClaimMutation?: ClaimMutationResolvers<ContextType>;
  ClassOfUse?: ClassOfUseResolvers<ContextType>;
  CloseBrothersDocumentQuery?: CloseBrothersDocumentQueryResolvers<ContextType>;
  CompanyCarExperienceDetails?: CompanyCarExperienceDetailsResolvers<ContextType>;
  ConvictionOffenceItem?: ConvictionOffenceItemResolvers<ContextType>;
  ConvictionTypeItem?: ConvictionTypeItemResolvers<ContextType>;
  ConvictionsQuery?: ConvictionsQueryResolvers<ContextType>;
  CoverDetails?: CoverDetailsResolvers<ContextType>;
  Document?: DocumentResolvers<ContextType>;
  DocumentationQuery?: DocumentationQueryResolvers<ContextType>;
  DrivingBan?: DrivingBanResolvers<ContextType>;
  EmailLatestSavedQuoteResult?: EmailLatestSavedQuoteResultResolvers<ContextType>;
  Employment?: EmploymentResolvers<ContextType>;
  Excess?: ExcessResolvers<ContextType>;
  FailedQuoteResult?: FailedQuoteResultResolvers<ContextType>;
  FetchProspectiveLoansResponse?: FetchProspectiveLoansResponseResolvers<ContextType>;
  FinancedPolicyPaymentResponse?: FinancedPolicyPaymentResponseResolvers<ContextType>;
  FindAddressResult?: FindAddressResultResolvers<ContextType>;
  FullPolicyPaymentResponse?: FullPolicyPaymentResponseResolvers<ContextType>;
  GooglePayPolicySetupMutation?: GooglePayPolicySetupMutationResolvers<ContextType>;
  GooglePayProcessRenewalMutation?: GooglePayProcessRenewalMutationResolvers<ContextType>;
  HealthCheck?: HealthCheckResolvers<ContextType>;
  HealthCheckResponse?: HealthCheckResponseResolvers<ContextType>;
  HostedPaymentPagePolicySetupMutation?: HostedPaymentPagePolicySetupMutationResolvers<ContextType>;
  HostedPaymentPageProcessRenewalMutation?: HostedPaymentPageProcessRenewalMutationResolvers<ContextType>;
  InsurerSchemeQuote?: InsurerSchemeQuoteResolvers<ContextType>;
  Licence?: LicenceResolvers<ContextType>;
  LoadQuoteResult?: LoadQuoteResultResolvers<ContextType>;
  LoanDepositOption?: LoanDepositOptionResolvers<ContextType>;
  MidTermAdjustmentMetadata?: MidTermAdjustmentMetadataResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NamedDriverExperienceDetails?: NamedDriverExperienceDetailsResolvers<ContextType>;
  NoClaimsDiscount?: NoClaimsDiscountResolvers<ContextType>;
  NoClaimsDiscountProtectionDataResult?: NoClaimsDiscountProtectionDataResultResolvers<ContextType>;
  PaymentDetails?: PaymentDetailsResolvers<ContextType>;
  Policy?: PolicyResolvers<ContextType>;
  PolicyClaim?: PolicyClaimResolvers<ContextType>;
  PolicyConviction?: PolicyConvictionResolvers<ContextType>;
  PolicyDocument?: PolicyDocumentResolvers<ContextType>;
  PolicyDocumentation?: PolicyDocumentationResolvers<ContextType>;
  PolicyExtraCost?: PolicyExtraCostResolvers<ContextType>;
  PolicyExtraMetadata?: PolicyExtraMetadataResolvers<ContextType>;
  PolicyHolder?: PolicyHolderResolvers<ContextType>;
  PolicyHolderMetadata?: PolicyHolderMetadataResolvers<ContextType>;
  PolicyHolderMutation?: PolicyHolderMutationResolvers<ContextType>;
  PolicyList?: PolicyListResolvers<ContextType>;
  PolicyMetadata?: PolicyMetadataResolvers<ContextType>;
  PolicySetupMutation?: PolicySetupMutationResolvers<ContextType>;
  PolicySetupQuery?: PolicySetupQueryResolvers<ContextType>;
  PolicyVehicleDetails?: PolicyVehicleDetailsResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  ProspectiveLoan?: ProspectiveLoanResolvers<ContextType>;
  ProspectiveLoanWithPrice?: ProspectiveLoanWithPriceResolvers<ContextType>;
  ProspectivePolicyExtra?: ProspectivePolicyExtraResolvers<ContextType>;
  ProspectivePolicyExtraOption?: ProspectivePolicyExtraOptionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QuoteAdditionalDriverDetails?: QuoteAdditionalDriverDetailsResolvers<ContextType>;
  QuoteConvictionDetails?: QuoteConvictionDetailsResolvers<ContextType>;
  QuoteCoverDetails?: QuoteCoverDetailsResolvers<ContextType>;
  QuoteDetails?: QuoteDetailsResolvers<ContextType>;
  QuoteDocuments?: QuoteDocumentsResolvers<ContextType>;
  QuoteEmploymentDetails?: QuoteEmploymentDetailsResolvers<ContextType>;
  QuoteIncidentDetails?: QuoteIncidentDetailsResolvers<ContextType>;
  QuoteMutation?: QuoteMutationResolvers<ContextType>;
  QuotePersonalDetails?: QuotePersonalDetailsResolvers<ContextType>;
  QuotePremiumPriceBreakdown?: QuotePremiumPriceBreakdownResolvers<ContextType>;
  QuotePremiumPriceBreakdownDetails?: QuotePremiumPriceBreakdownDetailsResolvers<ContextType>;
  QuoteQuery?: QuoteQueryResolvers<ContextType>;
  QuoteValidationError?: QuoteValidationErrorResolvers<ContextType>;
  QuoteVehicleDetails?: QuoteVehicleDetailsResolvers<ContextType>;
  QuotesResult?: QuotesResultResolvers<ContextType>;
  Rate?: RateResolvers<ContextType>;
  RebrokedQuoteResult?: RebrokedQuoteResultResolvers<ContextType>;
  RegisteredVehicle?: RegisteredVehicleResolvers<ContextType>;
  RenewalDocuments?: RenewalDocumentsResolvers<ContextType>;
  RenewalNotice?: RenewalNoticeResolvers<ContextType>;
  RenewalsMutation?: RenewalsMutationResolvers<ContextType>;
  RenewalsQuery?: RenewalsQueryResolvers<ContextType>;
  RequestPrintedDocumentsV2Result?: RequestPrintedDocumentsV2ResultResolvers<ContextType>;
  RetrieveAddressResult?: RetrieveAddressResultResolvers<ContextType>;
  SaveQuoteResult?: SaveQuoteResultResolvers<ContextType>;
  StepbackRule?: StepbackRuleResolvers<ContextType>;
  StepbackRules?: StepbackRulesResolvers<ContextType>;
  SuccessfulQuoteResult?: SuccessfulQuoteResultResolvers<ContextType>;
  ThreeDSecureResponse?: ThreeDSecureResponseResolvers<ContextType>;
  ThreeDSecureResponseChallenge?: ThreeDSecureResponseChallengeResolvers<ContextType>;
  ThreeDSecureResponseMpi?: ThreeDSecureResponseMpiResolvers<ContextType>;
  UpdatePolicyHolderMarketingPreferencesResult?: UpdatePolicyHolderMarketingPreferencesResultResolvers<ContextType>;
  UploadPresignedUrl?: UploadPresignedUrlResolvers<ContextType>;
  UpsertQuoteExtrasResponse?: UpsertQuoteExtrasResponseResolvers<ContextType>;
  ValidateBankDetailsResult?: ValidateBankDetailsResultResolvers<ContextType>;
  VehicleAlarmDetails?: VehicleAlarmDetailsResolvers<ContextType>;
  VehicleOptions?: VehicleOptionsResolvers<ContextType>;
  VehicleQuery?: VehicleQueryResolvers<ContextType>;
  VehicleSpecLookupItem?: VehicleSpecLookupItemResolvers<ContextType>;
  ZendeskAuthenticationTokenResult?: ZendeskAuthenticationTokenResultResolvers<ContextType>;
  ZendeskQuery?: ZendeskQueryResolvers<ContextType>;
  ZeroDepositFinancedProcessRenewalMutation?: ZeroDepositFinancedProcessRenewalMutationResolvers<ContextType>;
  ZeroDepositPolicySetupMutation?: ZeroDepositPolicySetupMutationResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
}>;
