import {
  FullPolicyPaymentResult,
  GooglePayFullPolicySetUpRequest,
  Mutation,
  ProposedPolicyRequest,
  ProposedRenewalRequest
} from "shared/dist/generated/graphql/resolvers-types";
import {parseGooglePayPaymentToken} from "../../../shared/google-pay/parsers/ParseGooglePayPaymentToken";
import {useState} from "react";
import {QUOTE_PAYMENT_SUCCESS} from "../../../../../router/models/Routes";
import {logger} from "../../../../../utils/logging/Logger";
import {useNavigate} from "react-router-dom";
import {GooglePayPurchase} from "../../../shared/google-pay/model/GooglePayPurchase";
import {apolloClient} from "../../../../../graphql/apollo/ApolloClientProvider";
import {
  GOOGLE_PAY_PROCESS_FULL_RENEWAL,
  GooglePayFullRenewalPaymentVariables
} from "shared/dist/graphql/mutations/payment/GooglePayProcessFullRenewalMutation";
import {
  GOOGLE_PAY_SET_UP_FULL_POLICY,
  GooglePayFullPolicyPaymentVariables
} from "shared/dist/graphql/mutations/payment/GooglePaySetUpFullPolicyMutation";
import {PolicySetupQuoteValues} from "shared/dist/event-models/policy-setup/PolicySetupEvent";
import {usePolicyQueryParams} from "../../../../../router/params/Params";

export function useGooglePayPurchaseFullPolicy(proposedPolicyRequest: ProposedPolicyRequest, onSuccessfulPayment?: () => void): GooglePayPurchase {
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const purchasePolicy = async (paymentData: google.payments.api.PaymentData): Promise<void> => {
    setLoading(true);

    try {
      const paymentResult = await purchasePolicyMutation({
        payment: {
          token: parseGooglePayPaymentToken(paymentData)
        },
        magicLinkId: proposedPolicyRequest.magicLinkId,
        proposerEmail: proposedPolicyRequest.proposer.email,
        proposerPhoneNumber: proposedPolicyRequest.proposer.phoneNumber,
        quoteReference: proposedPolicyRequest.quoteReference,
        quoteSequenceNumber: proposedPolicyRequest.quoteSequenceNumber,
      });

      if (paymentResult === FullPolicyPaymentResult.Success) {
        navigate(QUOTE_PAYMENT_SUCCESS);
        onSuccessfulPayment?.();
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      logger.error("Payment failed", error);
      setPaymentFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const resetPaymentFailed = (): void => setPaymentFailed(false);

  return {
    makePayment: purchasePolicy,
    loading,
    paymentFailed,
    resetPaymentFailed
  };
}

export function useGooglePayPurchaseRenewal(proposedRenewalRequest: ProposedRenewalRequest, onSuccessfulPayment?: () => void): GooglePayPurchase {
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const purchaseRenewal = async (paymentData: google.payments.api.PaymentData): Promise<void> => {
    setLoading(true);

    try {
      const paymentResult = await purchaseRenewalMutation(paymentData, proposedRenewalRequest);

      if (paymentResult === FullPolicyPaymentResult.Success) {
        navigate(`/portal/policy/${proposedRenewalRequest.policyId}/renewals/payment/success`);
        onSuccessfulPayment?.();
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      logger.error("Payment failed", error);
      setPaymentFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const resetPaymentFailed = (): void => setPaymentFailed(false);

  return {
    makePayment: purchaseRenewal,
    loading,
    paymentFailed,
    resetPaymentFailed
  };
}

export function useGooglePayPurchaseRebrokePolicy(proposedPolicyRequest: PolicySetupQuoteValues | undefined, onSuccessfulPayment?: () => void): GooglePayPurchase {
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {policyId} = usePolicyQueryParams();

  const purchasePolicy = async (paymentData: google.payments.api.PaymentData): Promise<void> => {
    if (!proposedPolicyRequest) throw new Error("No rebroke quote has been selected");
    setLoading(true);

    try {
      const paymentResult = await purchasePolicyMutation({
        payment: {
          token: parseGooglePayPaymentToken(paymentData)
        },
        proposerEmail: proposedPolicyRequest.proposerEmail,
        proposerPhoneNumber: proposedPolicyRequest.proposerPhoneNumber,
        quoteReference: proposedPolicyRequest.quoteReference,
        quoteSequenceNumber: proposedPolicyRequest.quoteSequenceNumber,
      });

      if (paymentResult === FullPolicyPaymentResult.Success) {
        navigate(`/portal/policy/${policyId}/renewals/payment/success`);
        onSuccessfulPayment?.();
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      logger.error("Payment failed", error);
      setPaymentFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const resetPaymentFailed = (): void => setPaymentFailed(false);

  return {
    makePayment: purchasePolicy,
    loading,
    paymentFailed,
    resetPaymentFailed
  };
}

async function purchasePolicyMutation(request: GooglePayFullPolicySetUpRequest): Promise<FullPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "policySetup">, GooglePayFullPolicyPaymentVariables>({
    mutation: GOOGLE_PAY_SET_UP_FULL_POLICY,
    variables: {
      request
    }
  });
  return response.data?.policySetup.googlePay.setUpFullPolicy.result ?? FullPolicyPaymentResult.SomethingWentWrong;
}

async function purchaseRenewalMutation(paymentData: google.payments.api.PaymentData, proposedRenewalRequest: ProposedRenewalRequest): Promise<FullPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "renewals">, GooglePayFullRenewalPaymentVariables>({
    mutation: GOOGLE_PAY_PROCESS_FULL_RENEWAL,
    variables: {
      request: {
        payment: {
          token: parseGooglePayPaymentToken(paymentData)
        },
        proposedRenewalRequest: proposedRenewalRequest
      }
    }
  });
  return response.data?.renewals.googlePay.processFullRenewal.result ?? FullPolicyPaymentResult.SomethingWentWrong;
}
