export default {
  "login.loginButton": "crwdns10249:0crwdne10249:0",
  "login.passwordInput.placeholder": "crwdns10251:0crwdne10251:0",
  "login.forgotPasswordLink": "crwdns10253:0crwdne10253:0",
  "login.errors.incorrectCredentialsEntered": "crwdns10255:0crwdne10255:0",
  "login.errors.tooManyFailedAttempts": "crwdns10257:0crwdne10257:0",
  "login.errors.sessionTimeout": "crwdns10259:0crwdne10259:0",
  "login.errors.genericLoginFailure": "crwdns10261:0crwdne10261:0",
  "login.forgotPassword.modal.header": "crwdns10263:0crwdne10263:0",
  "login.forgotPassword.modal.description": "crwdns10265:0crwdne10265:0",
  "login.forgotPassword.modal.emailInput.label": "crwdns10267:0crwdne10267:0",
  "login.forgotPassword.modal.buttons.cancel": "crwdns10269:0crwdne10269:0",
  "login.forgotPassword.modal.buttons.sendLink": "crwdns10271:0crwdne10271:0",
  "login.forgotPassword.helpIsOnTheWayMessage.title": "crwdns10273:0crwdne10273:0",
  "login.forgotPassword.helpIsOnTheWayMessage.description": "crwdns10275:0crwdne10275:0",
  "login.forgotPassword.submitButton": "crwdns10277:0crwdne10277:0",
  "login.forgotPassword.genericFailure": "crwdns10279:0crwdne10279:0",
  "login.forgotPassword.resetPasswordCodeHasExpired": "crwdns10281:0crwdne10281:0",
  "login.newHereButton": "crwdns10283:0crwdne10283:0",
  "notFound.body.errorMessage.slowDown": "crwdns10285:0crwdne10285:0",
  "notFound.body.errorMessage.takenAWrongTurn": "crwdns10287:0crwdne10287:0",
  "notFound.body.button.startANewQuote": "crwdns10289:0crwdne10289:0",
  "navbar.menu.dashboard": "crwdns10291:0crwdne10291:0",
  "navbar.menu.policies": "crwdns10293:0crwdne10293:0",
  "navbar.menu.policies.summary": "crwdns10295:0crwdne10295:0",
  "navbar.menu.policies.documents": "crwdns10297:0crwdne10297:0",
  "navbar.menu.policies.renewals": "crwdns10299:0crwdne10299:0",
  "navbar.menu.policies.additionalDrivers": "crwdns10301:0crwdne10301:0",
  "navbar.menu.policies.payments": "crwdns10303:0crwdne10303:0",
  "navbar.menu.policies.claims": "crwdns10305:0crwdne10305:0",
  "navbar.menu.quotes": "crwdns10307:0crwdne10307:0",
  "navbar.menu.claims": "crwdns10309:0crwdne10309:0",
  "navbar.menu.helpAndSupport": "crwdns10311:0crwdne10311:0",
  "navbar.menu.settings": "crwdns10313:0crwdne10313:0",
  "navbar.menu.uploads": "crwdns10315:0crwdne10315:0",
  "navbar.logout": "crwdns13865:0crwdne13865:0",
  "navbar.logout.modal.header": "crwdns10319:0crwdne10319:0",
  "navbar.logout.modal.description": "crwdns10321:0crwdne10321:0",
  "navbar.logout.modal.buttons.cancel": "crwdns10323:0crwdne10323:0",
  "navbar.logout.modal.buttons.confirm": "crwdns10325:0crwdne10325:0",
  "daysOfTheWeek.monday": "crwdns10327:0crwdne10327:0",
  "daysOfTheWeek.friday": "crwdns10329:0crwdne10329:0",
  "daysOfTheWeek.saturday": "crwdns10331:0crwdne10331:0",
  "portal.needToMakeAChange.header": "crwdns10333:0crwdne10333:0",
  "portal.needToMakeAChange.description": "crwdns13867:0crwdne13867:0",
  "portal.needToMakeAChange.openingHours": "crwdns13827:0crwdne13827:0",
  "portal.reminder.unverifiedEmail.title": "crwdns10337:0crwdne10337:0",
  "portal.reminder.unverifiedEmail.description": "crwdns10339:0crwdne10339:0",
  "portal.reminder.unverifiedPhoneNumber.title": "crwdns10341:0crwdne10341:0",
  "portal.reminder.unverifiedPhoneNumber.description": "crwdns10343:0crwdne10343:0",
  "portal.reminder.uploadDocuments.title": "crwdns10345:0crwdne10345:0",
  "portal.reminder.uploadDocuments.description": "crwdns10347:0crwdne10347:0",
  "portal.reminder.reviewInProgress.title": "crwdns10349:0crwdne10349:0",
  "portal.reminder.reviewInProgress.description": "crwdns10351:0crwdne10351:0",
  "portal.reminder.withCustomer.title": "crwdns10353:0crwdne10353:0",
  "portal.reminder.withCustomer.description": "crwdns10355:0crwdne10355:0",
  "portal.reminder.verified.title": "crwdns10357:0crwdne10357:0",
  "portal.reminder.verified.description": "crwdns10359:0crwdne10359:0",
  "portal.reminder.pendingCancellation.title": "crwdns10361:0crwdne10361:0",
  "portal.reminder.pendingCancellation.description": "crwdns10363:0crwdne10363:0",
  "portal.reminder.renewalsAwaitingInvite.title": "crwdns10365:0crwdne10365:0",
  "portal.reminder.renewalsAwaitingInvite.description": "crwdns10367:0crwdne10367:0",
  "portal.reminder.renewalOffered.title": "crwdns10369:0crwdne10369:0",
  "portal.reminder.renewalOffered.description": "crwdns10371:0crwdne10371:0",
  "portal.reminder.renewalOffered.link.description": "crwdns10373:0crwdne10373:0",
  "renewal.startRenewal": "crwdns14021:0crwdne14021:0",
  "renewal.holdingPage.checkBack": "crwdns10377:0crwdne10377:0",
  "renewal.holdingPage.renewalDue": "crwdns10379:0{renewalDate}crwdne10379:0",
  "renewal.holdingPage.renewalDueToday": "crwdns13669:0crwdne13669:0",
  "renewal.holdingPage.weWillContactYou": "crwdns10381:0crwdne10381:0",
  "renewal.readyPage.yourRenewalQuoteReady": "crwdns14023:0crwdne14023:0",
  "renewal.readyPage.insurer": "crwdns10385:0{insurer}crwdne10385:0",
  "renewal.readyPage.clickBelowStarted": "crwdns10387:0crwdne10387:0",
  "renewal.expiredPage.yourRenewalOfferHasExpired": "crwdns10389:0crwdne10389:0",
  "renewal.expiredPage.onlineAccessExpired": "crwdns14025:0{policyEndDate}crwdnd14025:0{br}crwdnd14025:0{br}crwdne14025:0",
  "renewal.expiredPage.getInTouch": "crwdns10393:0crwdne10393:0",
  "renewal.inviteOverduePage.description": "crwdns13671:0crwdne13671:0",
  "renewal.inviteOverduePage.getInTouch": "crwdns13673:0crwdne13673:0",
  "renewal.completePage.title": "crwdns10395:0crwdne10395:0",
  "renewal.completePage.description": "crwdns10397:0crwdne10397:0",
  "renewal.completePage.getInTouch": "crwdns10399:0crwdne10399:0",
  "renewal.revokedPage.revoked": "crwdns14005:0crwdne14005:0",
  "renewal.revokedPage.wereReviewingYourPolicy": "crwdns10403:0crwdne10403:0",
  "renewal.revokedPage.nextSteps": "crwdns10405:0crwdne10405:0",
  "renewal.revokedPage.nextStepDetails": "crwdns14007:0crwdne14007:0" + "crwdns14009:0crwdne14009:0" + "crwdns10413:0crwdne10413:0",
  "helpCard.needHelp": "crwdns10415:0crwdne10415:0",
  "helpCard.needAHand": "crwdns10417:0crwdne10417:0",
  "helpCard.chatToUs": "crwdns13869:0crwdne13869:0",
  "document.upload.header.title": "crwdns10421:0crwdne10421:0",
  "document.upload.policy.field.name": "crwdns10423:0crwdne10423:0",
  "document.upload.vehicle.field.name": "crwdns10425:0crwdne10425:0",
  "document.upload.registration.number.field.name": "crwdns10427:0crwdne10427:0",
  "document.upload.policy.term.number.field.name": "crwdns10429:0crwdne10429:0",
  "document.upload.form.title": "crwdns10431:0crwdne10431:0",
  "document.upload.documentTypeError": "crwdns10433:0crwdne10433:0",
  "document.upload.noDocumentsToUploadError": "crwdns10435:0crwdne10435:0",
  "document.upload.document.type.licence.plastic.front": "crwdns10437:0crwdne10437:0",
  "document.upload.document.type.licence.plastic.back": "crwdns10439:0crwdne10439:0",
  "document.upload.document.type.licence.paper.front": "crwdns10441:0crwdne10441:0",
  "document.upload.document.type.licence.paper.back": "crwdns10443:0crwdne10443:0",
  "document.upload.document.type.ncb": "crwdns10445:0crwdne10445:0",
  "document.upload.document.type.namedDriverExperience": "crwdns10447:0crwdne10447:0",
  "document.upload.document.type.companyCarBonus": "crwdns10449:0crwdne10449:0",
  "document.upload.document.type.other": "crwdns10451:0crwdne10451:0",
  "document.upload.form.proofOfIdentity": "crwdns10453:0crwdne10453:0",
  "document.upload.form.proofOfIdentity.description": "crwdns10455:0crwdne10455:0" + "crwdns10457:0crwdne10457:0" + "crwdns10459:0crwdne10459:0" + "crwdns10461:0crwdne10461:0" + "crwdns10463:0crwdne10463:0",
  "document.upload.form.ncb": "crwdns10465:0crwdne10465:0",
  "document.upload.form.ncb.description": "crwdns10467:0crwdne10467:0" + "crwdns10469:0crwdne10469:0" + "crwdns10471:0crwdne10471:0" + "crwdns10473:0crwdne10473:0",
  "document.upload.form.namedDriverExperience": "crwdns10475:0crwdne10475:0",
  "document.upload.form.namedDriverExperience.description": "crwdns10477:0crwdne10477:0" + "crwdns10479:0crwdne10479:0" + "crwdns10481:0crwdne10481:0" + "crwdns10483:0crwdne10483:0",
  "document.upload.form.companyCarBonus": "crwdns10485:0crwdne10485:0",
  "document.upload.form.companyCarBonus.description": "crwdns10487:0crwdne10487:0" + "crwdns10489:0crwdne10489:0" + "crwdns10491:0crwdne10491:0" + "crwdns10493:0crwdne10493:0",
  "document.upload.form.header": "crwdns10495:0crwdne10495:0",
  "document.upload.form.mobile.header": "crwdns10497:0crwdne10497:0",
  "document.upload.form.description": "crwdns10499:0crwdne10499:0",
  "document.upload.form.button.text": "crwdns10501:0crwdne10501:0",
  "document.upload.form.remove.button.text": "crwdns10503:0crwdne10503:0",
  "document.upload.form.submit.button.text": "crwdns10505:0crwdne10505:0",
  "document.upload.form.submit.button.header": "crwdns10507:0crwdne10507:0",
  "document.upload.form.successful.upload.message": "crwdns10509:0{numberOfSuccessfulFiles}crwdne10509:0",
  "document.upload.form.successful.uploads.message": "crwdns10511:0{numberOfSuccessfulFiles}crwdne10511:0",
  "document.upload.form.failed.upload.message": "crwdns10513:0{numberOfFailedFiles}crwdne10513:0",
  "document.upload.form.failed.uploads.message": "crwdns10515:0{numberOfFailedFiles}crwdne10515:0",
  "document.upload.form.retry": "crwdns10517:0crwdne10517:0",
  "compareYourQuote.title": "crwdns10519:0crwdne10519:0",
  "compareYourQuote.description": "crwdns10521:0crwdne10521:0",
  "compareYourQuote.annualCost": "crwdns10523:0crwdne10523:0",
  "compareYourQuote.monthlyCost": "crwdns10525:0crwdne10525:0",
  "compareYourQuote.underageMonthlyPaymentStop.title": "crwdns10527:0crwdne10527:0",
  "compareYourQuote.underageMonthlyPaymentStop.description": "crwdns10529:0crwdne10529:0",
  "compareYourQuote.underageMonthlyPaymentStop.close": "crwdns10531:0crwdne10531:0",
  "compareYourQuote.underageMonthlyPaymentStop.contact": "crwdns10533:0crwdne10533:0",
  "insurerQuoteCard.subheader": "crwdns10535:0crwdne10535:0",
  "insurerQuoteCard.costPerMonth": "crwdns10537:0crwdne10537:0",
  "insurerQuoteCard.numberOfInstalments": "crwdns10539:0{numberOfInstalments}crwdne10539:0",
  "insurerQuoteCard.button.text": "crwdns10541:0crwdne10541:0",
  "insurerQuoteCard.noExperience.header": "crwdns10543:0crwdne10543:0",
  "insurerQuoteCard.namedDriver.header": "crwdns10545:0crwdne10545:0",
  "insurerQuoteCard.noClaimsBonus.header": "crwdns10547:0crwdne10547:0",
  "insurerQuoteCard.companyCar.header": "crwdns10549:0crwdne10549:0",
  "insurerQuoteCard.tooltip.calculated.header": "crwdns10551:0crwdne10551:0",
  "insurerQuoteCard.noExperience.tooltip.calculated.description": "crwdns10553:0crwdne10553:0",
  "insurerQuoteCard.noExperience.tooltip.changes.header": "crwdns10555:0crwdne10555:0",
  "insurerQuoteCard.noExperience.tooltip.changes.description": "crwdns10557:0crwdne10557:0",
  "insurerQuoteCard.namedDriver.tooltip.calculated.description": "crwdns10559:0crwdne10559:0",
  "insurerQuoteCard.namedDriver.tooltip.changes.header": "crwdns10561:0crwdne10561:0",
  "insurerQuoteCard.namedDriver.tooltip.changes.description": "crwdns10563:0crwdne10563:0",
  "insurerQuoteCard.noClaimsBonus.tooltip.calculated.description": "crwdns10565:0crwdne10565:0",
  "insurerQuoteCard.noClaimsBonus.tooltip.changes.header": "crwdns10567:0crwdne10567:0",
  "insurerQuoteCard.noClaimsBonus.tooltip.changes.description": "crwdns10569:0crwdne10569:0" + "\n" + "crwdns10573:0crwdne10573:0",
  "insurerQuoteCard.companyCar.tooltip.calculated.description": "crwdns10575:0crwdne10575:0",
  "insurerQuoteCard.companyCar.tooltip.changes.header": "crwdns10577:0crwdne10577:0",
  "insurerQuoteCard.companyCar.tooltip.changes.description": "crwdns10579:0crwdne10579:0",
  "insurerQuoteCard.namedDriver.footer.description": "crwdns10581:0crwdne10581:0",
  "insurerQuoteCard.companyCar.footer.description": "crwdns10583:0crwdne10583:0",
  "insurerQuoteCard.noClaimsBonus.footer.description": "crwdns10585:0crwdne10585:0",
  "insurerQuoteCard.noExperience.footer.description": "crwdns10587:0crwdne10587:0",
  "insurerQuoteCard.body.insurancePremium": "crwdns10589:0crwdne10589:0",
  "insurerQuoteCard.body.APR": "crwdns10591:0crwdne10591:0",
  "insurerQuoteCard.body.deposit": "crwdns10593:0crwdne10593:0",
  "insurerQuoteCard.body.adminFee": "crwdns10595:0crwdne10595:0",
  "insurerQuoteCard.body.Extras": "crwdns10597:0crwdne10597:0",
  "insurerQuoteCard.body.quoteWith": "crwdns10599:0crwdne10599:0",
  "policies.extra.smartfobKeycare.description": "crwdns13871:0crwdne13871:0",
  "policies.extra.legalExpenses.description": "crwdns13873:0crwdne13873:0",
  "policies.extra.replacementCar.description": "crwdns13875:0crwdne13875:0",
  "policies.extra.roadsideAssistancePlatinum.description": "crwdns13877:0crwdne13877:0",
  "policies.extra.excessInsurance.description": "crwdns13879:0crwdne13879:0",
  "policies.extra.excessInsurance.question": "crwdns10611:0crwdne10611:0",
  "policies.extra.excessInsurance.error": "crwdns10613:0crwdne10613:0",
  "policies.extra.excess.insurance.option.type1.description": "crwdns10615:0crwdne10615:0",
  "policies.extra.excess.insurance.option.type2.description": "crwdns10617:0crwdne10617:0",
  "policies.extra.excess.insurance.option.type3.description": "crwdns10619:0crwdne10619:0",
  "policies.extra.excess.insurance.option.type4.description": "crwdns10621:0crwdne10621:0",
  "policies.extra.excess.insurance.option.type5.description": "crwdns10623:0crwdne10623:0",
  "policies.mainContent.insuranceProductInformation.insuranceProductInformationLink": "crwdns10625:0crwdne10625:0",
  "policies.mainContent.policyBooklet.policyBookletLink": "crwdns10627:0crwdne10627:0",
  "policies.mainContent.termsOfBusinessAgreement.termsOfBusinessAgreementLink": "crwdns10629:0crwdne10629:0",
  "policies.header.title": "crwdns14027:0crwdne14027:0",
  "policies.header.subHeader.coverSummary": "crwdns10633:0crwdne10633:0",
  "policies.header.subHeader.documents": "crwdns10635:0crwdne10635:0",
  "policies.header.subHeader.renewals": "crwdns14029:0crwdne14029:0",
  "policies.header.subHeader.additionalDrivers": "crwdns10639:0crwdne10639:0",
  "policies.header.subHeader.payments": "crwdns10641:0crwdne10641:0",
  "policies.header.subHeader.claims": "crwdns10643:0crwdne10643:0",
  "policies.header.subHeader.policySummaryCardRow.policyNumber": "crwdns13895:0crwdne13895:0",
  "policies.header.subHeader.policySummaryCardRow.checkBackSoon": "crwdns13897:0crwdne13897:0",
  "policies.header.subHeader.policySummaryCardRow.activeOn": "crwdns13899:0crwdne13899:0",
  "policies.header.subHeader.policySummaryCardRow.renewalDue": "crwdns13901:0crwdne13901:0",
  "policies.header.subHeader.policySummaryCardRow.expiredOn": "crwdns13903:0crwdne13903:0",
  "policies.header.subHeader.policySummaryCardRow.startedOn": "crwdns13905:0crwdne13905:0",
  "policies.mainContent.cover.title": "crwdns10667:0crwdne10667:0",
  "policies.summary.totalPolicyExcess": "crwdns10669:0crwdne10669:0",
  "policies.summary.checkYourDocuments": "crwdns14178:0crwdne14178:0",
  "policies.summary.coverType": "crwdns10671:0crwdne10671:0",
  "policies.summary.noClaimsProtection": "crwdns10673:0crwdne10673:0",
  "policies.summary.noClaimsProtection.protected": "crwdns10675:0crwdne10675:0",
  "policies.summary.noClaimsProtection.unprotected": "crwdns10677:0crwdne10677:0",
  "policies.mainContent.yourCar": "crwdns10679:0crwdne10679:0",
  "policies.mainContent.yourCar.make": "crwdns10681:0crwdne10681:0",
  "policies.mainContent.yourCar.model": "crwdns10683:0crwdne10683:0",
  "policies.mainContent.yourCar.fuel": "crwdns10685:0crwdne10685:0",
  "policies.mainContent.yourCar.transmission": "crwdns10687:0crwdne10687:0",
  "policies.mainContent.yourCar.engineSize": "crwdns10689:0crwdne10689:0",
  "policies.mainContent.yourCar.yearOfRegistration": "crwdns10691:0crwdne10691:0",
  "policies.mainContent.yourCar.trimLevel": "crwdns13907:0crwdne13907:0",
  "policies.mainContent.yourDetails": "crwdns10695:0crwdne10695:0",
  "policies.mainContent.yourDetails.name": "crwdns10697:0crwdne10697:0",
  "policies.mainContent.yourDetails.dateOfBirth": "crwdns10699:0crwdne10699:0",
  "policies.mainContent.yourDetails.address": "crwdns10701:0crwdne10701:0",
  "policies.mainContent.yourDetails.maritalStatus": "crwdns10703:0crwdne10703:0",
  "policies.mainContent.yourDetails.jobTitle": "crwdns10705:0crwdne10705:0",
  "policies.mainContent.yourDetails.secondaryJobTitle": "crwdns10707:0crwdne10707:0",
  "policies.mainContent.yourAdditionalDrivers": "crwdns10709:0crwdne10709:0",
  "policies.mainContent.yourAdditionalDrivers.mainDriver": "crwdns10711:0crwdne10711:0",
  "policies.mainContent.policyExtras.title.placeholder": "crwdns10713:0crwdne10713:0",
  "policies.mainContent.policyExtras.title": "crwdns10715:0crwdne10715:0",
  "policies.mainContent.policyExtras.policyExtras.startDate": "crwdns10717:0{startDate}crwdne10717:0",
  "policies.mainContent.policyExtras.viewDocuments": "crwdns10719:0crwdne10719:0",
  "policies.mainContent.policyExtras.smartfobKeycare.title": "crwdns10721:0crwdne10721:0",
  "policies.mainContent.policyExtras.legalExpenses.title": "crwdns10723:0crwdne10723:0",
  "policies.mainContent.policyExtras.replacementCar.title": "crwdns10725:0crwdne10725:0",
  "policies.mainContent.policyExtras.roadsideAssistancePlatinum.title": "crwdns10727:0crwdne10727:0",
  "policies.mainContent.policyExtras.excessInsurance.title": "crwdns10729:0crwdne10729:0",
  "portal.error.unableToLoadPolicy": "crwdns10731:0crwdne10731:0",
  "portal.error.unableToLoadRenewal": "crwdns10733:0crwdne10733:0",
  "portal.error.page.title": "crwdns10735:0crwdne10735:0",
  "portal.error.page.description": "crwdns10737:0crwdne10737:0",
  "portal.spinner.message": "crwdns10739:0crwdne10739:0",
  "policies.documents.activeDocuments": "crwdns10741:0crwdne10741:0",
  "policies.documents.requestPrintedDocuments.ready": "crwdns10743:0crwdne10743:0",
  "policies.documents.processingMessage": "crwdns10745:0crwdne10745:0" + "crwdns10747:0crwdne10747:0",
  "policies.documents.type.certificateAndScheduleOfInsurance.title": "crwdns10749:0crwdne10749:0",
  "policies.documents.type.statementOfFact.title": "crwdns10751:0crwdne10751:0",
  "policies.documents.type.noClaimsBonusProtection.title": "crwdns10753:0crwdne10753:0",
  "policies.documents.type.insuranceProductInformation.title": "crwdns10755:0crwdne10755:0",
  "policies.documents.type.termsOfBusinessAgreement.title": "crwdns10757:0crwdne10757:0",
  "policies.documents.type.policyWordingBooklet.title": "crwdns10759:0crwdne10759:0",
  "policies.documents.type.smartfobKeycare.title": "crwdns10761:0crwdne10761:0",
  "policies.documents.type.roadsideAssistancePlatinum.title": "crwdns10763:0crwdne10763:0",
  "policies.documents.type.legalExpenses.title": "crwdns10765:0crwdne10765:0",
  "policies.documents.type.excessInsurance.title": "crwdns10767:0crwdne10767:0",
  "policies.documents.type.excessInsuranceSchedule.title": "crwdns10769:0crwdne10769:0",
  "policies.documents.type.courtesyCar.title": "crwdns10771:0crwdne10771:0",
  "policies.documents.type.policySummary.title": "crwdns10773:0crwdne10773:0",
  "policies.documents.type.policyExtras.title": "crwdns10775:0crwdne10775:0",
  "policies.documents.type.certificateAndScheduleOfInsurance.description": "crwdns10777:0crwdne10777:0",
  "policies.documents.type.statementOfFact.description": "crwdns10779:0crwdne10779:0",
  "policies.documents.type.noClaimsBonusProtection.description": "crwdns10781:0crwdne10781:0",
  "policies.documents.type.insuranceProductInformation.description": "crwdns10783:0crwdne10783:0",
  "policies.documents.type.termsOfBusinessAgreement.description": "crwdns10785:0crwdne10785:0",
  "policies.documents.type.policyWordingBooklet.description": "crwdns10787:0crwdne10787:0",
  "policies.documents.type.default.description.placeholder": "crwdns10789:0crwdne10789:0",
  "policies.archivedDocuments.title": "crwdns10791:0crwdne10791:0",
  "policies.expiredDocuments.title": "crwdns10793:0crwdne10793:0",
  "policies.cancelledDocuments.title": "crwdns10795:0crwdne10795:0",
  "policies.upcomingDocuments.title": "crwdns10797:0crwdne10797:0",
  "policies.upcomingDocuments.sidebar.goBack": "crwdns10799:0crwdne10799:0",
  "policies.upcomingDocuments.card.title": "crwdns10801:0crwdne10801:0",
  "policies.upcomingDocuments.card.description": "crwdns10803:0crwdne10803:0",
  "policies.upcomingDocuments.card.button": "crwdns10805:0crwdne10805:0",
  "policies.documents.requestPrintedDocuments.modal.title": "crwdns10807:0crwdne10807:0",
  "policies.documents.requestPrintedDocuments.modal.address.title": "crwdns10809:0crwdne10809:0",
  "policies.documents.requestPrintedDocuments.modal.oldAddress.title": "crwdns10811:0crwdne10811:0",
  "policies.documents.requestPrintedDocuments.modal.oldAddress.description": "crwdns10813:0crwdne10813:0",
  "policies.documents.requestPrintedDocuments.modal.policyBooklet.header": "crwdns10815:0crwdne10815:0",
  "policies.documents.requestPrintedDocuments.modal.policyBooklet.description": "crwdns10817:0crwdne10817:0",
  "policies.documents.requestPrintedDocuments.modal.policyBooklet.link": "crwdns10819:0crwdne10819:0",
  "policies.documents.requestPrintedDocuments.modal.dismiss": "crwdns10821:0crwdne10821:0",
  "policies.documents.requestPrintedDocuments.modal.orderPrints": "crwdns10823:0crwdne10823:0",
  "policies.documents.requestPrintedDocuments.modal.printRequestSent.title": "crwdns10825:0crwdne10825:0",
  "policies.documents.requestPrintedDocuments.modal.printRequestSent.description": "crwdns10827:0crwdne10827:0",
  "policies.documents.requestPrintedDocuments.modal.printWeeklyLimitHit.title": "crwdns10829:0crwdne10829:0",
  "policies.documents.requestPrintedDocuments.modal.printWeeklyLimitHit.description": "crwdns10831:0{date}crwdnd10831:0{minDaysBetweenRequests}crwdne10831:0",
  "policies.documents.requestPrintedDocuments.modal.printBatchLimitHit.title": "crwdns10833:0crwdne10833:0",
  "policies.documents.requestPrintedDocuments.modal.printBatchLimitHit.description": "crwdns10835:0crwdne10835:0" + "crwdns10837:0crwdne10837:0",
  "policies.documents.requestPrintedDocuments.modal.error": "crwdns10839:0crwdne10839:0",
  "policies.additionalDrivers.dateOfBirth": "crwdns10841:0crwdne10841:0",
  "policies.additionalDrivers.address": "crwdns10843:0crwdne10843:0",
  "policies.additionalDrivers.carStorage": "crwdns10845:0crwdne10845:0",
  "policies.additionalDrivers.occupation": "crwdns10847:0crwdne10847:0",
  "policies.additionalDrivers.classOfUse": "crwdns10849:0crwdne10849:0",
  "policies.additionalDrivers.licenceInformation": "crwdns10851:0crwdne10851:0",
  "policies.additionalDrivers.convictions": "crwdns13909:0crwdne13909:0",
  "policies.additionalDrivers.medicalInformation": "crwdns10855:0crwdne10855:0",
  "policies.additionalDrivers.claims": "crwdns10857:0crwdne10857:0",
  "policies.additionalDrivers.noAdditionalDrivers.title": "crwdns10859:0crwdne10859:0",
  "policies.additionalDrivers.noAdditionalDrivers.message": "crwdns10861:0crwdne10861:0",
  "passwordConfirmation.passwordInput.placeholder": "crwdns10863:0crwdne10863:0",
  "passwordConfirmation.repeatPasswordInput.placeholder": "crwdns10865:0crwdne10865:0",
  "passwordConfirmation.matchingPasswords.error.message": "crwdns10867:0crwdne10867:0",
  "passwordConfirmation.invalidPasswordPolicy.error.message": "crwdns10869:0crwdne10869:0",
  "passwordConfirmation.error.message.unauthorized": "crwdns10871:0crwdne10871:0",
  "passwordConfirmation.error.message.tooManyFailedAttempts": "crwdns10873:0crwdne10873:0",
  "passwordConfirmation.error.message.genericFailure": "crwdns10875:0crwdne10875:0",
  "accountActivation.savePasswordButton": "crwdns10877:0crwdne10877:0",
  "accountActivation.informationPanel.title": "crwdns10879:0crwdne10879:0",
  "accountActivation.informationPanel.subTitle": "crwdns10881:0crwdne10881:0",
  "accountActivation.informationPanel.description": "crwdns10883:0crwdne10883:0",
  "accountActivation.description": "crwdns10885:0crwdne10885:0",
  "accountActivation.sessionExpired.error.message": "crwdns10887:0crwdne10887:0",
  "accountSettings.title": "crwdns10889:0crwdne10889:0",
  "accountSettings.header.phoneNumber": "crwdns10891:0crwdne10891:0",
  "accountSettings.header.emailAddress": "crwdns10893:0crwdne10893:0",
  "accountSettings.header.password": "crwdns10895:0crwdne10895:0",
  "accountSettings.form.edit": "crwdns10897:0crwdne10897:0",
  "accountSettings.form.cancel": "crwdns10899:0crwdne10899:0",
  "accountSettings.form.save": "crwdns10901:0crwdne10901:0",
  "accountSettings.form.sendCode": "crwdns10903:0crwdne10903:0",
  "accountSettings.form.verify": "crwdns10905:0crwdne10905:0",
  "accountSettings.form.resendCode": "crwdns10907:0crwdne10907:0",
  "accountSettings.form.codeSentSuccess": "crwdns10909:0{destination}crwdne10909:0",
  "accountSettings.form.invalidPhoneNumber": "crwdns10911:0crwdne10911:0",
  "accountSettings.form.invalidEmail": "crwdns10913:0crwdne10913:0",
  "accountSettings.form.invalidConfirmationCode": "crwdns10915:0crwdne10915:0",
  "accountSettings.form.genericUpdateFailure": "crwdns10917:0crwdne10917:0",
  "accountSettings.form.emailAlreadyExists": "crwdns10919:0crwdne10919:0",
  "accountSettings.form.tooManyAttempts": "crwdns10921:0crwdne10921:0",
  "accountSettings.form.confirmationCodeExpired": "crwdns10923:0crwdne10923:0",
  "accountSettings.form.confirmationCode.placeholder": "crwdns10925:0crwdne10925:0",
  "accountSettings.form.phoneNumberUpdated": "crwdns10927:0crwdne10927:0",
  "accountSettings.form.emailUpdated": "crwdns10929:0crwdne10929:0",
  "accountSettings.form.passwordUpdated": "crwdns10931:0crwdne10931:0",
  "accountSettings.form.newPassword": "crwdns10933:0crwdne10933:0",
  "accountSettings.form.currentPassword": "crwdns10935:0crwdne10935:0",
  "accountSettings.form.confirmPassword": "crwdns10937:0crwdne10937:0",
  "accountSettings.form.error.title": "crwdns10939:0crwdne10939:0",
  "accountSettings.form.invalidPassword": "crwdns10941:0crwdne10941:0",
  "accountSettings.form.error.password.format.title": "crwdns10943:0crwdne10943:0",
  "accountSettings.form.error.password.specialCharacter": "crwdns10945:0crwdne10945:0",
  "accountSettings.form.error.password.upperCaseLetter": "crwdns10947:0crwdne10947:0",
  "accountSettings.form.error.password.number": "crwdns10949:0crwdne10949:0",
  "accountSettings.form.error.password.minimumLength": "crwdns10951:0crwdne10951:0",
  "accountSettings.form.error.password.emailAddress": "crwdns10953:0crwdne10953:0",
  "accountSettings.header.marketingPreferences": "crwdns10955:0crwdne10955:0",
  "accountSettings.marketingPreferences.content": "crwdns10957:0crwdne10957:0",
  "accountSettings.marketingPreferences.email.text": "crwdns10959:0crwdne10959:0",
  "accountSettings.marketingPreferences.sms.text": "crwdns10961:0crwdne10961:0",
  "accountSettings.marketingPreferences.updated": "crwdns10963:0crwdne10963:0",
  "accountSettings.marketingPreferences.savePreferences": "crwdns10965:0crwdne10965:0",
  "accountAlreadyActivated.title": "crwdns10967:0crwdne10967:0",
  "accountAlreadyActivated.pleaseLogin": "crwdns10969:0crwdne10969:0",
  "claims.form.policy": "crwdns10971:0crwdne10971:0",
  "claims.form.dateOfIncident": "crwdns10973:0crwdne10973:0",
  "claims.form.callMeOn": "crwdns10975:0crwdne10975:0",
  "claims.form.claimsTeamHelp": "crwdns10977:0crwdne10977:0",
  "claims.form.claimDetails": "crwdns10979:0crwdne10979:0",
  "claims.form.tellUsWhatHappened": "crwdns10981:0crwdne10981:0",
  "claims.form.requestCallback": "crwdns10983:0crwdne10983:0",
  "claims.form.invalidPhoneNumber": "crwdns10985:0crwdne10985:0",
  "claims.form.invalidDate.beforeOriginalPolicyStartDate": "crwdns10987:0crwdne10987:0",
  "claims.form.invalidDate.beforeSelectedPolicyStartDate": "crwdns10989:0crwdne10989:0",
  "claims.form.invalidDate.afterSelectedPolicyEndDate": "crwdns10991:0crwdne10991:0",
  "claims.form.genericFailure": "crwdns10993:0crwdne10993:0",
  "claims.form.requestSubmitted.title": "crwdns10995:0crwdne10995:0",
  "claims.form.requestSubmitted.description": "crwdns10997:0crwdne10997:0",
  "claims.form.processingMessage": "crwdns10999:0crwdne10999:0",
  "quote.sidebar.navigation.title": "crwdns11001:0crwdne11001:0",
  "quote.header.modal.logIn": "crwdns11003:0crwdne11003:0",
  "quote.header.modal.retrieveQuote": "crwdns11005:0crwdne11005:0",
  "quote.header.modal.retrieveQuote.emailAddress": "crwdns11007:0crwdne11007:0",
  "quote.header.modal.retrieveQuote.description": "crwdns11009:0crwdne11009:0",
  "quote.header.modal.retrieveQuote.footer.cancel": "crwdns11011:0crwdne11011:0",
  "quote.header.modal.retrieveQuote.footer.sendLink": "crwdns11013:0crwdne11013:0",
  "quote.header.modal.retrieveQuote.checkYourEmails.title": "crwdns11015:0crwdne11015:0",
  "quote.header.modal.retrieveQuote.checkYourEmails.description": "crwdns11017:0{emailAddress}crwdne11017:0",
  "quote.errors.questionIncomplete": "crwdns14156:0crwdne14156:0",
  "quoteJourney.shared.insuranceDeclinedQuestion.stop.title": "crwdns11021:0crwdne11021:0",
  "quoteJourney.shared.nonMotoringConvictions.stop.title": "crwdns13911:0crwdne13911:0",
  "quoteJourney.shared.licenceCountryOfIssueQuestion.stop.title": "crwdns14158:0crwdne14158:0",
  "quoteJourney.shared.mainDriverQuestion.stop.title": "crwdns11027:0crwdne11027:0",
  "quoteJourney.shared.convictionsQuestions.otherConvictionType.stop.title": "crwdns11029:0crwdne11029:0",
  "quoteJourney.shared.medicalCondition.softStop.title": "crwdns11031:0crwdne11031:0",
  "quoteJourney.shared.softStop.description": "crwdns11033:0crwdne11033:0",
  "quoteJourney.shared.nonMotoringConvictionsQuestion.tooltip.description": "crwdns13913:0crwdne13913:0",
  "vehicleQuote.promptOnExit": "crwdns11037:0crwdne11037:0",
  "vehicleQuote.title": "crwdns11039:0crwdne11039:0",
  "vehicleQuote.footerButton": "crwdns11041:0crwdne11041:0",
  "vehicleQuote.softStop.title": "crwdns11043:0crwdne11043:0",
  "vehicleQuote.softStop.description": "crwdns11045:0crwdne11045:0",
  "vehicleQuote.softStop.dismiss": "crwdns11047:0crwdne11047:0",
  "vehicleQuote.softStop.button.primary": "crwdns11049:0crwdne11049:0",
  "vehicleQuote.softStop.button.secondary": "crwdns11051:0crwdne11051:0",
  "vehicleQuote.hardStop.title": "crwdns11053:0crwdne11053:0",
  "vehicleQuote.registrationLookup.questionTitle": "crwdns11055:0crwdne11055:0",
  "vehicleQuote.registrationLookup.placeholder": "crwdns11057:0crwdne11057:0",
  "vehicleQuote.registrationLookup.submitButton": "crwdns11059:0crwdne11059:0",
  "vehicleQuote.registrationLookup.errors.validation.qRegistration": "crwdns11061:0crwdne11061:0",
  "vehicleQuote.registrationLookup.errors.validation.vehicleIsInvalid": "crwdns11063:0crwdne11063:0",
  "vehicleQuote.registrationLookup.errors.validation.commercialVehicle": "crwdns11065:0crwdne11065:0",
  "vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber": "crwdns11067:0crwdne11067:0",
  "vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber.isleOfManRegistration": "crwdns11069:0crwdne11069:0",
  "vehicleQuote.registrationLookup.errors.validation.prohibitedFuelType": "crwdns11071:0crwdne11071:0",
  "vehicleQuote.registrationLookup.errors.query.VEHICLE_REGISTRATION_INVALID": "crwdns11073:0crwdne11073:0",
  "vehicleQuote.registrationLookup.errors.query.VEHICLE_REGISTRATION_NOT_FOUND": "crwdns11075:0crwdne11075:0",
  "vehicleQuote.registrationLookup.errors.query.VEHICLE_INFORMATION_INCOMPLETE": "crwdns11077:0crwdne11077:0",
  "vehicleQuote.registrationLookup.errors.query.SERVICE_UNAVAILABLE": "crwdns11079:0crwdne11079:0",
  "vehicleQuote.registrationLookup.errors.query.SANDBOX_LIMITATION": "crwdns11081:0crwdne11081:0",
  "vehicleQuote.registrationLookup.errors.query.GENERIC_ERROR": "crwdns11083:0crwdne11083:0",
  "vehicleQuote.registrationLookup.errors.query.UNSUCCESSFUL_RESPONSE_CODE": "crwdns11085:0crwdne11085:0",
  "vehicleQuote.vehicleConfirmation.questionTitle": "crwdns11087:0crwdne11087:0",
  "vehicleQuote.vehicleConfirmation.rejectButton": "crwdns11089:0crwdne11089:0",
  "vehicleQuote.manualVehicleLookup.manualButton": "crwdns11091:0crwdne11091:0",
  "vehicleQuote.manualVehicleLookup.make": "crwdns11093:0crwdne11093:0",
  "vehicleQuote.manualVehicleLookup.make.typeahead.placeholder": "crwdns11095:0crwdne11095:0",
  "vehicleQuote.manualVehicleLookup.model": "crwdns11097:0crwdne11097:0",
  "vehicleQuote.manualVehicleLookup.model.typeahead.placeholder": "crwdns11099:0crwdne11099:0",
  "vehicleQuote.manualVehicleLookup.fuelType": "crwdns11101:0crwdne11101:0",
  "vehicleQuote.manualVehicleLookup.fuelType.stop.title": "crwdns11103:0crwdne11103:0",
  "vehicleQuote.manualVehicleLookup.transmissionType": "crwdns11105:0crwdne11105:0",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture": "crwdns11107:0crwdne11107:0",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture.tooltip.title": "crwdns11109:0crwdne11109:0",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture.tooltip.description": "crwdns11111:0crwdne11111:0",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture.stop.title": "crwdns11113:0crwdne11113:0",
  "vehicleQuote.manualVehicleLookup.engineCapacity": "crwdns11115:0crwdne11115:0",
  "vehicleQuote.manualVehicleLookup.trimLevel": "crwdns13915:0crwdne13915:0",
  "vehicleQuote.manualVehicleLookup.fullSpec": "crwdns11119:0crwdne11119:0",
  "vehicleQuote.manualVehicleLookup.errors.makesFetchFailed": "crwdns11121:0crwdne11121:0",
  "vehicleQuote.manualVehicleLookup.errors.modelsFetchFailed": "crwdns11123:0crwdne11123:0",
  "vehicleQuote.manualVehicleLookup.errors.fuelTypesFetchFailed": "crwdns11125:0crwdne11125:0",
  "vehicleQuote.manualVehicleLookup.errors.transmissionTypesFetchFailed": "crwdns11127:0crwdne11127:0",
  "vehicleQuote.manualVehicleLookup.errors.yearOfManufactureFetchFailed": "crwdns11129:0crwdne11129:0",
  "vehicleQuote.manualVehicleLookup.errors.engineCapacityLitresFetchFailed": "crwdns11131:0crwdne11131:0",
  "vehicleQuote.manualVehicleLookup.errors.trimLevelsFetchFailed": "crwdns13917:0crwdne13917:0",
  "vehicleQuote.manualVehicleLookup.errors.fullSpecsFetchFailed": "crwdns11135:0crwdne11135:0",
  "vehicleQuote.vehicleAssumptions.listTitle": "crwdns11137:0crwdne11137:0",
  "vehicleQuote.vehicleAssumptions.questionTitle": "crwdns11139:0crwdne11139:0",
  "vehicleQuote.assumptions.listItems.driverPosition": "crwdns11141:0{driverPosition}crwdne11141:0",
  "vehicleQuote.assumptions.listItems.seats": "crwdns11143:0{seats}crwdne11143:0",
  "vehicleQuote.assumptions.listItems.trackerDevice": "crwdns11145:0{hasTracker}crwdne11145:0",
  "vehicleQuote.assumptions.listItems.hasTracker": "crwdns11147:0crwdne11147:0",
  "vehicleQuote.assumptions.listItems.hasNoTracker": "crwdns11149:0crwdne11149:0",
  "vehicleQuote.assumptions.listItems.alarm": "crwdns13753:0{alarmInstaller}crwdne13753:0",
  "vehicleQuote.assumptions.listItems.alarm.unknown": "crwdns13755:0crwdne13755:0",
  "vehicleQuote.assumptions.listItems.alarm.notFitted": "crwdns13757:0crwdne13757:0",
  "vehicleQuote.assumptions.listItems.imported": "crwdns11153:0{imported}crwdne11153:0",
  "vehicleQuote.assumptions.listItems.imported.nonImported": "crwdns11155:0crwdne11155:0",
  "vehicleQuote.assumptions.listItems.importType": "crwdns11157:0{importType}crwdne11157:0",
  "vehicleQuote.assumptions.listItems.importType.eu": "crwdns11159:0crwdne11159:0",
  "vehicleQuote.assumptions.listItems.importType.nonEu": "crwdns11161:0crwdne11161:0",
  "vehicleQuote.assumptions.listItems.estimatedValue": "crwdns11163:0{estimatedValue}crwdne11163:0",
  "vehicleQuote.additionalVehicleDetailsForm.title": "crwdns11165:0crwdne11165:0",
  "vehicleQuote.additionalVehicleDetailsForm.driverPosition": "crwdns11167:0crwdne11167:0",
  "vehicleQuote.additionalVehicleDetailsForm.driverPosition.stop.title": "crwdns11169:0crwdne11169:0",
  "vehicleQuote.additionalVehicleDetailsForm.seats": "crwdns11171:0crwdne11171:0",
  "vehicleQuote.additionalVehicleDetailsForm.seats.placeholder": "crwdns11173:0crwdne11173:0",
  "vehicleQuote.additionalVehicleDetailsForm.seats.errors.belowMinimum": "crwdns11175:0crwdne11175:0",
  "vehicleQuote.additionalVehicleDetailsForm.seats.errors.aboveMaximum": "crwdns11177:0crwdne11177:0",
  "vehicleQuote.additionalVehicleDetailsForm.hasTracker": "crwdns11179:0crwdne11179:0",
  "vehicleQuote.additionalVehicleDetailsForm.hasAlarm": "crwdns13759:0crwdne13759:0",
  "vehicleQuote.additionalVehicleDetailsForm.alarmInstaller": "crwdns13761:0crwdne13761:0",
  "vehicleQuote.additionalVehicleDetailsForm.imported": "crwdns11181:0crwdne11181:0",
  "vehicleQuote.additionalVehicleDetailsForm.imported.tooltip.title": "crwdns11183:0crwdne11183:0",
  "vehicleQuote.additionalVehicleDetailsForm.imported.tooltip.description": "crwdns11185:0crwdne11185:0",
  "vehicleQuote.additionalVehicleDetailsForm.importType": "crwdns11187:0crwdne11187:0",
  "vehicleQuote.additionalVehicleDetailsForm.importType.eu.stop.title": "crwdns11189:0crwdne11189:0",
  "vehicleQuote.additionalVehicleDetailsForm.importType.nonEu.stop.title": "crwdns11191:0crwdne11191:0",
  "vehicleQuote.additionalVehicleDetailsForm.estimatedValue": "crwdns11193:0crwdne11193:0",
  "vehicleQuote.additionalVehicleDetailsForm.estimatedValue.tooltip.title": "crwdns11195:0crwdne11195:0",
  "vehicleQuote.additionalVehicleDetailsForm.estimatedValue.tooltip.description": "crwdns11197:0crwdne11197:0",
  "vehicleQuote.additionalVehicleDetailsForm.submitButton.confirmed": "crwdns11199:0crwdne11199:0",
  "vehicleQuote.additionalVehicleDetailsForm.submitButton.unconfirmed": "crwdns11201:0crwdne11201:0",
  "vehicleQuote.trackerQuestion": "crwdns11203:0crwdne11203:0",
  "vehicleQuote.modifications.title": "crwdns11205:0crwdne11205:0",
  "vehicleQuote.modifications.tooltip.description": "crwdns11207:0crwdne11207:0",
  "vehicleQuote.modifications.tooManyModificationsStop.title": "crwdns11209:0crwdne11209:0",
  "vehicleQuote.modifications.selection.title": "crwdns11211:0crwdne11211:0",
  "vehicleQuote.modifications.selection.options.alloyWheels": "crwdns11213:0crwdne11213:0",
  "vehicleQuote.modifications.selection.options.windowTint": "crwdns11215:0crwdne11215:0",
  "vehicleQuote.modifications.selection.options.other": "crwdns11217:0crwdne11217:0",
  "vehicleQuote.modifications.selection.options.other.stop.title": "crwdns11219:0crwdne11219:0",
  "vehicleQuote.modifications.alloyWheelsQuestion.title": "crwdns11221:0crwdne11221:0",
  "vehicleQuote.modifications.alloyWheelsQuestion.options.lessThan2Inches": "crwdns11223:0crwdne11223:0",
  "vehicleQuote.modifications.alloyWheelsQuestion.options.moreThan2Inches": "crwdns11225:0crwdne11225:0",
  "vehicleQuote.modifications.alloyWheelsQuestion.options.moreThan2Inches.stop.title": "crwdns11227:0crwdne11227:0",
  "vehicleQuote.modifications.windowTintQuestion.title": "crwdns11229:0crwdne11229:0",
  "vehicleQuote.modifications.windowTintQuestion.options.fullVehicle": "crwdns11231:0crwdne11231:0",
  "vehicleQuote.modifications.windowTintQuestion.options.fullVehicle.stop.title": "crwdns11233:0crwdne11233:0",
  "vehicleQuote.modifications.windowTintQuestion.options.rearOfVehicle": "crwdns11235:0crwdne11235:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.title": "crwdns11237:0crwdne11237:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.tooltip.description": "crwdns11239:0crwdne11239:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.title": "crwdns11241:0crwdne11241:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.invalidYearOfManufacture": "crwdns11243:0crwdne11243:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.beforeItWasManufactured": "crwdns11245:0crwdne11245:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.afterToday": "crwdns11247:0crwdne11247:0",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.afterCoverStartDate": "crwdns11249:0crwdne11249:0",
  "vehicleQuote.ownerOrRegisteredKeeperQuestion.title": "crwdns11251:0crwdne11251:0",
  "vehicleQuote.ownerOrRegisteredKeeperQuestion.description": "crwdns11253:0crwdne11253:0",
  "vehicleQuote.ownerQuestion.title": "crwdns11255:0crwdne11255:0",
  "vehicleQuote.ownerQuestion.tooltip.description": "crwdns11257:0crwdne11257:0",
  "vehicleQuote.ownerQuestion.stop.title": "crwdns11259:0crwdne11259:0",
  "vehicleQuote.ownerQuestion.stop.description": "crwdns11261:0crwdne11261:0",
  "vehicleQuote.registeredKeeperQuestion.title": "crwdns11263:0crwdne11263:0",
  "vehicleQuote.registeredKeeperQuestion.tooltip.description": "crwdns11265:0crwdne11265:0",
  "vehicleQuote.registeredKeeperQuestion.stop.title": "crwdns11267:0crwdne11267:0",
  "vehicleQuote.registeredKeeperQuestion.stop.description": "crwdns11269:0crwdne11269:0",
  "personalDetails.title": "crwdns11271:0crwdne11271:0",
  "personalDetails.footerButton": "crwdns11273:0crwdne11273:0",
  "personalDetails.titleQuestion": "crwdns11275:0crwdne11275:0",
  "personalDetails.titleDropdownPlaceholder": "crwdns11277:0crwdne11277:0",
  "personalDetails.firstNameQuestion": "crwdns11279:0crwdne11279:0",
  "personalDetails.firstNameQuestion.placeholder": "crwdns11281:0crwdne11281:0",
  "personalDetails.firstNameQuestion.errors.invalidEntry": "crwdns11283:0crwdne11283:0",
  "personalDetails.surnameQuestion": "crwdns11285:0crwdne11285:0",
  "personalDetails.surnameQuestion.placeholder": "crwdns11287:0crwdne11287:0",
  "personalDetails.surnameQuestion.errors.invalidEntry": "crwdns11289:0crwdne11289:0",
  "personalDetails.dateOfBirthQuestion.title": "crwdns11291:0crwdne11291:0",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.before": "crwdns11293:0crwdne11293:0",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.onStartDate": "crwdns11295:0crwdne11295:0",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.forResidencyDuration": "crwdns11297:0{residencyDuration}crwdnd11297:0{age}crwdne11297:0",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.toBeAHomeowner": "crwdns11299:0{age}crwdne11299:0",
  "personalDetails.dateOfBirthQuestion.errors.isTooOld": "crwdns11301:0crwdne11301:0",
  "personalDetails.dateOfBirthQuestion.errors.isTooOld.onStartDate": "crwdns11303:0crwdne11303:0",
  "personalDetails.classOfUseQuestion": "crwdns11305:0crwdne11305:0",
  "personalDetails.classOfUseQuestion.tooltip.title": "crwdns11307:0crwdne11307:0",
  "personalDetails.classOfUseQuestion.tooltip.description": "crwdns11309:0crwdne11309:0" + "crwdns11311:0crwdne11311:0" + "crwdns11313:0crwdne11313:0" + "crwdns11315:0crwdne11315:0" + "crwdns11317:0crwdne11317:0" + "crwdns11319:0crwdne11319:0" + "crwdns11321:0crwdne11321:0",
  "personalDetails.classOfUseQuestion.errors.nonCommutingEmploymentStatus": "crwdns11323:0crwdne11323:0",
  "personalDetails.classOfUseQuestion.errors.nonBusinessEmploymentStatus": "crwdns11325:0crwdne11325:0",
  "personalDetails.solicitingSubQuestion.title": "crwdns11327:0crwdne11327:0",
  "personalDetails.phoneNumberQuestion": "crwdns11329:0crwdne11329:0",
  "personalDetails.phoneNumberQuestion.placeholder": "crwdns11331:0crwdne11331:0",
  "personalDetails.phoneNumberQuestion.tooltip": "crwdns14160:0crwdne14160:0",
  "personalDetails.emailAddressQuestion": "crwdns11335:0crwdne11335:0",
  "personalDetails.emailAddressQuestion.tooltip": "crwdns14162:0crwdne14162:0",
  "personalDetails.phoneNumberQuestion.errors.invalidPhoneNumber": "crwdns11339:0crwdne11339:0",
  "personalDetails.storedAtNightQuestion.title": "crwdns11341:0crwdne11341:0",
  "personalDetails.storedAtNightQuestion.placeholder": "crwdns11343:0crwdne11343:0",
  "personalDetails.storedAtNightQuestion.tooltip.title": "crwdns11345:0crwdne11345:0",
  "personalDetails.storedAtNightQuestion.tooltip.description": "crwdns11347:0crwdne11347:0",
  "personalDetails.isHomeownerQuestion.title": "crwdns11349:0crwdne11349:0",
  "personalDetails.isHomeownerQuestion.tooltip": "crwdns11351:0crwdne11351:0",
  "personalDetails.addressQuestion": "crwdns11353:0crwdne11353:0",
  "personalDetails.addressQuestion.tooltip.description": "crwdns11355:0crwdne11355:0",
  "personalDetails.addressQuestion.titleWhenSet": "crwdns11357:0crwdne11357:0",
  "personalDetails.addressQuestion.placeholder": "crwdns11359:0crwdne11359:0",
  "personalDetails.addressQuestion.loading": "crwdns11361:0crwdne11361:0",
  "personalDetails.addressQuestion.errors.queryError": "crwdns11363:0crwdne11363:0",
  "personalDetails.addressQuestion.errors.validationError": "crwdns11365:0crwdne11365:0",
  "personalDetails.isCarKeptAtHomeQuestion.title": "crwdns11367:0crwdne11367:0",
  "personalDetails.isCarKeptAtHomeQuestion.tooltip.description": "crwdns11369:0crwdne11369:0",
  "personalDetails.isCarKeptAtHomeQuestion.stop.title": "crwdns11371:0crwdne11371:0",
  "personalDetails.genderQuestion": "crwdns11373:0crwdne11373:0",
  "personalDetails.genderQuestion.tooltip.description": "crwdns11375:0crwdne11375:0",
  "personalDetails.licenceCountryOfIssueQuestion": "crwdns11377:0crwdne11377:0",
  "personalDetails.licenceCountryOfIssueQuestion.answer.uk": "crwdns11379:0crwdne11379:0",
  "personalDetails.licenceCountryOfIssueQuestion.answer.roi": "crwdns11381:0crwdne11381:0",
  "personalDetails.licenceCountryOfIssueQuestion.answer.other": "crwdns11383:0crwdne11383:0",
  "personalDetails.licenceCountryOfIssueQuestion.roiLicenceMessage": "crwdns14164:0crwdne14164:0",
  "personalDetails.licenceTypeQuestion": "crwdns11387:0crwdne11387:0",
  "personalDetails.licenceDurationQuestion": "crwdns11389:0crwdne11389:0",
  "personalDetails.licenceDurationQuestion.tooltip.description": "crwdns11391:0crwdne11391:0" + "crwdns11393:0crwdne11393:0",
  "personalDetails.maritalStatusQuestion": "crwdns11395:0crwdne11395:0",
  "personalDetails.maritalStatusQuestion.additionalDriverIncompatibleStop.title": "crwdns11397:0crwdne11397:0",
  "personalDetails.maritalStatusQuestion.IncompatibleAnswerStop.reset": "crwdns11399:0crwdne11399:0",
  "personalDetails.maritalStatusQuestion.ownerIncompatible.title": "crwdns11401:0crwdne11401:0",
  "personalDetails.maritalStatusQuestion.keeperIncompatible.title": "crwdns11403:0crwdne11403:0",
  "personalDetails.maritalStatusQuestion.ownerIncompatible.description": "crwdns11405:0crwdne11405:0",
  "personalDetails.maritalStatusQuestion.keeperIncompatible.description": "crwdns11407:0crwdne11407:0",
  "personalDetails.maritalStatusQuestion.dropdownPlaceholder": "crwdns11409:0crwdne11409:0",
  "personalDetails.maritalStatusQuestion.tooltip.description": "crwdns11411:0crwdne11411:0",
  "personalDetails.primaryEmploymentQuestion": "crwdns11413:0crwdne11413:0",
  "personalDetails.primaryEmploymentQuestion.dropdownPlaceholder": "crwdns11415:0crwdne11415:0",
  "personalDetails.primaryEmploymentQuestion.employed.industry.title": "crwdns11417:0crwdne11417:0",
  "personalDetails.primaryEmploymentQuestion.employed.industry.tooltip.title": "crwdns11419:0crwdne11419:0",
  "personalDetails.primaryEmploymentQuestion.employed.industry.tooltip.description": "crwdns11421:0crwdne11421:0",
  "personalDetails.primaryEmploymentQuestion.employed.job.title": "crwdns11423:0crwdne11423:0",
  "personalDetails.primaryEmploymentQuestion.employed.job.tooltip.description": "crwdns11425:0crwdne11425:0",
  "personalDetails.primaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "crwdns11427:0crwdne11427:0",
  "personalDetails.primaryEmploymentQuestion.unemployed.previousJob.title": "crwdns11429:0crwdne11429:0",
  "personalDetails.primaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "crwdns11431:0crwdne11431:0",
  "personalDetails.primaryEmploymentQuestion.retired.previousJob.title": "crwdns11433:0crwdne11433:0",
  "personalDetails.primaryEmploymentQuestion.retired.previousJob.tooltip.description": "crwdns11435:0crwdne11435:0",
  "personalDetails.secondaryEmploymentQuestion": "crwdns11437:0crwdne11437:0",
  "personalDetails.secondaryEmploymentQuestion.tooltip.title": "crwdns11439:0crwdne11439:0",
  "personalDetails.secondaryEmploymentQuestion.tooltip.description": "crwdns11441:0crwdne11441:0" + "crwdns11443:0crwdne11443:0" + "crwdns11445:0crwdne11445:0",
  "personalDetails.secondaryEmploymentQuestion.employmentStatus.title": "crwdns11447:0crwdne11447:0",
  "personalDetails.secondaryEmploymentQuestion.employmentStatus.placeholder": "crwdns11449:0crwdne11449:0",
  "personalDetails.secondaryEmploymentQuestion.employed.industry.title": "crwdns11451:0crwdne11451:0",
  "personalDetails.secondaryEmploymentQuestion.employed.industry.tooltip.title": "crwdns11453:0crwdne11453:0",
  "personalDetails.secondaryEmploymentQuestion.employed.industry.tooltip.description": "crwdns11455:0crwdne11455:0",
  "personalDetails.secondaryEmploymentQuestion.employed.job.title": "crwdns11457:0crwdne11457:0",
  "personalDetails.secondaryEmploymentQuestion.employed.job.tooltip.description": "crwdns11459:0crwdne11459:0",
  "personalDetails.secondaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "crwdns11461:0crwdne11461:0",
  "personalDetails.secondaryEmploymentQuestion.unemployed.previousJob.title": "crwdns11463:0crwdne11463:0",
  "personalDetails.secondaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "crwdns11465:0crwdne11465:0",
  "personalDetails.secondaryEmploymentQuestion.retired.previousJob.title": "crwdns11467:0crwdne11467:0",
  "personalDetails.secondaryEmploymentQuestion.retired.previousJob.tooltip.description": "crwdns11469:0crwdne11469:0",
  "personalDetails.employmentQuestion.employed.industry.placeholder": "crwdns11471:0crwdne11471:0",
  "personalDetails.employmentQuestion.employed.job.placeholder": "crwdns11473:0crwdne11473:0",
  "personalDetails.employmentQuestion.unemployed.previousJob.placeholder": "crwdns11475:0crwdne11475:0",
  "personalDetails.employmentQuestion.retired.previousJob.placeholder": "crwdns11477:0crwdne11477:0",
  "personalDetails.hasMedicalCondition": "crwdns11479:0crwdne11479:0",
  "personalDetails.hasMedicalConditionQuestion.tooltip.description": "crwdns11481:0crwdne11481:0",
  "personalDetails.medicalCondition": "crwdns11483:0crwdne11483:0",
  "personalDetails.residency.fromBirth": "crwdns11485:0crwdne11485:0",
  "personalDetails.residency.fromBirth.tooltip": "crwdns14166:0crwdne14166:0",
  "personalDetails.residency.duration": "crwdns11489:0crwdne11489:0",
  "personalDetails.residency.duration.stop.title": "crwdns11491:0crwdne11491:0",
  "personalDetails.claims.stops.claimsLimit.title": "crwdns11493:0crwdne11493:0",
  "personalDetails.claims.hasClaims.title": "crwdns11495:0crwdne11495:0",
  "personalDetails.claims.hasClaims.description": "crwdns11497:0crwdne11497:0",
  "personalDetails.claims.hasClaims.tooltip.description": "crwdns11499:0crwdne11499:0" + "crwdns11501:0crwdne11501:0",
  "personalDetails.claims.date.title": "crwdns11503:0crwdne11503:0",
  "personalDetails.claims.date.tooltip.title": "crwdns11505:0crwdne11505:0",
  "personalDetails.claims.date.tooltip.description": "crwdns11507:0crwdne11507:0",
  "personalDetails.claims.date.error.inFuture": "crwdns11509:0crwdne11509:0",
  "personalDetails.claims.date.error.overFiveYearsInPast": "crwdns11511:0crwdne11511:0",
  "personalDetails.claims.status.title": "crwdns11513:0crwdne11513:0",
  "personalDetails.claims.status.tooltip.title": "crwdns11515:0crwdne11515:0",
  "personalDetails.claims.status.tooltip.description": "crwdns11517:0crwdne11517:0",
  "personalDetails.claims.type.title": "crwdns11519:0crwdne11519:0",
  "personalDetails.claims.faultStatus.title": "crwdns13693:0crwdne13693:0",
  "personalDetails.claims.faultStatus.tooltip.title": "crwdns11523:0crwdne11523:0",
  "personalDetails.claims.faultStatus.tooltip.description": "crwdns11525:0crwdne11525:0",
  "personalDetails.claims.accidentDescription.title": "crwdns11527:0crwdne11527:0",
  "personalDetails.claims.claimedPolicy.title": "crwdns11529:0crwdne11529:0",
  "personalDetails.claims.claimedPolicy.tooltip.description": "crwdns11531:0crwdne11531:0",
  "personalDetails.claims.noClaimsAffected.title": "crwdns11533:0crwdne11533:0",
  "personalDetails.claims.noClaimsAffected.tooltip.title": "crwdns11535:0crwdne11535:0",
  "personalDetails.claims.noClaimsAffected.tooltip.description": "crwdns11537:0crwdne11537:0",
  "personalDetails.claims.injuriesOccurred.title": "crwdns11539:0crwdne11539:0",
  "personalDetails.claims.cost.title": "crwdns11541:0crwdne11541:0",
  "personalDetails.convictionsQuestions.hasConviction.title": "crwdns11543:0crwdne11543:0",
  "personalDetails.convictionsQuestions.hasConviction.description.motoringOrFixedPenalty": "crwdns11545:0crwdne11545:0",
  "personalDetails.convictionsQuestions.hasConviction.description.pendingProsecutions": "crwdns11547:0crwdne11547:0",
  "personalDetails.convictionsQuestions.hasConviction.description.drivingDisqualification": "crwdns11549:0crwdne11549:0",
  "personalDetails.convictionsQuestions.hasConviction.tooltip.title": "crwdns11551:0crwdne11551:0",
  "personalDetails.convictionsQuestions.hasConviction.tooltip.description": "crwdns11553:0crwdne11553:0" + "crwdns11555:0crwdne11555:0",
  "personalDetails.convictionsQuestions.subQuestion.type.title": "crwdns13919:0crwdne13919:0",
  "personalDetails.convictionsQuestions.subQuestion.type.tooltip.description": "crwdns13921:0crwdne13921:0",
  "personalDetails.convictionsQuestions.subQuestion.type.fetchError": "crwdns13923:0crwdne13923:0",
  "personalDetails.convictionsQuestions.subQuestion.offence.title": "crwdns13925:0crwdne13925:0",
  "personalDetails.convictionsQuestions.subQuestion.offence.fetchError": "crwdns13927:0crwdne13927:0",
  "personalDetails.convictionsQuestions.subQuestion.date.title": "crwdns13929:0crwdne13929:0",
  "personalDetails.convictionsQuestions.subQuestion.date.tooltip.description": "crwdns13931:0crwdne13931:0",
  "personalDetails.convictionsQuestions.subQuestion.date.dateInFutureError": "crwdns11571:0crwdne11571:0",
  "personalDetails.convictionsQuestions.subQuestion.date.dateOverFiveYearsError": "crwdns11573:0crwdne11573:0",
  "personalDetails.convictionsQuestions.subQuestion.receivedPenaltyPoints.title": "crwdns11575:0crwdne11575:0",
  "personalDetails.convictionsQuestions.subQuestion.convictionPenaltyPoints.title": "crwdns11577:0crwdne11577:0",
  "personalDetails.convictionsQuestions.subQuestion.convictionPenaltyPoints.placeholder": "crwdns11579:0crwdne11579:0",
  "personalDetails.convictionsQuestions.subQuestion.receivedFine.title": "crwdns11581:0crwdne11581:0",
  "personalDetails.convictionsQuestions.subQuestion.fineAmount.title": "crwdns11583:0crwdne11583:0",
  "personalDetails.convictionsQuestions.subQuestion.fineAmount.placeholder": "crwdns11585:0crwdne11585:0",
  "personalDetails.convictionsQuestions.subQuestion.receivedDrivingBan.title": "crwdns11587:0crwdne11587:0",
  "personalDetails.convictionsQuestions.subQuestion.drivingBanDuration.title": "crwdns11589:0crwdne11589:0",
  "personalDetails.convictionsQuestions.subQuestion.alcoholTestMethod.title": "crwdns11591:0crwdne11591:0",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.title": "crwdns11593:0crwdne11593:0",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.title": "crwdns11595:0crwdne11595:0",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.description": "crwdns11597:0crwdne11597:0" + "crwdns11599:0crwdne11599:0",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.placeholder": "crwdns11601:0crwdne11601:0",
  "personalDetails.convictionsQuestions.tooManyConvictions.stop.title": "crwdns13933:0crwdne13933:0",
  "personalDetails.convictionsQuestions.convictionType.drivingWhileUsingMobilePhone": "crwdns11605:0crwdne11605:0",
  "personalDetails.nonMotoringConvictionsQuestion": "crwdns13935:0crwdne13935:0",
  "personalDetails.additionalDrivers.title": "crwdns11609:0crwdne11609:0",
  "personalDetails.additionalDrivers.prefix": "crwdns11611:0crwdne11611:0",
  "personalDetails.additionalDrivers.description": "crwdns11613:0crwdne11613:0",
  "personalDetails.additionalDrivers.button": "crwdns11615:0crwdne11615:0",
  "personalDetails.mainDriver": "crwdns11617:0crwdne11617:0",
  "personalDetails.insuranceDeclinedQuestion.title": "crwdns11619:0crwdne11619:0",
  "additionalDriver.add.title": "crwdns11621:0crwdne11621:0",
  "additionalDriver.add.sidebar.title.yourDetails": "crwdns11623:0crwdne11623:0",
  "additionalDriver.add.sidebar.title.refineYourQuote": "crwdns11625:0crwdne11625:0",
  "additionalDriver.add.sidebar.title.goBack": "crwdns11627:0crwdne11627:0",
  "additionalDriver.add.footer.cancel": "crwdns11629:0crwdne11629:0",
  "additionalDriver.add.footer.add": "crwdns11631:0crwdne11631:0",
  "additionalDriver.edit.title": "crwdns11633:0crwdne11633:0",
  "additionalDriver.edit.sidebar.title.yourDetails": "crwdns11635:0crwdne11635:0",
  "additionalDriver.edit.sidebar.title.refineYourQuote": "crwdns11637:0crwdne11637:0",
  "additionalDriver.edit.sidebar.title.goBack": "crwdns11639:0crwdne11639:0",
  "additionalDriver.edit.footer.cancel": "crwdns11641:0crwdne11641:0",
  "additionalDriver.edit.footer.save": "crwdns11643:0crwdne11643:0",
  "additionalDriver.remove.button": "crwdns11645:0crwdne11645:0",
  "additionalDriver.remove.modal.title": "crwdns11647:0crwdne11647:0",
  "additionalDriver.remove.modal.description": "crwdns11649:0crwdne11649:0",
  "additionalDriver.remove.modal.buttons.cancel": "crwdns11651:0crwdne11651:0",
  "additionalDriver.remove.modal.buttons.remove": "crwdns11653:0crwdne11653:0",
  "additionalDriver.relationshipToProposerQuestion.title": "crwdns11655:0crwdne11655:0",
  "additionalDriver.relationshipToProposerQuestion.placeholder": "crwdns11657:0crwdne11657:0",
  "additionalDriver.titleQuestion.title": "crwdns11659:0crwdne11659:0",
  "additionalDriver.firstNameQuestion.errors.invalidEntry": "crwdns11661:0crwdne11661:0",
  "additionalDriver.firstNameQuestion.title": "crwdns11663:0crwdne11663:0",
  "additionalDriver.firstNameQuestion.placeholder": "crwdns11665:0crwdne11665:0",
  "additionalDriver.surnameQuestion.title": "crwdns11667:0crwdne11667:0",
  "additionalDriver.surnameQuestion.errors.invalidEntry": "crwdns11669:0crwdne11669:0",
  "additionalDriver.surnameQuestion.placeholder": "crwdns11671:0crwdne11671:0",
  "additionalDriver.dateOfBirthQuestion.title": "crwdns11673:0crwdne11673:0",
  "additionalDriver.dateOfBirthQuestion.invalid.childDateOfBirthBeforeProposer": "crwdns11675:0crwdne11675:0",
  "additionalDriver.dateOfBirthQuestion.invalid.grandchildDateOfBirthBeforeProposer": "crwdns11677:0crwdne11677:0",
  "additionalDriver.dateOfBirthQuestion.invalid.parentDateOfBirthBeforeProposer": "crwdns11679:0crwdne11679:0",
  "additionalDriver.dateOfBirthQuestion.invalid.grandparentDateOfBirthBeforeProposer": "crwdns11681:0crwdne11681:0",
  "additionalDriver.dateOfBirthQuestion.invalid.licenceDuration": "crwdns11683:0{licenceLength}crwdnd11683:0{numberOfYearsOld}crwdne11683:0",
  "additionalDriver.dateOfBirthQuestion.invalid.licenceDuration.invalidMonths": "crwdns11685:0{years}crwdnd11685:0{months}crwdne11685:0",
  "additionalDriver.householdNumberOfCarsSubQuestion.title": "crwdns11687:0crwdne11687:0",
  "additionalDriver.householdNumberOfCarsSubQuestion.stop.title": "crwdns11689:0crwdne11689:0",
  "additionalDriver.genderQuestion.title": "crwdns11691:0crwdne11691:0",
  "additionalDriver.genderQuestion.tooltip.description": "crwdns11693:0crwdne11693:0",
  "additionalDriver.residency.fromBirth": "crwdns11695:0crwdne11695:0",
  "additionalDriver.residency.fromBirth.tooltip": "crwdns11697:0crwdne11697:0",
  "additionalDriver.residency.duration": "crwdns11699:0crwdne11699:0",
  "additionalDriver.residency.duration.stop.title": "crwdns11701:0crwdne11701:0",
  "additionalDriver.maritalStatusQuestion": "crwdns11703:0crwdne11703:0",
  "additionalDriver.maritalStatusQuestion.dropdownPlaceholder": "crwdns11705:0crwdne11705:0",
  "additionalDriver.maritalStatusQuestion.tooltip.description": "crwdns11707:0crwdne11707:0",
  "additionalDriver.maritalStatusQuestion.driverRelationshipStatusInvalid": "crwdns11709:0crwdne11709:0",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.spouse": "crwdns11711:0crwdne11711:0",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneSpouse": "crwdns11713:0crwdne11713:0",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneCommonLawSpouse": "crwdns11715:0crwdne11715:0",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneCivilPartner": "crwdns11717:0crwdne11717:0",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.spouse.commonLaw": "crwdns11719:0crwdne11719:0",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.civilPartner": "crwdns11721:0crwdne11721:0",
  "additionalDriver.primaryEmploymentQuestion": "crwdns11723:0crwdne11723:0",
  "additionalDriver.primaryEmploymentQuestion.dropdownPlaceholder": "crwdns11725:0crwdne11725:0",
  "additionalDriver.primaryEmploymentQuestion.employed.industry.title": "crwdns11727:0crwdne11727:0",
  "additionalDriver.primaryEmploymentQuestion.employed.industry.tooltip.title": "crwdns11729:0crwdne11729:0",
  "additionalDriver.primaryEmploymentQuestion.employed.industry.tooltip.description": "crwdns11731:0crwdne11731:0",
  "additionalDriver.primaryEmploymentQuestion.employed.job.title": "crwdns11733:0crwdne11733:0",
  "additionalDriver.primaryEmploymentQuestion.employed.job.tooltip.description": "crwdns11735:0crwdne11735:0",
  "additionalDriver.primaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "crwdns11737:0crwdne11737:0",
  "additionalDriver.primaryEmploymentQuestion.unemployed.previousJob.title": "crwdns11739:0crwdne11739:0",
  "additionalDriver.primaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "crwdns11741:0crwdne11741:0",
  "additionalDriver.primaryEmploymentQuestion.retired.previousJob.title": "crwdns11743:0crwdne11743:0",
  "additionalDriver.primaryEmploymentQuestion.retired.previousJob.tooltip.description": "crwdns11745:0crwdne11745:0",
  "additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.employee": "crwdns11747:0crwdne11747:0",
  "additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.employer": "crwdns11749:0crwdne11749:0",
  "additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.businessPartner": "crwdns11751:0crwdne11751:0",
  "additionalDriver.secondaryEmploymentQuestion": "crwdns11753:0crwdne11753:0",
  "additionalDriver.secondaryEmploymentQuestion.tooltip.title": "crwdns11755:0crwdne11755:0",
  "additionalDriver.secondaryEmploymentQuestion.tooltip.description": "crwdns11757:0crwdne11757:0" + "crwdns11759:0crwdne11759:0" + "crwdns11761:0crwdne11761:0",
  "additionalDriver.secondaryEmploymentQuestion.employmentStatus.title": "crwdns11763:0crwdne11763:0",
  "additionalDriver.secondaryEmploymentQuestion.employmentStatus.placeholder": "crwdns11765:0crwdne11765:0",
  "additionalDriver.secondaryEmploymentQuestion.employed.industry.title": "crwdns11767:0crwdne11767:0",
  "additionalDriver.secondaryEmploymentQuestion.employed.industry.tooltip.title": "crwdns11769:0crwdne11769:0",
  "additionalDriver.secondaryEmploymentQuestion.employed.industry.tooltip.description": "crwdns11771:0crwdne11771:0",
  "additionalDriver.secondaryEmploymentQuestion.employed.job.title": "crwdns11773:0crwdne11773:0",
  "additionalDriver.secondaryEmploymentQuestion.employed.job.tooltip.description": "crwdns11775:0crwdne11775:0",
  "additionalDriver.secondaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "crwdns11777:0crwdne11777:0",
  "additionalDriver.secondaryEmploymentQuestion.unemployed.previousJob.title": "crwdns11779:0crwdne11779:0",
  "additionalDriver.secondaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "crwdns11781:0crwdne11781:0",
  "additionalDriver.secondaryEmploymentQuestion.retired.previousJob.title": "crwdns11783:0crwdne11783:0",
  "additionalDriver.secondaryEmploymentQuestion.retired.previousJob.tooltip.description": "crwdns11785:0crwdne11785:0",
  "additionalDriver.classOfUseQuestion": "crwdns11787:0crwdne11787:0",
  "additionalDriver.classOfUseQuestion.tooltip.title": "crwdns11789:0crwdne11789:0",
  "additionalDriver.classOfUseQuestion.tooltip.description": "crwdns11791:0crwdne11791:0" + "crwdns11793:0crwdne11793:0" + "crwdns11795:0crwdne11795:0" + "crwdns11797:0crwdne11797:0" + "crwdns11799:0crwdne11799:0" + "crwdns11801:0crwdne11801:0" + "crwdns11803:0crwdne11803:0",
  "additionalDriver.classOfUseQuestion.tooltip.description.forWorkingRelationship": "crwdns11805:0crwdne11805:0" + "crwdns11807:0crwdne11807:0" + "crwdns11809:0crwdne11809:0" + "crwdns11811:0crwdne11811:0" + "crwdns11813:0crwdne11813:0" + "crwdns11815:0crwdne11815:0" + "crwdns11817:0crwdne11817:0",
  "additionalDriver.classOfUse.softStop.businessUse.spousalRelationship.student": "crwdns11819:0crwdne11819:0",
  "additionalDriver.classOfUse.softStop.businessOrCommutingUse.spousalRelationship.unemployed": "crwdns11821:0crwdne11821:0",
  "additionalDriver.classOfUse.softStop.businessOrCommutingUse.namedDriver": "crwdns14168:0crwdne14168:0",
  "additionalDriver.classOfUse.softStop.commutingUse.workingRelationship.employed": "crwdns11825:0crwdne11825:0",
  "additionalDriver.classOfUse.softStop.businessOrCommutingUse.workingRelationship.unemployed": "crwdns11827:0crwdne11827:0",
  "additionalDriver.licenceTypeQuestion": "crwdns11829:0crwdne11829:0",
  "additionalDriver.licenceCountryOfIssueQuestion": "crwdns11831:0crwdne11831:0",
  "additionalDriver.licenceDurationQuestion": "crwdns11833:0crwdne11833:0",
  "additionalDriver.licenceDurationQuestion.tooltip.description": "crwdns11835:0crwdne11835:0" + "crwdns11837:0crwdne11837:0",
  "additionalDriver.hasMedicalConditionQuestion": "crwdns11839:0crwdne11839:0",
  "additionalDriver.hasMedicalConditionQuestion.tooltip.description": "crwdns11841:0crwdne11841:0",
  "additionalDriver.medicalConditionQuestion": "crwdns11843:0crwdne11843:0",
  "additionalDriver.claims.stops.claimsLimit.title": "crwdns11845:0crwdne11845:0",
  "additionalDriver.claims.hasClaims.title": "crwdns11847:0crwdne11847:0",
  "additionalDriver.claims.hasClaims.description": "crwdns11849:0crwdne11849:0",
  "additionalDriver.claims.hasClaims.tooltip.description": "crwdns11851:0crwdne11851:0" + "crwdns11853:0crwdne11853:0",
  "additionalDriver.claims.date.title": "crwdns11855:0crwdne11855:0",
  "additionalDriver.claims.date.tooltip.title": "crwdns11857:0crwdne11857:0",
  "additionalDriver.claims.date.tooltip.description": "crwdns11859:0crwdne11859:0",
  "additionalDriver.claims.date.error.inFuture": "crwdns11861:0crwdne11861:0",
  "additionalDriver.claims.date.error.overFiveYearsInPast": "crwdns11863:0crwdne11863:0",
  "additionalDriver.claims.status.title": "crwdns11865:0crwdne11865:0",
  "additionalDriver.claims.status.tooltip.title": "crwdns11867:0crwdne11867:0",
  "additionalDriver.claims.status.tooltip.description": "crwdns11869:0crwdne11869:0",
  "additionalDriver.claims.type.title": "crwdns11871:0crwdne11871:0",
  "additionalDriver.claims.faultStatus.title": "crwdns13695:0crwdne13695:0",
  "additionalDriver.claims.faultStatus.tooltip.title": "crwdns11875:0crwdne11875:0",
  "additionalDriver.claims.faultStatus.tooltip.description": "crwdns11877:0crwdne11877:0",
  "additionalDriver.claims.accidentDescription.title": "crwdns11879:0crwdne11879:0",
  "additionalDriver.claims.claimedPolicy.title": "crwdns11881:0crwdne11881:0",
  "additionalDriver.claims.claimedPolicy.tooltip.description": "crwdns11883:0crwdne11883:0",
  "additionalDriver.claims.noClaimsAffected.title": "crwdns11885:0crwdne11885:0",
  "additionalDriver.claims.noClaimsAffected.tooltip.title": "crwdns11887:0crwdne11887:0",
  "additionalDriver.claims.noClaimsAffected.tooltip.description": "crwdns11889:0crwdne11889:0",
  "additionalDriver.claims.injuriesOccurred.title": "crwdns11891:0crwdne11891:0",
  "additionalDriver.claims.cost.title": "crwdns11893:0crwdne11893:0",
  "additionalDriver.convictionsQuestions.hasConviction.title": "crwdns11895:0crwdne11895:0",
  "additionalDriver.convictionsQuestions.hasConviction.tooltip.title": "crwdns11897:0crwdne11897:0",
  "additionalDriver.convictionsQuestions.hasConviction.tooltip.description": "crwdns11899:0crwdne11899:0" + "crwdns11901:0crwdne11901:0",
  "additionalDriver.convictionsQuestions.subQuestion.type.title": "crwdns13937:0crwdne13937:0",
  "additionalDriver.convictionsQuestions.subQuestion.type.fetchError": "crwdns13939:0crwdne13939:0",
  "additionalDriver.convictionsQuestions.subQuestion.type.tooltip.description": "crwdns13941:0crwdne13941:0",
  "additionalDriver.convictionsQuestions.subQuestion.offence.title": "crwdns13943:0crwdne13943:0",
  "additionalDriver.convictionsQuestions.subQuestion.offence.fetchError": "crwdns13945:0crwdne13945:0",
  "additionalDriver.convictionsQuestions.subQuestion.date.title": "crwdns13947:0crwdne13947:0",
  "additionalDriver.convictionsQuestions.subQuestion.date.tooltip.description": "crwdns13949:0crwdne13949:0",
  "additionalDriver.convictionsQuestions.subQuestion.receivedPenaltyPoints.title": "crwdns11917:0crwdne11917:0",
  "additionalDriver.convictionsQuestions.subQuestion.convictionPenaltyPoints.title": "crwdns13951:0crwdne13951:0",
  "additionalDriver.convictionsQuestions.subQuestion.receivedFine.title": "crwdns11921:0crwdne11921:0",
  "additionalDriver.convictionsQuestions.subQuestion.fineAmount.title": "crwdns11923:0crwdne11923:0",
  "additionalDriver.convictionsQuestions.subQuestion.receivedDrivingBan.title": "crwdns11925:0crwdne11925:0",
  "additionalDriver.convictionsQuestions.subQuestion.drivingBanDuration.title": "crwdns11927:0crwdne11927:0",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholTestMethod.title": "crwdns11929:0crwdne11929:0",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.title": "crwdns11931:0crwdne11931:0",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.title": "crwdns11933:0crwdne11933:0",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.description": "crwdns11935:0crwdne11935:0" + "crwdns11937:0crwdne11937:0",
  "additionalDriver.convictionsQuestions.tooManyConvictions.stop.title": "crwdns13953:0crwdne13953:0",
  "additionalDriver.nonMotoringConvictionsQuestion": "crwdns13955:0crwdne13955:0",
  "additionalDriver.insuranceDeclinedQuestion.title": "crwdns11943:0crwdne11943:0",
  "coverDetails.title": "crwdns11945:0crwdne11945:0",
  "coverDetails.footerButton": "crwdns11947:0crwdne11947:0",
  "coverDetails.voluntaryExcessQuestion.title": "crwdns11949:0crwdne11949:0",
  "coverDetails.voluntaryExcessQuestion.tooltip.title": "crwdns11951:0crwdne11951:0",
  "coverDetails.voluntaryExcessQuestion.tooltip.description": "crwdns11953:0crwdne11953:0" + "crwdns11955:0crwdne11955:0",
  "coverDetails.averageMileageQuestion.title": "crwdns11957:0crwdne11957:0",
  "coverDetails.averageMileageQuestion.description": "crwdns11959:0crwdne11959:0",
  "coverDetails.averageMileageQuestion.placeholder": "crwdns11961:0crwdne11961:0",
  "coverDetails.averageMileageQuestion.tooltip.title": "crwdns11963:0crwdne11963:0",
  "coverDetails.averageMileageQuestion.tooltip.description": "crwdns11965:0crwdne11965:0" + "crwdns11967:0crwdne11967:0" + "crwdns11969:0crwdne11969:0",
  "coverDetails.averageBusinessMileageQuestion.title": "crwdns11971:0crwdne11971:0",
  "coverDetails.averageBusinessMileageQuestion.description": "crwdns11973:0crwdne11973:0",
  "coverDetails.averageBusinessMileageQuestion.tooltip.title": "crwdns11975:0crwdne11975:0",
  "coverDetails.averageBusinessMileageQuestion.tooltip.description": "crwdns11977:0crwdne11977:0" + "crwdns11979:0crwdne11979:0" + "crwdns11981:0crwdne11981:0" + "crwdns11983:0crwdne11983:0",
  "coverDetails.coverStartDateQuestion.title": "crwdns11985:0crwdne11985:0",
  "coverDetails.coverStartDateQuestion.placeholder": "crwdns11987:0crwdne11987:0",
  "coverDetails.coverStartDateQuestion.tooltip": "crwdns11989:0crwdne11989:0",
  "coverDetails.coverTypeQuestion.title": "crwdns11991:0crwdne11991:0",
  "coverDetails.coverTypeQuestion.tooltip.description": "crwdns11993:0crwdne11993:0" + "crwdns11995:0crwdne11995:0" + "crwdns11997:0crwdne11997:0" + "crwdns11999:0crwdne11999:0" + "crwdns12001:0crwdne12001:0" + "crwdns12003:0crwdne12003:0",
  "coverDetails.errors.coverStartDateQuestion.noDateOfBirth": "crwdns12005:0crwdne12005:0",
  "coverDetails.errors.coverStartDateQuestion.carNotYetPurchased": "crwdns12007:0crwdne12007:0",
  "coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate": "crwdns12009:0crwdne12009:0",
  "coverDetails.previouslyHeldInsuranceQuestion.title": "crwdns12011:0crwdne12011:0",
  "coverDetails.previouslyHeldInsuranceQuestion.title.new": "crwdns12013:0crwdne12013:0",
  "coverDetails.previouslyHeldInsuranceQuestion.description": "crwdns12015:0crwdne12015:0",
  "coverDetails.yearsNoClaimsBonusSubQuestion.title": "crwdns12017:0crwdne12017:0",
  "coverDetails.yearsNoClaimsBonusSubQuestion.tooltip.title": "crwdns12019:0crwdne12019:0",
  "coverDetails.yearsNoClaimsBonusSubQuestion.tooltip.description": "crwdns12021:0crwdne12021:0" + "crwdns12023:0crwdne12023:0",
  "coverDetails.yearsNoClaimsBonusSubQuestion.placeholder": "crwdns12025:0crwdne12025:0",
  "coverDetails.existingPolicyExpiryDateSubQuestion.title": "crwdns12027:0crwdne12027:0",
  "coverDetails.existingPolicyExpiryDateSubQuestion.tooltip.description": "crwdns12029:0crwdne12029:0",
  "coverDetails.existingPolicyExpiryDateSubQuestion.warning.exceedsTwoYears": "crwdns12031:0crwdne12031:0",
  "coverDetails.existingPolicyExpiryDateSubQuestion.warning.exceedsThirtyDays": "crwdns12033:0crwdne12033:0",
  "coverDetails.protectNoClaimsBonusSubQuestion.title": "crwdns12035:0crwdne12035:0",
  "coverDetails.protectNoClaimsBonusSubQuestion.tooltip.title": "crwdns12037:0crwdne12037:0",
  "coverDetails.protectNoClaimsBonusSubQuestion.tooltip.description": "crwdns12039:0crwdne12039:0" + "crwdns12041:0crwdne12041:0" + "crwdns12043:0crwdne12043:0",
  "coverDetails.creditSearchesStatement.title": "crwdns12045:0crwdne12045:0",
  "coverDetails.creditSearchesStatement.description": "crwdns12047:0crwdne12047:0",
  "coverDetails.breakInCover.upToFourteenDays.title": "crwdns12049:0crwdne12049:0",
  "coverDetails.breakInCover.upToFourteenDays.description": "crwdns12051:0crwdne12051:0",
  "coverDetails.breakInCover.upToFourteenDays.hasNotBeenInAnAccident": "crwdns12053:0crwdne12053:0",
  "coverDetails.breakInCover.upToFourteenDays.hasNoPendingConvictions": "crwdns12055:0crwdne12055:0",
  "coverDetails.breakInCover.upToFourteenDays.hasBeenKeptAtHomeAddress": "crwdns12057:0crwdne12057:0",
  "coverDetails.breakInCover.upToFourteenDays.confirmation": "crwdns12059:0crwdne12059:0",
  "coverDetails.breakInCover.softStops.underFourteenDays.title": "crwdns12061:0crwdne12061:0",
  "coverDetails.breakInCover.softStops.underFourteenDays.description": "crwdns12063:0crwdne12063:0",
  "coverDetails.breakInCover.softStops.overFourteenDays.title": "crwdns12065:0crwdne12065:0",
  "coverDetails.breakInCover.softStops.overFourteenDays.description": "crwdns12067:0crwdne12067:0" + "crwdns12069:0crwdne12069:0",
  "coverDetails.ownOrCompanyCarQuestion.title": "crwdns12071:0crwdne12071:0",
  "coverDetails.ownOrCompanyCarQuestion.placeholder": "crwdns12073:0crwdne12073:0",
  "coverDetails.ownOrCompanyCarQuestion.option.companyCar": "crwdns12075:0crwdne12075:0",
  "coverDetails.ownOrCompanyCarQuestion.option.ownPolicy": "crwdns12077:0crwdne12077:0",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.oneYearOnly": "crwdns12079:0crwdne12079:0",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.oneMonthOnly": "crwdns12081:0crwdne12081:0",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.oneDayOnly": "crwdns12083:0crwdne12083:0",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.yearsOnly": "crwdns12085:0{years}crwdne12085:0",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.monthsOnly": "crwdns12087:0{months}crwdne12087:0",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.daysOnly": "crwdns12089:0{days}crwdne12089:0",
  "coverDetails.namedDriverOnOwnVehicleQuestion.title": "crwdns12091:0crwdne12091:0",
  "coverDetails.vehiclePreviousPolicyExpiryDateQuestion.title": "crwdns12093:0crwdne12093:0",
  "coverDetails.vehiclePreviousPolicyExpiryDateQuestion.tooltip": "crwdns12095:0crwdne12095:0" + "crwdns12097:0crwdne12097:0",
  "coverDetails.previousNamedDriverQuestion.title": "crwdns12099:0crwdne12099:0",
  "coverDetails.previousNamedDriverQuestion.tooltip.description": "crwdns12101:0crwdne12101:0",
  "coverDetails.currentlyOrPreviouslyInsuredQuestion.title": "crwdns12103:0crwdne12103:0",
  "coverDetails.currentlyOrPreviouslyInsuredQuestion.namedOnOwnVehicle.title": "crwdns12105:0crwdne12105:0",
  "coverDetails.currentlyOrPreviouslyInsuredQuestion.tooltip.description": "crwdns12107:0crwdne12107:0",
  "coverDetails.vehicleTypeQuestion.title": "crwdns12109:0crwdne12109:0",
  "coverDetails.vehicleTypeQuestion.option.car": "crwdns12111:0crwdne12111:0",
  "coverDetails.vehicleTypeQuestion.option.motorBike": "crwdns12113:0crwdne12113:0",
  "coverDetails.vehicleTypeQuestion.option.van": "crwdns12115:0crwdne12115:0",
  "coverDetails.vehicleTypeQuestion.option.other": "crwdns12117:0crwdne12117:0",
  "coverDetails.yearsNamedDriverQuestion.title": "crwdns12119:0crwdne12119:0",
  "coverDetails.yearsNamedDriverQuestion.description": "crwdns12121:0crwdne12121:0",
  "coverDetails.yearsNamedDriverQuestion.tooltip.description": "crwdns12123:0crwdne12123:0",
  "coverDetails.namedDriver.confirmation.title": "crwdns12125:0crwdne12125:0",
  "coverDetails.namedDriver.confirmation.subtitle": "crwdns12127:0crwdne12127:0",
  "coverDetails.namedDriver.confirmation.description": "crwdns12129:0crwdne12129:0",
  "coverDetails.namedDriver.confirmation.proofOfYears": "crwdns12131:0crwdne12131:0",
  "coverDetails.namedDriver.confirmation.consecutiveYears": "crwdns12133:0crwdne12133:0",
  "coverDetails.namedDriver.confirmation.noBreaks": "crwdns12135:0crwdne12135:0",
  "coverDetails.namedDriver.confirmation.ukPolicyOnly": "crwdns12137:0crwdne12137:0",
  "coverDetails.namedDriver.confirmation.noOtherVehicle": "crwdns12139:0crwdne12139:0",
  "coverDetails.namedDriver.confirmation.notPartOfFleet": "crwdns12141:0crwdne12141:0",
  "coverDetails.namedDriver.ineligiblePanel.title": "crwdns12143:0crwdne12143:0",
  "coverDetails.namedDriver.ineligiblePanel.subtitle": "crwdns12145:0crwdne12145:0",
  "coverDetails.namedDriver.ineligiblePanel.description": "crwdns12147:0crwdne12147:0",
  "coverDetails.namedDriverBreakInCover.softStop.title": "crwdns12149:0crwdne12149:0",
  "coverDetails.namedDriverBreakInCover.softStop.description": "crwdns12151:0crwdne12151:0",
  "coverDetails.companyCar.confirmation.title": "crwdns12153:0crwdne12153:0",
  "coverDetails.companyCar.confirmation.subtitle": "crwdns12155:0crwdne12155:0",
  "coverDetails.companyCar.confirmation.description": "crwdns12157:0crwdne12157:0",
  "coverDetails.companyCar.confirmation.soleUse": "crwdns12159:0crwdne12159:0",
  "coverDetails.companyCar.confirmation.proofOfYears": "crwdns12161:0crwdne12161:0",
  "coverDetails.companyCar.confirmation.consecutiveYears": "crwdns12163:0crwdne12163:0",
  "coverDetails.companyCar.confirmation.noBreaks": "crwdns12165:0crwdne12165:0",
  "coverDetails.companyCar.confirmation.ukPolicyOnly": "crwdns12167:0crwdne12167:0",
  "coverDetails.companyCar.confirmation.noOtherVehicle": "crwdns12169:0crwdne12169:0",
  "coverDetails.companyCar.confirmation.noLongerInsured": "crwdns12171:0crwdne12171:0",
  "coverDetails.companyCar.ineligiblePanel.title": "crwdns12173:0crwdne12173:0",
  "coverDetails.companyCar.ineligiblePanel.subtitle": "crwdns12175:0crwdne12175:0",
  "coverDetails.companyCar.ineligiblePanel.description": "crwdns12177:0crwdne12177:0",
  "coverDetails.companyCar.forYourInformation.title": "crwdns12179:0crwdne12179:0",
  "coverDetails.companyCar.forYourInformation.description": "crwdns12181:0crwdne12181:0",
  "coverDetails.companyCarBonusQuestion.title": "crwdns12183:0crwdne12183:0",
  "coverDetails.companyCarBonusQuestion.tooltip.description": "crwdns12185:0crwdne12185:0",
  "coverDetails.companyCarPolicyExpiryDateQuestion.title": "crwdns12187:0crwdne12187:0",
  "coverDetails.companyCarPolicyExpiryDateQuestion.tooltip.description": "crwdns12189:0crwdne12189:0",
  "quote.shared.buyQuoteRequirements.title": "crwdns12191:0crwdne12191:0",
  "quote.shared.buyQuoteRequirements.noExperience.description": "crwdns12193:0crwdne12193:0",
  "quote.shared.buyQuoteRequirements.namedDriver.description": "crwdns12195:0crwdne12195:0",
  "quote.shared.buyQuoteRequirements.noClaimsBonus.description": "crwdns12197:0crwdne12197:0",
  "quote.shared.buyQuoteRequirements.companyCar.description": "crwdns12199:0crwdne12199:0",
  "quoteDetails.title": "crwdns12201:0crwdne12201:0",
  "quoteDetails.description": "crwdns12203:0{firstName}crwdne12203:0",
  "quoteDetails.tabGroup.annualCost": "crwdns12205:0crwdne12205:0",
  "quoteDetails.tabGroup.monthlyCost": "crwdns12207:0crwdne12207:0",
  "quoteDetails.insurerInformation": "crwdns12209:0crwdne12209:0",
  "quoteDetails.footerButton": "crwdns12211:0crwdne12211:0",
  "quoteDetails.priceCard.quotePrice.label": "crwdns12213:0crwdne12213:0",
  "quoteDetails.priceCard.quotePrice.costPerMonth": "crwdns12215:0crwdne12215:0",
  "quoteDetails.priceCard.insurerName": "crwdns12219:0crwdne12219:0",
  "quoteDetails.priceCard.insurancePremium": "crwdns13829:0crwdne13829:0",
  "quoteDetails.priceCard.strollBrokerDiscount": "crwdns12223:0crwdne12223:0",
  "quoteDetails.priceCard.adminFee": "crwdns12225:0crwdne12225:0",
  "quoteDetails.priceCard.adminFee.value": "crwdns12227:0crwdne12227:0",
  "quoteDetails.priceCard.extras": "crwdns13831:0crwdne13831:0",
  "quoteDetails.priceCard.deposit": "crwdns12231:0crwdne12231:0",
  "quoteDetails.priceCard.totalPayable": "crwdns13833:0crwdne13833:0",
  "quoteDetails.priceCard.totalPayableAmount": "crwdns12235:0{aprPercentage}crwdne12235:0",
  "quoteDetails.priceCard.policyExtras": "crwdns12237:0crwdne12237:0",
  "quoteDetails.priceCard.instalments": "crwdns12239:0{numberOfInstalments}crwdne12239:0",
  "quoteDetails.priceCard.reference": "crwdns12241:0crwdne12241:0",
  "quoteDetails.priceCard.ipt": "crwdns12243:0crwdne12243:0",
  "quoteDetails.priceCard.header.quoteType.companyCar": "crwdns12245:0crwdne12245:0",
  "quoteDetails.priceCard.header.quoteType.default": "crwdns12247:0crwdne12247:0",
  "quoteDetails.priceCard.header.quoteType.namedDriverExperience": "crwdns12249:0crwdne12249:0",
  "quoteDetails.priceCard.header.quoteType.noClaims": "crwdns12251:0crwdne12251:0",
  "quoteDetails.priceCard.header.quoteReference": "crwdns12253:0{quoteReference}crwdne12253:0",
  "quoteDetails.priceCard.header.acceptedYearsOfExperience": "crwdns12255:0yearsOfExperience={yearsOfExperience}crwdnd12255:0drivingExperience={drivingExperience}crwdne12255:0",
  "quoteDetails.priceCard.header.namedDrivingExperience": "crwdns12257:0crwdne12257:0",
  "quoteDetails.priceCard.header.companyCarDrivingExperience": "crwdns12259:0crwdne12259:0",
  "quoteDetails.quoteResult.loading": "crwdns12261:0crwdne12261:0",
  "quoteDetails.quoteResult.error": "crwdns12263:0{message}crwdne12263:0",
  "quoteDetails.quoteResult.invalidVehicle": "crwdns12265:0crwdne12265:0",
  "quoteDetails.depositAndPayments.title": "crwdns12267:0crwdne12267:0",
  "quoteDetails.depositAndPayments.description": "crwdns12269:0crwdne12269:0",
  "quoteDetails.depositAndPayments.radioOption": "crwdns12271:0crwdne12271:0",
  "quoteDetails.depositAndPayments.placeholder": "crwdns12273:0crwdne12273:0",
  "quoteDetails.policyExtrasQuestion.title": "crwdns12275:0crwdne12275:0",
  "quoteDetails.summary.editButton": "crwdns12277:0crwdne12277:0",
  "quoteDetails.summary.yourCar": "crwdns12279:0crwdne12279:0",
  "quoteDetails.summary.yourCar.addDetails": "crwdns12281:0crwdne12281:0",
  "quoteDetails.summary.yourCar.description": "crwdns12283:0crwdne12283:0",
  "quoteDetails.summary.yourCar.registrationNumber": "crwdns12285:0crwdne12285:0",
  "quoteDetails.summary.yourCar.dateOfPurchase": "crwdns12287:0crwdne12287:0",
  "quoteDetails.summary.yourCar.make": "crwdns12289:0crwdne12289:0",
  "quoteDetails.summary.yourCar.model": "crwdns12291:0crwdne12291:0",
  "quoteDetails.summary.yourCar.fuel": "crwdns12293:0crwdne12293:0",
  "quoteDetails.summary.yourCar.transmission": "crwdns12295:0crwdne12295:0",
  "quoteDetails.summary.yourCar.yearOfReg": "crwdns12297:0crwdne12297:0",
  "quoteDetails.summary.yourCar.engineSize": "crwdns12299:0crwdne12299:0",
  "quoteDetails.summary.yourCar.trimLevel": "crwdns13957:0crwdne13957:0",
  "quoteDetails.summary.yourCar.driverPosition": "crwdns12303:0crwdne12303:0",
  "quoteDetails.summary.yourCar.seats": "crwdns12305:0crwdne12305:0",
  "quoteDetails.summary.yourCar.hasTracker": "crwdns12307:0crwdne12307:0",
  "quoteDetails.summary.yourCar.alarm": "crwdns12309:0crwdne12309:0",
  "quoteDetails.summary.yourCar.imported": "crwdns12311:0crwdne12311:0",
  "quoteDetails.summary.yourCar.modifications": "crwdns12313:0crwdne12313:0",
  "quoteDetails.summary.yourCar.estimatedValue": "crwdns12315:0crwdne12315:0",
  "quoteDetails.summary.yourDetails": "crwdns12317:0crwdne12317:0",
  "quoteDetails.summary.yourDetails.name": "crwdns12319:0crwdne12319:0",
  "quoteDetails.summary.yourDetails.dateOfBirth": "crwdns12321:0crwdne12321:0",
  "quoteDetails.summary.yourDetails.address": "crwdns12323:0crwdne12323:0",
  "quoteDetails.summary.yourDetails.carStorage": "crwdns12325:0crwdne12325:0",
  "quoteDetails.summary.yourDetails.homeowner": "crwdns12327:0crwdne12327:0",
  "quoteDetails.summary.yourDetails.jobTitle": "crwdns12329:0crwdne12329:0",
  "quoteDetails.summary.yourDetails.classOfUse": "crwdns12331:0crwdne12331:0",
  "quoteDetails.summary.yourDetails.licenceInfo": "crwdns12333:0crwdne12333:0",
  "quoteDetails.summary.yourDetails.cancellationsEndorsements": "crwdns12335:0crwdne12335:0",
  "quoteDetails.summary.yourDetails.convictions": "crwdns13959:0crwdne13959:0",
  "quoteDetails.summary.yourDetails.medicalInfo": "crwdns12339:0crwdne12339:0",
  "quoteDetails.summary.yourDetails.claims": "crwdns12341:0crwdne12341:0",
  "quoteDetails.summary.yourCover": "crwdns12343:0crwdne12343:0",
  "quoteDetails.summary.yourCover.startDate": "crwdns12345:0crwdne12345:0",
  "quoteDetails.summary.yourCover.type": "crwdns12347:0crwdne12347:0",
  "quoteDetails.summary.yourCover.voluntaryExcess": "crwdns12349:0crwdne12349:0",
  "quoteDetails.summary.yourCover.compulsoryExcess": "crwdns12351:0crwdne12351:0",
  "quoteDetails.summary.yourCover.noClaimsBonus": "crwdns12353:0crwdne12353:0",
  "quoteDetails.summary.yourCover.mileage": "crwdns12355:0crwdne12355:0",
  "quoteDetails.summary.additionalDrivers": "crwdns12357:0crwdne12357:0",
  "quoteDetails.summary.additionalDrivers.editDriver": "crwdns12359:0crwdne12359:0",
  "quoteDetails.summary.additionalDrivers.button": "crwdns12361:0crwdne12361:0",
  "quoteDetails.summary.additionalDrivers.description": "crwdns12363:0crwdne12363:0",
  "quoteDetails.summary.cover.title": "crwdns12365:0crwdne12365:0",
  "quoteDetails.summary.coverStartDate": "crwdns12367:0crwdne12367:0",
  "quoteDetails.summary.coverStartDate.description": "crwdns12369:0crwdne12369:0",
  "quoteDetails.summary.coverStartDate.warning.noClaims": "crwdns12371:0crwdne12371:0",
  "quoteDetails.summary.coverStartDate.warning.noClaims.tooltip": "crwdns12373:0crwdne12373:0",
  "quoteDetails.summary.coverStartDate.warning.companyCarBonus": "crwdns12375:0crwdne12375:0",
  "quoteDetails.summary.coverStartDate.warning.companyCarBonus.tooltip": "crwdns12377:0crwdne12377:0",
  "quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate": "crwdns12379:0crwdne12379:0",
  "quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate.tooltip": "crwdns12381:0{vehiclePolicyExpiryDate}crwdne12381:0",
  "quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate": "crwdns12383:0crwdne12383:0",
  "quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate.tooltip": "crwdns12385:0{vehiclePolicyExpiryDate}crwdne12385:0",
  "quoteDetails.summary.noClaimsProtection": "crwdns12387:0crwdne12387:0",
  "quoteDetails.summary.noClaimsProtection.description": "crwdns12389:0crwdne12389:0",
  "quoteDetails.summary.noClaimsProtection.protected": "crwdns12391:0crwdne12391:0",
  "quoteDetails.summary.noClaimsProtection.unprotected": "crwdns12393:0crwdne12393:0",
  "quoteDetails.summary.policyExcess": "crwdns12395:0crwdne12395:0",
  "quoteDetails.summary.checkYourDocuments": "crwdns14180:0crwdne14180:0",
  "quoteDetails.summary.policyExcess.description": "crwdns12397:0{excess}crwdne12397:0" + "crwdns12399:0crwdne12399:0",
  "quoteDetails.summary.policyExcess.detailsList.compulsory": "crwdns12401:0crwdne12401:0",
  "quoteDetails.summary.policyExcess.detailsList.voluntary": "crwdns12403:0crwdne12403:0",
  "quoteDetails.summary.voluntaryExcess": "crwdns12405:0crwdne12405:0",
  "quoteDetails.summary.coverType": "crwdns12407:0crwdne12407:0",
  "quoteDetails.summary.modal.title": "crwdns12409:0crwdne12409:0",
  "quoteDetails.summary.modal.cancel": "crwdns12411:0crwdne12411:0",
  "quoteDetails.summary.modal.save": "crwdns12413:0crwdne12413:0",
  "quoteDetails.carDetails.modal.title": "crwdns12415:0crwdne12415:0",
  "quoteDetails.carDetails.modal.registrationNumber": "crwdns12417:0crwdne12417:0",
  "quoteDetails.carDetails.modal.registrationNumber.placeholder": "crwdns12419:0crwdne12419:0",
  "quoteDetails.carDetails.modal.registrationNumber.error": "crwdns12421:0crwdne12421:0",
  "quoteDetails.carDetails.modal.registrationNumber.error.qRegistration": "crwdns12423:0crwdne12423:0",
  "quoteDetails.carDetails.modal.dateOfPurchase": "crwdns12425:0crwdne12425:0",
  "quoteDetails.carDetails.modal.dateOfPurchase.afterToday": "crwdns12427:0crwdne12427:0",
  "quoteDetails.carDetails.modal.cancel": "crwdns12429:0crwdne12429:0",
  "quoteDetails.carDetails.modal.save": "crwdns12431:0crwdne12431:0",
  "quoteDetails.sidebar.title.saveQuote": "crwdns12433:0crwdne12433:0",
  "quoteDetails.sidebar.title.quoteSaved": "crwdns12435:0crwdne12435:0",
  "quoteDetails.sidebar.title.quoteEdited": "crwdns12437:0crwdne12437:0",
  "quoteDetails.sidebar.description.saveYourQuote.main": "crwdns12439:0crwdne12439:0",
  "quoteDetails.sidebar.description.saveYourQuote.secondary": "crwdns12441:0crwdne12441:0",
  "quoteDetails.sidebar.description.quoteSaved.main": "crwdns12443:0crwdne12443:0",
  "quoteDetails.sidebar.description.quoteEdited.main": "crwdns12445:0crwdne12445:0",
  "quoteDetails.sidebar.description.quoteEdited.secondary": "crwdns12447:0crwdne12447:0",
  "quoteDetails.sidebar.button.saveQuote": "crwdns12449:0crwdne12449:0",
  "quoteDetails.sidebar.button.quoteEdited": "crwdns12451:0crwdne12451:0",
  "quoteDetails.sidebar.button.changeEmailAddress": "crwdns12453:0crwdne12453:0",
  "quoteDetails.quoteDocuments.title": "crwdns12455:0crwdne12455:0",
  "quoteDetails.quoteDocuments.description": "crwdns12457:0crwdne12457:0",
  "quoteDetails.quoteDocuments.ipid": "crwdns12459:0crwdne12459:0",
  "quoteDetails.quoteDocuments.policyBooklet": "crwdns12461:0crwdne12461:0",
  "quoteDetails.legalDocuments.title": "crwdns12463:0crwdne12463:0",
  "quoteDetails.legalDocuments.description": "crwdns12465:0crwdne12465:0",
  "quoteDetails.legalDocuments.termsAndConditions": "crwdns12467:0crwdne12467:0",
  "quoteDetails.legalDocuments.privacyPolicy": "crwdns12469:0crwdne12469:0",
  "quoteDetails.contactDetails.title": "crwdns12471:0crwdne12471:0",
  "quoteDetails.contactDetails.description": "crwdns12473:0crwdne12473:0",
  "quoteDetails.contactDetails.email.label": "crwdns12475:0crwdne12475:0",
  "quoteDetails.contactDetails.email.placeholder": "crwdns12477:0crwdne12477:0",
  "quoteDetails.contactDetails.email.error.required": "crwdns12479:0crwdne12479:0",
  "quoteDetails.contactDetails.email.error.accountAlreadyExists": "crwdns12481:0crwdne12481:0",
  "quoteDetails.contactDetails.email.error.genericFailure": "crwdns12483:0crwdne12483:0",
  "quoteDetails.contactDetails.email.error.invalidEntry": "crwdns12485:0crwdne12485:0",
  "quoteDetails.contactDetails.phone.label": "crwdns12487:0crwdne12487:0",
  "quoteDetails.contactDetails.phone.placeholder": "crwdns12489:0crwdne12489:0",
  "quoteDetails.contactDetails.confirmation.title": "crwdns12491:0crwdne12491:0",
  "quoteDetails.contactDetails.confirmation.email.label": "crwdns12493:0crwdne12493:0",
  "quoteDetails.contactDetails.confirmation.phoneNumber.label": "crwdns12495:0crwdne12495:0",
  "quoteDetails.contactDetails.confirmation.question.label": "crwdns12497:0crwdne12497:0",
  "quoteDetails.contactDetails.confirmation.error": "crwdns12499:0crwdne12499:0",
  "quoteDetails.contactDetails.form.title": "crwdns12501:0crwdne12501:0",
  "quoteDetails.contactDetails.form.save": "crwdns12503:0crwdne12503:0",
  "quoteDetails.tooYoungForFinanceStop.title": "crwdns12505:0crwdne12505:0",
  "quoteDetails.tooYoungForFinanceStop.description": "crwdns12507:0crwdne12507:0",
  "quoteDetails.tooYoungForFinanceStop.button.contactAgent": "crwdns13881:0crwdne13881:0",
  "quoteDetails.tooYoungForFinanceStop.button.return": "crwdns12511:0crwdne12511:0",
  "quoteDetails.noClaimsProtectionCard.heading.title": "crwdns12513:0crwdne12513:0",
  "quoteDetails.noClaimsProtectionCard.heading.included": "crwdns13835:0{price}crwdne13835:0",
  "quoteDetails.noClaimsProtectionCard.heading.notIncluded": "crwdns12517:0crwdne12517:0",
  "quoteDetails.noClaimsProtectionCard.body.includedAtPrice": "crwdns13837:0{price}crwdne13837:0",
  "quoteDetails.noClaimsProtectionCard.body.included.whyProtectYourBonus": "crwdns12521:0{years}crwdnd12521:0{discountPercentage}crwdne12521:0",
  "quoteDetails.noClaimsProtectionCard.body.included.isNCBProtectionOptional": "crwdns12523:0crwdne12523:0",
  "quoteDetails.noClaimsProtectionCard.body.notIncluded.whyProtectYourBonus": "crwdns12525:0{years}crwdnd12525:0{discountPercentage}crwdne12525:0",
  "quoteDetails.noClaimsProtectionCard.body.notIncluded.isNCBProtectionOptional": "crwdns12527:0crwdne12527:0",
  "quoteDetails.noClaimsProtectionCard.body.doesThisProtectMyPriceNextYear": "crwdns12529:0crwdne12529:0",
  "quoteDetails.noClaimsProtectionCard.body.button": "crwdns12531:0crwdne12531:0",
  "quoteDetails.noClaimsProtectionCard.footer.button": "crwdns12533:0crwdne12533:0",
  "quoteDetails.goBack": "crwdns12535:0crwdne12535:0",
  "quoteDetails.notificationBanner.changeOfDetails": "crwdns12537:0crwdne12537:0",
  "quoteDetails.notificationBanner.changeOfDetails.foundACheaperQuote": "crwdns12539:0crwdne12539:0",
  "quoteDetails.notificationBanner.changeOfDetails.newQuotes": "crwdns12541:0crwdne12541:0",
  "quoteDetails.notificationBanner.changeOfDetails.callToAction": "crwdns12543:0crwdne12543:0",
  "quoteDetails.aggregatorMissingAnswersModal.title": "crwdns13799:0crwdne13799:0",
  "quoteDetails.aggregatorMissingAnswersModal.carPurchaseDate.title": "crwdns13801:0crwdne13801:0",
  "quoteDetails.aggregatorMissingAnswersModal.carPurchaseDate.description": "crwdns13803:0crwdne13803:0",
  "quoteDetails.aggregatorMissingAnswersModal.existingPolicyExpiryDate.title": "crwdns13805:0crwdne13805:0",
  "quoteDetails.aggregatorMissingAnswersModal.existingPolicyExpiryDate.description": "crwdns13807:0crwdne13807:0",
  "quoteDetails.aggregatorMissingAnswersModal.policyStartDate.title": "crwdns13809:0crwdne13809:0",
  "quoteDetails.aggregatorMissingAnswersModal.policyStartDate.description": "crwdns13811:0crwdne13811:0",
  "quoteDetails.aggregatorMissingAnswersModal.confirm": "crwdns13813:0crwdne13813:0",
  "priorQuote.header.viewSavedQuotes": "crwdns12545:0crwdne12545:0",
  "priorQuote.header.login": "crwdns12547:0crwdne12547:0",
  "priorQuote.header.quotePrice.title": "crwdns12549:0crwdne12549:0",
  "priorQuote.header.quotePrice.placeholder": "crwdns12551:0crwdne12551:0",
  "priorQuote.header.vehicleReg": "crwdns12553:0crwdne12553:0",
  "priorQuote.header.vehicleReg.placeholder": "crwdns12555:0crwdne12555:0",
  "priorQuote.header.noClaimsProtection": "crwdns12557:0crwdne12557:0",
  "priorQuote.header.noClaimsProtection.placeholder": "crwdns12559:0crwdne12559:0",
  "priorQuote.header.policyExcess": "crwdns12561:0crwdne12561:0",
  "priorQuote.header.coverType": "crwdns12563:0crwdne12563:0",
  "priorQuote.header.coverType.placeholder": "crwdns12565:0crwdne12565:0",
  "priorQuote.header.startDate": "crwdns12567:0crwdne12567:0",
  "priorQuote.header.startDate.placeholder": "crwdns12569:0crwdne12569:0",
  "priorQuote.header.buyNow": "crwdns12571:0crwdne12571:0",
  "priorQuote.quoteNotFound.title": "crwdns12573:0crwdne12573:0",
  "priorQuote.quoteNotFound.subTitle": "crwdns12575:0crwdne12575:0",
  "priorQuote.quoteNotFound.description": "crwdns12577:0crwdne12577:0",
  "priorQuote.quoteNotFound.button": "crwdns12579:0crwdne12579:0",
  "priorQuote.coverStartDateExpiredModal.title": "crwdns12581:0crwdne12581:0",
  "priorQuote.coverStartDateExpiredModal.question.title": "crwdns12583:0crwdne12583:0",
  "priorQuote.coverStartDateExpiredModal.question.description": "crwdns12585:0crwdne12585:0",
  "priorQuote.coverStartDateExpiredModal.buttons.update": "crwdns12587:0crwdne12587:0",
  "priorQuote.priceGuaranteeExpiredModal.title": "crwdns12589:0crwdne12589:0",
  "priorQuote.priceGuaranteeExpiredModal.description": "crwdns12591:0crwdne12591:0",
  "priorQuote.priceGuaranteeExpiredModal.dismiss": "crwdns12593:0crwdne12593:0",
  "priorQuote.title": "crwdns12595:0crwdne12595:0",
  "priorQuote.description": "crwdns12597:0{firstName}crwdne12597:0",
  "aggregatorQuote.sidebar.description": "crwdns13665:0crwdne13665:0",
  "aggregatorQuote.priceCard.footer.editQuote.title": "crwdns13815:0crwdne13815:0",
  "aggregatorQuote.priceCard.footer.editQuote.description": "crwdns13817:0crwdne13817:0",
  "aggregatorQuote.priceCard.footer.editQuote.button": "crwdns13819:0crwdne13819:0",
  "aggregatorQuote.priceCard.footer.errorsPresent.title": "crwdns13821:0crwdne13821:0",
  "aggregatorQuote.priceCard.footer.errorsPresent.description": "crwdns13823:0crwdne13823:0",
  "aggregatorQuote.priceCard.footer.errorsPresent.button": "crwdns13825:0crwdne13825:0",
  "aggregatorQuote.validation.item.doesNotExist": "crwdns13961:0crwdne13961:0",
  "aggregatorQuote.validationIssueTable.item.resolve": "crwdns13773:0crwdne13773:0",
  "aggregatorQuote.validationIssueTable.yourCar.title": "crwdns13775:0crwdne13775:0",
  "aggregatorQuote.validationIssueTable.yourCar.description": "crwdns13777:0crwdne13777:0",
  "aggregatorQuote.validationIssueTable.yourCar.tableItem.heading": "crwdns13839:0crwdne13839:0",
  "aggregatorQuote.validationIssueTable.yourCar.tableItem.subHeading": "crwdns13841:0crwdne13841:0",
  "aggregatorQuote.validationIssueTable.yourCar.tableItem.errorMessage": "crwdns13843:0numberOfIssues={numberOfIssues}crwdne13843:0",
  "aggregatorQuote.validationIssueTable.yourDetails.title": "crwdns13779:0crwdne13779:0",
  "aggregatorQuote.validationIssueTable.yourDetails.description": "crwdns13781:0crwdne13781:0",
  "aggregatorQuote.validationIssueTable.yourDetails.tableItem.heading": "crwdns13845:0crwdne13845:0",
  "aggregatorQuote.validationIssueTable.yourDetails.tableItem.subHeading": "crwdns13847:0crwdne13847:0",
  "aggregatorQuote.validationIssueTable.yourDetails.tableItem.errorMessage": "crwdns13849:0numberOfIssues={numberOfIssues}crwdne13849:0",
  "aggregatorQuote.validationIssueTable.yourCover.title": "crwdns13783:0crwdne13783:0",
  "aggregatorQuote.validationIssueTable.yourCover.description": "crwdns13785:0crwdne13785:0",
  "aggregatorQuote.validationIssueTable.yourCover.tableItem.heading": "crwdns13851:0crwdne13851:0",
  "aggregatorQuote.validationIssueTable.yourCover.tableItem.subHeading": "crwdns13853:0crwdne13853:0",
  "aggregatorQuote.validationIssueTable.yourCover.tableItem.errorMessage": "crwdns13855:0numberOfIssues={numberOfIssues}crwdne13855:0",
  "aggregatorQuote.validationIssueTable.additionalDrivers.title": "crwdns13787:0crwdne13787:0",
  "aggregatorQuote.validationIssueTable.additionalDrivers.description": "crwdns13789:0crwdne13789:0",
  "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.heading": "crwdns13857:0crwdne13857:0",
  "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.subHeading": "crwdns13859:0{firstName}crwdnd13859:0{lastName}crwdnd13859:0{relationshipToProposer}crwdne13859:0",
  "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.errorMessage": "crwdns13861:0numberOfIssues={numberOfIssues}crwdne13861:0",
  "aggregatorQuote.resolveErrorFlow.confirm": "crwdns13963:0crwdne13963:0",
  "protectedNoClaimsDiscountInfo.title": "crwdns12601:0crwdne12601:0",
  "protectedNoClaimsDiscountInfo.description": "crwdns12603:0{insurerName}crwdne12603:0",
  "protectedNoClaimsDiscountInfo.discountTable.yearsNCD": "crwdns12605:0crwdne12605:0",
  "protectedNoClaimsDiscountInfo.discountTable.averageDiscount": "crwdns12607:0crwdne12607:0",
  "protectedNoClaimsDiscountInfo.stepBack.title": "crwdns12609:0crwdne12609:0",
  "protectedNoClaimsDiscountInfo.stepBack.description": "crwdns12611:0{insurerName}crwdne12611:0",
  "protectedNoClaimsDiscountInfo.stepBack.withProtection.title": "crwdns12613:0crwdne12613:0",
  "protectedNoClaimsDiscountInfo.stepBack.withoutProtection.title": "crwdns12615:0crwdne12615:0",
  "expiredQuote.title": "crwdns12617:0crwdne12617:0",
  "expiredQuote.description": "crwdns12619:0crwdne12619:0",
  "expiredQuote.button": "crwdns12621:0crwdne12621:0",
  "refusedQuote.title": "crwdns12623:0crwdne12623:0",
  "refusedQuote.reference": "crwdns12625:0crwdne12625:0",
  "refusedQuote.description": "crwdns12627:0crwdne12627:0",
  "refusedQuote.callToAction.edit": "crwdns12629:0crwdne12629:0",
  "refusedQuote.callToAction.chat": "crwdns12631:0crwdne12631:0",
  "refusedQuote.list.checkDateOfBirthAndLicenseInfo": "crwdns12633:0crwdne12633:0",
  "refusedQuote.list.vehicleBeyondDrivingExperience": "crwdns12635:0crwdne12635:0",
  "refusedQuote.list.claimsAndConvictionHistory": "crwdns13965:0crwdne13965:0",
  "quoteSteps.header.yourCar": "crwdns12639:0crwdne12639:0",
  "quoteSteps.sidebarDescription.yourCar": "crwdns14170:0crwdne14170:0",
  "quoteSteps.header.yourDetails": "crwdns12643:0crwdne12643:0",
  "quoteSteps.sidebarDescription.yourDetails": "crwdns14172:0crwdne14172:0",
  "quoteSteps.header.yourCover": "crwdns12647:0crwdne12647:0",
  "quoteSteps.sidebarDescription.yourCover": "crwdns14174:0crwdne14174:0",
  "quoteSteps.header.yourQuote": "crwdns12651:0crwdne12651:0",
  "quoteSteps.sidebarDescription.yourQuote": "crwdns14176:0crwdne14176:0",
  "paymentFlow.inProgress": "crwdns12655:0crwdne12655:0",
  "paymentFlow.annual.landing.mobileTitle": "crwdns12657:0crwdne12657:0",
  "paymentFlow.annual.landing.description": "crwdns13883:0crwdne13883:0",
  "paymentFlow.annual.landing.payByCard": "crwdns12661:0crwdne12661:0",
  "paymentFlow.annual.landing.sidebar.title": "crwdns12663:0crwdne12663:0",
  "paymentFlow.annual.landing.sidebar.description": "crwdns12665:0crwdne12665:0" + "crwdns12667:0crwdne12667:0",
  "paymentFlow.annual.byCard.mobileTitle": "crwdns12669:0crwdne12669:0",
  "paymentFlow.annual.byCard.sidebar.title": "crwdns12671:0crwdne12671:0",
  "paymentFlow.annual.byCard.sidebar.description": "crwdns12673:0crwdne12673:0" + "crwdns12675:0crwdne12675:0",
  "paymentFlow.monthly.directDebit.title": "crwdns12677:0crwdne12677:0",
  "paymentFlow.monthly.directDebit.description": "crwdns12679:0crwdne12679:0",
  "paymentFlow.monthly.directDebit.confirmationButton": "crwdns12681:0crwdne12681:0",
  "paymentFlow.monthly.directDebit.confirmRenewalButton": "crwdns12683:0crwdne12683:0",
  "paymentFlow.monthly.directDebit.sidebar.title": "crwdns12685:0{totalSteps}crwdne12685:0",
  "paymentFlow.monthly.directDebit.sidebar.description": "crwdns12687:0crwdne12687:0",
  "paymentFlow.monthly.directDebit.interestRate": "crwdns12689:0{interestRate}crwdnd12689:0{apr}crwdne12689:0",
  "paymentFlow.monthly.directDebit.monthlyInstalments.title": "crwdns12691:0crwdne12691:0",
  "paymentFlow.monthly.directDebit.monthlyInstalments.description": "crwdns12693:0{dateOfFirstPayment}crwdne12693:0",
  "paymentFlow.monthly.directDebit.monthlyInstalmentsForLoanRenewal.description": "crwdns12695:0crwdne12695:0",
  "paymentFlow.monthly.directDebit.bankDetails.NotRequiredForRenewal.description": "crwdns12697:0crwdne12697:0",
  "paymentFlow.monthly.directDebit.bankDetails.NotRequiredForRenewal.button.title": "crwdns12699:0crwdne12699:0",
  "paymentFlow.monthly.directDebit.form.firstPayment.title": "crwdns12701:0crwdne12701:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.title": "crwdns12703:0crwdne12703:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.sortCode.title": "crwdns12705:0crwdne12705:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.sortCode.error": "crwdns12707:0crwdne12707:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.accountNumber.title": "crwdns12709:0crwdne12709:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.accountNumber.error": "crwdns12711:0crwdne12711:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.error": "crwdns12713:0crwdne12713:0",
  "paymentFlow.monthly.directDebit.form.bankDetails.useOldDetails": "crwdns12715:0crwdne12715:0",
  "paymentFlow.monthly.directDebit.confirmRenewal.error": "crwdns12717:0crwdne12717:0",
  "paymentFlow.monthly.directDebit.paymentInfo.title": "crwdns12719:0crwdne12719:0",
  "paymentFlow.monthly.directDebit.paymentInfo.description": "crwdns12721:0crwdne12721:0" + "crwdns12723:0crwdne12723:0" + "crwdns12725:0crwdne12725:0" + "crwdns12727:0crwdne12727:0" + "crwdns12729:0crwdne12729:0",
  "paymentFlow.monthly.directDebit.paymentInfo.errorMessage": "crwdns12731:0crwdne12731:0",
  "paymentFlow.monthly.directDebit.paymentInfo.links.commonQuestions": "crwdns12733:0crwdne12733:0",
  "paymentFlow.monthly.directDebit.paymentInfo.links.creditInformation": "crwdns12735:0crwdne12735:0",
  "paymentFlow.monthly.directDebit.paymentInfo.links.directDebitGuarantee": "crwdns12737:0crwdne12737:0",
  "paymentFlow.monthly.depositPayment.title": "crwdns12739:0crwdne12739:0",
  "paymentFlow.monthly.depositPayment.description": "crwdns12741:0crwdne12741:0",
  "paymentFlow.monthly.depositPayment.sidebar.description": "crwdns12743:0crwdne12743:0" + "crwdns12745:0crwdne12745:0",
  "paymentFlow.monthly.depositPayment.confirmationButton": "crwdns12747:0crwdne12747:0",
  "paymentFlow.monthly.depositPayment.sidebar.title": "crwdns12749:0crwdne12749:0",
  "paymentFlow.monthly.depositPayment.yourDeposit.title": "crwdns12751:0crwdne12751:0",
  "paymentFlow.monthly.depositPayment.yourDeposit.alternativePaymentMethods": "crwdns12753:0crwdne12753:0",
  "paymentFlow.financeCard.monthlyCost": "crwdns12755:0{price}crwdne12755:0",
  "paymentFlow.financeCard.total.title": "crwdns12757:0crwdne12757:0",
  "paymentFlow.financeCard.insurancePremium.title": "crwdns12759:0crwdne12759:0",
  "paymentFlow.financeCard.adminFee.title": "crwdns12761:0crwdne12761:0",
  "paymentFlow.financeCard.extras.title": "crwdns12763:0crwdne12763:0",
  "paymentFlow.financeCard.financeFee.title": "crwdns12765:0crwdne12765:0",
  "paymentFlow.financeCard.depositPaid.title": "crwdns12767:0crwdne12767:0",
  "paymentFlow.byCard.form.cardDetails.title": "crwdns12769:0crwdne12769:0",
  "paymentFlow.byCard.form.cardDetails.cardholderName": "crwdns12771:0crwdne12771:0",
  "paymentFlow.byCard.form.cardDetails.cardholderName.placeholder": "crwdns12773:0crwdne12773:0",
  "paymentFlow.byCard.form.cardDetails.cardNumber": "crwdns12775:0crwdne12775:0",
  "paymentFlow.byCard.form.cardDetails.cardNumber.placeholder": "crwdns12777:0crwdne12777:0",
  "paymentFlow.byCard.form.cardDetails.cvv": "crwdns12779:0crwdne12779:0",
  "paymentFlow.byCard.form.cardDetails.cvv.placeholder": "crwdns12781:0crwdne12781:0",
  "paymentFlow.byCard.form.cardDetails.expiry": "crwdns12783:0crwdne12783:0",
  "paymentFlow.byCard.form.cardDetails.expiry.placeholder": "crwdns12785:0crwdne12785:0",
  "paymentFlow.byCard.form.button": "crwdns12787:0crwdne12787:0",
  "paymentFlow.byCard.failure": "crwdns12789:0crwdne12789:0",
  "paymentFlow.error.modal.body.title": "crwdns12791:0crwdne12791:0",
  "paymentFlow.error.modal.footer.title": "crwdns12793:0crwdne12793:0",
  "paymentFlow.error.modal.footer.description.contact": "crwdns12795:0crwdne12795:0",
  "paymentFlow.error.modal.footer.description.retry": "crwdns12797:0crwdne12797:0",
  "paymentFlow.error.modal.footer.expiredStartDate.description": "crwdns12799:0crwdne12799:0",
  "paymentFlow.error.modal.footer.expiredStartDate.button": "crwdns12801:0crwdne12801:0",
  "paymentFlow.error.modal.footer.button": "crwdns12803:0crwdne12803:0",
  "paymentFlow.success.title": "crwdns12805:0crwdne12805:0",
  "paymentFlow.success.description": "crwdns12807:0crwdne12807:0",
  "paymentFlow.success.callToAction.title": "crwdns12809:0crwdne12809:0",
  "paymentFlow.success.callToAction.checkEmail": "crwdns12811:0crwdne12811:0",
  "paymentFlow.success.callToAction.downloadMobileApp": "crwdns12813:0crwdne12813:0",
  "paymentFlow.success.renewal.description": "crwdns12815:0crwdne12815:0",
  "paymentFlow.success.renewal.weWillSendYouAnEmail": "crwdns12817:0crwdne12817:0",
  "paymentFlow.success.renewal.weWillBeInTouch": "crwdns12819:0crwdne12819:0",
  "placeholders.dropdown": "crwdns12821:0crwdne12821:0",
  "placeholders.inputs.emailAddress": "crwdns12823:0crwdne12823:0",
  "placeholders.date.day": "crwdns12825:0crwdne12825:0",
  "placeholders.date.month": "crwdns12827:0crwdne12827:0",
  "placeholders.date.shortYear": "crwdns12829:0crwdne12829:0",
  "placeholders.date.year": "crwdns12831:0crwdne12831:0",
  "placeholders.loremIpsum": "crwdns12833:0crwdne12833:0",
  "placeholders.sidebarNav.title": "crwdns12835:0crwdne12835:0",
  "placeholders.sidebarNav.description": "crwdns12837:0crwdne12837:0",
  "structure.gender.male": "crwdns12839:0crwdne12839:0",
  "structure.gender.female": "crwdns12841:0crwdne12841:0",
  "structure.gender.unknown": "crwdns12843:0crwdne12843:0",
  "structure.buttons.guideButton.story.text": "crwdns12845:0crwdne12845:0",
  "structure.buttons.viewSavedQuotesButton": "crwdns12847:0crwdne12847:0",
  "structure.currencies.gbp": "crwdns12849:0crwdne12849:0",
  "structure.currencies.gbp.placeholder": "crwdns12851:0crwdne12851:0",
  "structure.durations.yearDropdownPlaceholder": "crwdns12853:0crwdne12853:0",
  "structure.durations.monthsDropdownPlaceholder": "crwdns12855:0crwdne12855:0",
  "structure.durations.lessThan1Year": "crwdns12857:0crwdne12857:0",
  "structure.durations.1Year": "crwdns12859:0crwdne12859:0",
  "structure.durations.1Month": "crwdns12861:0crwdne12861:0",
  "structure.durations.years": "crwdns12863:0{prefix}crwdne12863:0",
  "structure.durations.months": "crwdns12865:0{prefix}crwdne12865:0",
  "structure.form.inlineQuestionEmailInput.placeholder": "crwdns12867:0crwdne12867:0",
  "structure.form.inlineQuestionEmailInput.invalidEmailAddressFormat": "crwdns12869:0crwdne12869:0",
  "structure.form.emailAddressInput.placeholder": "crwdns12871:0crwdne12871:0",
  "structure.form.dateInput.genericError": "crwdns12873:0crwdne12873:0",
  "structure.form.inputField.required": "crwdns12875:0crwdne12875:0",
  "structure.form.phoneNumberInput.placeholder": "crwdns12877:0crwdne12877:0",
  "structure.form.typeahead.loading": "crwdns12879:0crwdne12879:0",
  "structure.form.typeahead.noOptions": "crwdns12881:0crwdne12881:0",
  "structure.lists.policyExtras.description.anchorText": "crwdns12883:0crwdne12883:0",
  "structure.lists.summaryList.button": "crwdns12885:0crwdne12885:0",
  "structure.modals.stopModal.button": "crwdns12887:0crwdne12887:0",
  "structure.navigation.buttons.login": "crwdns13885:0crwdne13885:0",
  "structure.steps.tooltip.defaultTitle": "crwdns12891:0crwdne12891:0",
  "structure.questions.multipleItems.addButton": "crwdns12893:0crwdne12893:0",
  "structure.questions.multipleItems.cancelButton": "crwdns12895:0crwdne12895:0",
  "structure.questions.classOfUse.socialOnly": "crwdns12897:0crwdne12897:0",
  "structure.questions.classOfUse.socialAndCommuting": "crwdns12899:0crwdne12899:0",
  "structure.questions.classOfUse.socialCommutingAndBusiness": "crwdns12901:0crwdne12901:0",
  "structure.questions.classOfUse.socialAndBusiness": "crwdns12903:0crwdne12903:0",
  "structure.questions.medicalConditions.dvlaAwareNoRestrictions": "crwdns12905:0crwdne12905:0",
  "structure.questions.medicalConditions.restricted.oneYear": "crwdns12907:0crwdne12907:0",
  "structure.questions.medicalConditions.restricted.twoYears": "crwdns12909:0crwdne12909:0",
  "structure.questions.medicalConditions.restricted.threeYears": "crwdns12911:0crwdne12911:0",
  "structure.questions.medicalConditions.restricted.fiveYears": "crwdns12913:0crwdne12913:0",
  "structure.questions.medicalConditions.dvlaUnaware": "crwdns12915:0crwdne12915:0",
  "structure.questions.claims.dropdown.placeholder": "crwdns12917:0crwdne12917:0",
  "structure.questions.claims.status.settled": "crwdns12919:0crwdne12919:0",
  "structure.questions.claims.status.pending": "crwdns12921:0crwdne12921:0",
  "structure.questions.claims.incidentType.accident": "crwdns12923:0crwdne12923:0",
  "structure.questions.claims.incidentType.theft": "crwdns12925:0crwdne12925:0",
  "structure.questions.claims.incidentType.windscreen": "crwdns12927:0crwdne12927:0",
  "structure.questions.claims.incidentType.fire": "crwdns12929:0crwdne12929:0",
  "structure.questions.claims.incidentType.maliciousDamage": "crwdns12931:0crwdne12931:0",
  "structure.questions.claims.incidentType.riot": "crwdns12933:0crwdne12933:0",
  "structure.questions.claims.incidentType.storm": "crwdns12935:0crwdne12935:0",
  "structure.questions.claims.incidentType.vandalism": "crwdns12937:0crwdne12937:0",
  "structure.questions.claims.accidentFault.thirdParty": "crwdns13697:0crwdne13697:0",
  "structure.questions.claims.accidentFault.partial": "crwdns13699:0crwdne13699:0",
  "structure.questions.claims.accidentFault.own": "crwdns13701:0crwdne13701:0",
  "structure.questions.claims.accidentFault.pending": "crwdns13703:0crwdne13703:0",
  "structure.questions.claims.accidentDescription.hitByThirdParty.front": "crwdns12945:0crwdne12945:0",
  "structure.questions.claims.accidentDescription.hitByThirdParty.rear": "crwdns12947:0crwdne12947:0",
  "structure.questions.claims.accidentDescription.hitByThirdParty.side": "crwdns12949:0crwdne12949:0",
  "structure.questions.claims.accidentDescription.hitByThirdParty.whenParked": "crwdns12951:0crwdne12951:0",
  "structure.questions.claims.accidentDescription.hitByThirdParty.whenReversing": "crwdns12953:0crwdne12953:0",
  "structure.questions.claims.accidentDescription.hitThirdParty.front": "crwdns12955:0crwdne12955:0",
  "structure.questions.claims.accidentDescription.hitThirdParty.rear": "crwdns12957:0crwdne12957:0",
  "structure.questions.claims.accidentDescription.hitThirdParty.side": "crwdns12959:0crwdne12959:0",
  "structure.questions.claims.accidentDescription.hitThirdParty.whenParked": "crwdns12961:0crwdne12961:0",
  "structure.questions.claims.accidentDescription.hitThirdParty.whenReversing": "crwdns12963:0crwdne12963:0",
  "structure.questions.claims.accidentDescription.noOtherVehicleInvolved": "crwdns12965:0crwdne12965:0",
  "structure.questions.claims.incidentPolicy.company": "crwdns12967:0crwdne12967:0",
  "structure.questions.claims.incidentPolicy.own": "crwdns12969:0crwdne12969:0",
  "structure.questions.claims.incidentPolicy.other": "crwdns12971:0crwdne12971:0",
  "structure.questions.claims.incidentPolicy.proposers": "crwdns12973:0crwdne12973:0",
  "structure.questions.claims.affectedNCB.yes": "crwdns12975:0crwdne12975:0",
  "structure.questions.claims.affectedNCB.no": "crwdns12977:0crwdne12977:0",
  "structure.questions.claims.affectedNCB.noProtected": "crwdns12979:0crwdne12979:0",
  "structure.questions.vehicle.alarmInstaller.manufacturer": "crwdns13763:0crwdne13763:0",
  "structure.questions.vehicle.alarmInstaller.manufacturerApproved": "crwdns13765:0crwdne13765:0",
  "structure.questions.vehicle.alarmInstaller.proposer": "crwdns13767:0crwdne13767:0",
  "structure.questions.vehicle.alarmInstaller.VSIBAccredited": "crwdns14003:0crwdne14003:0",
  "structure.questions.vehicle.alarmInstaller.unknown": "crwdns13771:0crwdne13771:0",
  "structure.actionCard.claims.affectedNCB.yes": "crwdns12981:0crwdne12981:0",
  "structure.actionCard.claims.affectedNCB.no": "crwdns12983:0crwdne12983:0",
  "structure.actionCard.claims.affectedNCB.noProtected": "crwdns12985:0crwdne12985:0",
  "structure.footer.helpAndSupport": "crwdns12987:0crwdne12987:0",
  "structure.footer.blog": "crwdns12989:0crwdne12989:0",
  "structure.footer.copyright": "crwdns12991:0{currentYear}crwdne12991:0",
  "structure.footer.registration": "crwdns12993:0crwdne12993:0",
  "structure.footer.privacyPolicy": "crwdns12995:0crwdne12995:0",
  "structure.footer.cookiePolicy": "crwdns12997:0crwdne12997:0",
  "structure.footer.contactUs": "crwdns12999:0crwdne12999:0",
  "general.boolean.yes": "crwdns13001:0crwdne13001:0",
  "general.boolean.no": "crwdns13003:0crwdne13003:0",
  "general.success": "crwdns13005:0crwdne13005:0",
  "general.none": "crwdns13007:0crwdne13007:0",
  "general.edit": "crwdns13009:0crwdne13009:0",
  "test.string": "crwdns13011:0crwdne13011:0",
  "test.string.1": "crwdns13013:0crwdne13013:0",
  "test.string.2": "crwdns13015:0crwdne13015:0",
  "test.string.3": "crwdns13017:0crwdne13017:0",
  "test.string.4": "crwdns13019:0crwdne13019:0",
  "test.string.withNumbers": "crwdns13021:0crwdne13021:0",
  "test.string.withArguments": "crwdns13023:0{arg1}crwdne13023:0",
  "test.structure.question.title": "crwdns13025:0crwdne13025:0",
  "dashboard.header.welcomeText": "crwdns13887:0{firstName}crwdne13887:0",
  "dashboard.subheader.yourPolicies": "crwdns13057:0crwdne13057:0",
  "dashboard.subheader.manageYourPolicies": "crwdns13059:0crwdne13059:0",
  "dashboard.policyCard.activePolicy.progressBarDescription.dueTime": "crwdns13061:0{time}crwdne13061:0",
  "dashboard.policyCard.activePolicy.progressBarDescription.daysLeft": "crwdns13967:0days={days}crwdne13967:0",
  "dashboard.policyCard.upcomingPolicy.progressBarDescription.activeTime": "crwdns13067:0{time}crwdne13067:0",
  "dashboard.policyCard.upcomingPolicy.progressBarDescription.activeInDays": "crwdns13969:0days={days}crwdne13969:0",
  "dashboard.policyCard.cancelledPolicy.progressBarDescription": "crwdns13073:0crwdne13073:0",
  "dashboard.policyCard.expiredPolicy.progressBarDescription": "crwdns13075:0crwdne13075:0",
  "dashboard.contactCard.title": "crwdns13889:0crwdne13889:0",
  "dashboard.contactCard.description": "crwdns13079:0crwdne13079:0",
  "dashboard.contactCard.generalClaims.title": "crwdns13891:0crwdne13891:0",
  "dashboard.contactCard.generalClaims.workingHours": "crwdns13083:0crwdne13083:0",
  "dashboard.contactCard.emergencyClaims.title": "crwdns13085:0crwdne13085:0",
  "dashboard.uploadDocumentsCard.title": "crwdns13087:0crwdne13087:0",
  "dashboard.uploadDocumentsCard.description": "crwdns13089:0crwdne13089:0",
  "dashboard.uploadDocumentsCard.button.title": "crwdns13091:0crwdne13091:0",
  "dashboard.policyCard.renewalStatusPill.timeToRenew": "crwdns14031:0crwdne14031:0",
  "dashboard.policyCard.renewalStatusPill.renewComingSoon": "crwdns13095:0crwdne13095:0",
  "claims.pointOfContactCard.title": "crwdns13097:0crwdne13097:0",
  "claims.pointOfContactCard.description": "crwdns13099:0crwdne13099:0",
  "claims.pointOfContactCard.generalClaims.item.outOfHoursTitle": "crwdns13101:0crwdne13101:0",
  "claims.pointOfContactCard.Claims.FAQ": "crwdns13103:0crwdne13103:0",
  "claims.pointOfContactCard.policyExtras.title": "crwdns13105:0crwdne13105:0",
  "portal.policyCard.activePolicy.title": "crwdns13107:0crwdne13107:0",
  "portal.policyCard.upcomingPolicy.title": "crwdns13109:0crwdne13109:0",
  "portal.policyCard.cancelledPolicy.title": "crwdns13111:0crwdne13111:0",
  "portal.policyCard.expiredPolicy.title": "crwdns13113:0crwdne13113:0",
  "portal.policySelector.title": "crwdns13115:0crwdne13115:0",
  "portal.renewals.summary.title": "crwdns13117:0crwdne13117:0",
  "portal.renewals.summary.renewalOnlyDescription": "crwdns14033:0crwdne14033:0",
  "portal.renewals.summary.renewalAndRebrokeDescription": "crwdns14035:0crwdne14035:0",
  "portal.renewals.summary.rebrokeOnlyDescription": "crwdns14037:0{insurerName}crwdne14037:0",
  "portal.renewals.summary.cardTitle": "crwdns13125:0{days}crwdne13125:0",
  //TODO-REBROKE Update S1 copy following confirmation of updated version
  "portal.renewals.summary.priceCards.loading.title": "crwdns13971:0crwdne13971:0",
  "portal.renewals.summary.priceCard.renewalOnlyPriceTitle": "crwdns14039:0crwdne14039:0",
  "portal.renewals.summary.priceCard.bestPriceTitle": "crwdns13129:0crwdne13129:0",
  "portal.renewals.summary.priceCard.nextBestPriceTitle": "crwdns13131:0crwdne13131:0",
  "portal.renewals.summary.priceCard.currentInsurerTag": "crwdns13133:0crwdne13133:0",
  "portal.renewals.summary.priceCard.renewalInsurer": "crwdns13135:0{insurerName}crwdne13135:0",
  "portal.renewals.summary.priceCard.rebrokeInsurer": "crwdns13137:0{insurerName}crwdne13137:0",
  "portal.renewals.summary.priceCard.iptText": "crwdns13139:0crwdne13139:0",
  "portal.renewals.summary.priceCard.upfrontPayment": "crwdns13141:0{upfrontPayment}crwdne13141:0",
  "portal.renewals.summary.priceCard.instalments": "crwdns13143:0{instalments}crwdnd13143:0{monthlyInstalmentAmount}crwdne13143:0",
  "portal.renewals.summary.priceCard.button": "crwdns13145:0crwdne13145:0",
  "portal.renewals.summary.helpCard.title": "crwdns13147:0crwdne13147:0",
  "portal.renewals.summary.helpCard.description": "crwdns13149:0crwdne13149:0",
  "portal.renewals.summary.helpCard.button": "crwdns13893:0crwdne13893:0",
  "portal.renewals.summary.helpCard.talkToOurTeam": "crwdns14041:0crwdne14041:0",
  "portal.renewals.summary.helpCard.phoneNumber": "crwdns13155:0crwdne13155:0",
  "portal.renewals.summary.compareCover.title": "crwdns14043:0crwdne14043:0",
  "portal.renewals.summary.compareCover.description": "crwdns14045:0crwdne14045:0" + "crwdns14047:0crwdne14047:0",
  "portal.renewals.summary.compareCover.thisYears.title": "crwdns13163:0{insurer}crwdne13163:0",
  "portal.renewals.summary.compareCover.thisYears.instalments": "crwdns13165:0{instalments}crwdne13165:0",
  "portal.renewals.summary.compareCover.thisYears.button": "crwdns13167:0crwdne13167:0",
  "portal.renewals.summary.compareCover.thisYears.viewIpid": "crwdns14049:0crwdne14049:0",
  "portal.renewals.summary.compareCover.lastYears.title": "crwdns13705:0crwdne13705:0",
  "portal.renewals.summary.compareCover.lastYears.previousAmount": "crwdns14051:0crwdne14051:0",
  "portal.renewals.summary.compareCover.lastYears.button": "crwdns13173:0crwdne13173:0",
  "portal.renewals.summary.compareCover.lastYears.viewIpid": "crwdns13793:0crwdne13793:0",
  "portal.renewals.summary.extrasTable.header.column1": "crwdns14053:0crwdne14053:0",
  "portal.renewals.summary.extrasTable.header.column2": "crwdns14055:0crwdne14055:0",
  "portal.renewals.summary.extrasTable.header.column3": "crwdns13179:0crwdne13179:0",
  "portal.renewals.summary.extrasTable.smartfobKeycare.title": "crwdns13181:0crwdne13181:0",
  "portal.renewals.summary.extrasTable.smartfobKeycare.description": "crwdns14057:0crwdne14057:0",
  "portal.renewals.summary.extrasTable.roadsideAssistance.title": "crwdns13185:0crwdne13185:0",
  "portal.renewals.summary.extrasTable.roadsideAssistance.description": "crwdns14059:0crwdne14059:0",
  "portal.renewals.summary.extrasTable.courtesyCar.title": "crwdns14061:0crwdne14061:0",
  "portal.renewals.summary.extrasTable.courtesyCar.description": "crwdns14063:0crwdne14063:0",
  "portal.renewals.summary.extrasTable.legalExpenses.title": "crwdns13193:0crwdne13193:0",
  "portal.renewals.summary.extrasTable.legalExpenses.description": "crwdns14065:0crwdne14065:0",
  "portal.renewals.summary.extrasTable.excessInsurance.title": "crwdns14067:0crwdne14067:0",
  "portal.renewals.summary.extrasTable.excessInsurance.description": "crwdns14069:0crwdne14069:0",
  "portal.renewals.summary.extrasTable.row.addToPlan": "crwdns13201:0{price}crwdne13201:0",
  "portal.renewals.summary.extrasTable.row.addedToPlan": "crwdns13203:0{price}crwdne13203:0",
  "portal.renewals.summary.extrasTable.row.includedLastYear": "crwdns14071:0crwdne14071:0",
  "portal.renewals.summary.extrasTable.row.includedLastYear.mobile": "crwdns13207:0{price}crwdne13207:0",
  "portal.renewals.summary.extrasTable.row.notIncludedLastYear": "crwdns13209:0crwdne13209:0",
  "portal.renewals.summary.extrasTable.row.viewPolicyDocument": "crwdns13795:0crwdne13795:0",
  "portal.renewals.summary.protectNoClaims.header.column1": "crwdns14073:0crwdne14073:0",
  "portal.renewals.summary.protectNoClaims.header.column2": "crwdns14075:0crwdne14075:0",
  "portal.renewals.summary.protectNoClaims.body.column1.title": "crwdns14077:0crwdne14077:0",
  "portal.renewals.summary.protectNoClaims.body.column1.description": "crwdns14011:0crwdne14011:0",
  "portal.renewals.summary.protectNoClaims.body.column1.whatIsNCBProtection": "crwdns13797:0crwdne13797:0",
  "portal.renewals.summary.protectNoClaims.body.renewal.add.title": "crwdns13733:0crwdne13733:0",
  "portal.renewals.summary.protectNoClaims.body.renewal.add.description": "crwdns13735:0crwdne13735:0",
  "portal.renewals.summary.protectNoClaims.body.renewal.remove.title": "crwdns13737:0crwdne13737:0",
  "portal.renewals.summary.protectNoClaims.body.renewal.remove.description": "crwdns13739:0crwdne13739:0",
  "portal.renewals.summary.protectNoClaims.body.renewal.button": "crwdns14079:0crwdne14079:0",
  "portal.renewals.summary.protectNoClaims.body.rebroke.add.title": "crwdns13863:0crwdne13863:0",
  "portal.renewals.summary.protectNoClaims.body.rebroke.add.button": "crwdns13745:0crwdne13745:0",
  "portal.renewals.summary.protectNoClaims.body.rebroke.remove.title": "crwdns13747:0crwdne13747:0",
  "portal.renewals.summary.protectNoClaims.body.rebroke.remove.description": "crwdns13749:0crwdne13749:0",
  "portal.renewals.summary.protectNoClaims.body.rebroke.remove.button": "crwdns13751:0crwdne13751:0",
  "portal.renewals.summary.priceSummary.title": "crwdns14081:0crwdne14081:0",
  "portal.renewals.summary.priceSummary.annualOnly.title": "crwdns13231:0crwdne13231:0",
  "portal.renewals.summary.priceSummary.annual.description": "crwdns14083:0crwdne14083:0",
  "portal.renewals.summary.priceSummary.monthly.description": "crwdns13235:0crwdne13235:0",
  "portal.renewals.summary.priceSummary.card.monthly.title": "crwdns13237:0crwdne13237:0",
  "portal.renewals.summary.priceSummary.card.annual.title": "crwdns13239:0crwdne13239:0",
  "portal.renewals.summary.priceSummary.card.previouslySelected": "crwdns14085:0crwdne14085:0",
  "portal.renewals.summary.priceSummary.card.monthly.costPerMonth": "crwdns13667:0crwdne13667:0",
  "portal.renewals.summary.priceSummary.card.table.insurancePremium": "crwdns13243:0crwdne13243:0",
  "portal.renewals.summary.priceSummary.card.table.policyExtras": "crwdns13245:0crwdne13245:0",
  "portal.renewals.summary.priceSummary.card.table.adminFee": "crwdns13247:0crwdne13247:0",
  "portal.renewals.summary.priceSummary.card.table.brokerDiscount": "crwdns13249:0crwdne13249:0",
  "portal.renewals.summary.priceSummary.card.table.monthlyTotalPayable": "crwdns13251:0{interestRate}crwdnd13251:0{apr}crwdne13251:0",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.title": "crwdns13253:0crwdne13253:0",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.description": "crwdns13255:0crwdne13255:0",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.option": "crwdns13257:0{depositPercentage}crwdnd13257:0{depositAmount}crwdne13257:0",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.button": "crwdns14087:0crwdne14087:0",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.cancel": "crwdns14089:0crwdne14089:0",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.confirm": "crwdns14091:0crwdne14091:0",
  "portal.renewals.summary.priceSummary.card.selectQuoteButton": "crwdns14093:0crwdne14093:0",
  "portal.renewals.summary.footer.legal.title": "crwdns13267:0crwdne13267:0",
  "portal.renewals.summary.footer.legal.description": "crwdns14095:0crwdne14095:0" + "crwdns13271:0crwdne13271:0",
  "portal.renewals.summary.footer.legal.button.privacyPolicy": "crwdns13273:0crwdne13273:0",
  "portal.renewals.summary.footer.legal.button.termsAndConditions": "crwdns13275:0crwdne13275:0",
  "portal.renewals.summary.footer.policyDocuments.title": "crwdns13277:0crwdne13277:0",
  "portal.renewals.summary.footer.policyDocuments.description": "crwdns13279:0crwdne13279:0",
  "portal.renewals.summary.footer.policyDocuments.button.policyInfo": "crwdns13281:0crwdne13281:0",
  "portal.renewals.summary.footer.policyDocuments.button.policyBooklet": "crwdns13283:0crwdne13283:0",
  "portal.renewals.summary.footer.policyDocuments.button.policyDocuments": "crwdns13285:0crwdne13285:0",
  "portal.renewals.summary.footer.notRenewing.title": "crwdns13287:0crwdne13287:0",
  "portal.renewals.summary.footer.notRenewing.description": "crwdns13289:0crwdne13289:0",
  "portal.renewals.summary.footer.notRenewing.talkToUs": "crwdns14097:0crwdne14097:0",
  "portal.renewals.summary.footer.notRenewing.phoneNumber": "crwdns13293:0crwdne13293:0",
  "portal.renewals.summary.error.title": "crwdns13295:0crwdne13295:0",
  "portal.renewals.summary.error.description": "crwdns13297:0crwdne13297:0",
  "portal.renewals.summary.onlineRenewalSoftStop.ohItLookslike": "crwdns13681:0crwdne13681:0",
  "portal.renewals.summary.onlineRenewalSoftStop.butYouCanCall": "crwdns13683:0crwdne13683:0",
  "portal.renewals.summary.onlineRenewalSoftStop.talkToOurTeam": "crwdns13685:0crwdne13685:0",
  "portal.renewals.summary.onlineRenewalSoftStop.gotIt": "crwdns13687:0crwdne13687:0",
  "portal.renewals.summary.onlineRenewalSoftStop.stillNeedToTalk": "crwdns14001:0crwdne14001:0",
  "portal.renewals.excessProtectionModal.title": "crwdns13299:0crwdne13299:0",
  "portal.renewals.excessProtectionModal.description": "crwdns13301:0crwdne13301:0",
  "portal.renewals.excessProtectionModal.confirm": "crwdns13303:0crwdne13303:0",
  "portal.renewals.excessProtectionModal.cancel": "crwdns13305:0crwdne13305:0",
  "portal.renewals.annualised.breakdown.backToQuote": "crwdns13307:0crwdne13307:0",
  "portal.renewals.annualised.breakdown.title": "crwdns13309:0crwdne13309:0",
  "portal.renewals.annualised.breakdown.description": "crwdns14013:0crwdne14013:0",
  "portal.renewals.annualised.breakdown.card.header.title": "crwdns14015:0crwdne14015:0",
  "portal.renewals.annualised.breakdown.card.header.subtitle": "crwdns14017:0crwdne14017:0",
  "portal.renewals.annualised.breakdown.card.body.policyUpdate": "crwdns13317:0{date}crwdne13317:0",
  "portal.renewals.annualised.breakdown.card.body.annualised": "crwdns14019:0crwdne14019:0",
  "portal.renewals.annualised.breakdown.card.footer.button": "crwdns13321:0crwdne13321:0",
  "portal.renewals.annualised.breakdown.error.title": "crwdns13323:0crwdne13323:0",
  "portal.renewals.annualised.breakdown.error.description": "crwdns13325:0crwdne13325:0",
  "portal.renewals.getInTouchModal.title": "crwdns14099:0crwdne14099:0",
  "portal.renewals.getInTouchModal.description": "crwdns14101:0crwdne14101:0",
  "portal.renewals.getInTouchModal.buttonText": "crwdns13353:0crwdne13353:0",
  "portal.renewals.detailConfirmation.confirmYourDetails": "crwdns14103:0crwdne14103:0",
  "portal.renewals.detailConfirmation.previous": "crwdns13357:0crwdne13357:0",
  "portal.renewals.detailConfirmation.proceedToPayment": "crwdns13359:0crwdne13359:0",
  "portal.renewals.detailConfirmation.yourCar": "crwdns13361:0crwdne13361:0",
  "portal.renewals.detailConfirmation.yourCar.registration": "crwdns13363:0crwdne13363:0",
  "portal.renewals.detailConfirmation.yourCar.purchaseDate": "crwdns13365:0crwdne13365:0",
  "portal.renewals.detailConfirmation.yourCar.vehicle": "crwdns13367:0crwdne13367:0",
  "portal.renewals.detailConfirmation.yourCar.fuelType": "crwdns13369:0crwdne13369:0",
  "portal.renewals.detailConfirmation.yourCar.transmissionType": "crwdns13371:0crwdne13371:0",
  "portal.renewals.detailConfirmation.yourCar.yearOfManufacture": "crwdns13373:0crwdne13373:0",
  "portal.renewals.detailConfirmation.yourCar.engineSize": "crwdns13375:0crwdne13375:0",
  "portal.renewals.detailConfirmation.yourCar.trimLevel": "crwdns13973:0crwdne13973:0",
  "portal.renewals.detailConfirmation.yourCar.driverPosition": "crwdns13379:0crwdne13379:0",
  "portal.renewals.detailConfirmation.yourCar.numberOfSeats": "crwdns13381:0crwdne13381:0",
  "portal.renewals.detailConfirmation.yourCar.numberOfDoors": "crwdns13383:0crwdne13383:0",
  "portal.renewals.detailConfirmation.yourCar.tracker": "crwdns13385:0crwdne13385:0",
  "portal.renewals.detailConfirmation.yourCar.imported": "crwdns13387:0crwdne13387:0",
  "portal.renewals.detailConfirmation.yourCar.modified": "crwdns13389:0crwdne13389:0",
  "portal.renewals.detailConfirmation.yourCar.owner": "crwdns14105:0crwdne14105:0",
  "portal.renewals.detailConfirmation.yourCar.registeredKeeper": "crwdns13393:0crwdne13393:0",
  "portal.renewals.detailConfirmation.yourCarModifications": "crwdns13395:0crwdne13395:0",
  "portal.renewals.detailConfirmation.yourCarModifications.tableItem.title": "crwdns13397:0{count}crwdnd13397:0{max}crwdne13397:0",
  "portal.renewals.detailConfirmation.yourDetails": "crwdns13399:0crwdne13399:0",
  "portal.renewals.detailConfirmation.yourDetails.title": "crwdns13401:0crwdne13401:0",
  "portal.renewals.detailConfirmation.yourDetails.firstName": "crwdns13403:0crwdne13403:0",
  "portal.renewals.detailConfirmation.yourDetails.surname": "crwdns13405:0crwdne13405:0",
  "portal.renewals.detailConfirmation.yourDetails.dateOfBirth": "crwdns13407:0crwdne13407:0",
  "portal.renewals.detailConfirmation.yourDetails.gender": "crwdns13409:0crwdne13409:0",
  "portal.renewals.detailConfirmation.yourDetails.address": "crwdns13411:0crwdne13411:0",
  "portal.renewals.detailConfirmation.yourDetails.carAtAddress": "crwdns13413:0crwdne13413:0",
  "portal.renewals.detailConfirmation.yourDetails.carKeptAtNight": "crwdns13415:0crwdne13415:0",
  "portal.renewals.detailConfirmation.yourDetails.email": "crwdns14107:0crwdne14107:0",
  "portal.renewals.detailConfirmation.yourDetails.phoneNumber": "crwdns14109:0crwdne14109:0",
  "portal.renewals.detailConfirmation.yourDetails.relationshipStatus": "crwdns14111:0crwdne14111:0",
  "portal.renewals.detailConfirmation.yourDetails.homeOwner": "crwdns13423:0crwdne13423:0",
  "portal.renewals.detailConfirmation.yourDetails.ukResidency.lengthOfTime": "crwdns13427:0crwdne13427:0",
  "portal.renewals.detailConfirmation.yourDetails.employmentStatus": "crwdns14113:0crwdne14113:0",
  "portal.renewals.detailConfirmation.yourDetails.industry": "crwdns13431:0crwdne13431:0",
  "portal.renewals.detailConfirmation.yourDetails.jobTitle": "crwdns14115:0crwdne14115:0",
  "portal.renewals.detailConfirmation.yourDetails.jobTitle.retired": "crwdns13435:0crwdne13435:0",
  "portal.renewals.detailConfirmation.yourDetails.jobTitle.unemployed": "crwdns13437:0crwdne13437:0",
  "portal.renewals.detailConfirmation.yourDetails.secondJob": "crwdns13439:0crwdne13439:0",
  "portal.renewals.detailConfirmation.yourDetails.secondJobIndustry": "crwdns13441:0crwdne13441:0",
  "portal.renewals.detailConfirmation.yourDetails.secondJobTitle": "crwdns14117:0crwdne14117:0",
  "portal.renewals.detailConfirmation.yourDetails.carUse": "crwdns13445:0crwdne13445:0",
  "portal.renewals.detailConfirmation.yourDetails.mainDriver": "crwdns13447:0crwdne13447:0",
  "portal.renewals.detailConfirmation.yourDetails.licence": "crwdns14119:0crwdne14119:0",
  "portal.renewals.detailConfirmation.yourDetails.licenceDuration": "crwdns13451:0crwdne13451:0",
  "portal.renewals.detailConfirmation.yourDetails.medicalConditions": "crwdns13453:0crwdne13453:0",
  "portal.renewals.detailConfirmation.yourDetails.claims": "crwdns13455:0crwdne13455:0",
  "portal.renewals.detailConfirmation.yourDetails.convictions": "crwdns13457:0crwdne13457:0",
  "portal.renewals.detailConfirmation.yourDetails.nonMotoringConvictions": "crwdns13975:0crwdne13975:0",
  "portal.renewals.detailConfirmation.yourDetails.insuranceDeclined": "crwdns13461:0crwdne13461:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onTheStreet": "crwdns13463:0crwdne13463:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inTheDriveway": "crwdns13465:0crwdne13465:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inAGarage": "crwdns13467:0crwdne13467:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inACarport": "crwdns13469:0crwdne13469:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inACarPark": "crwdns13471:0crwdne13471:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inALockedCompound": "crwdns13473:0crwdne13473:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inAnUnlockedCompound": "crwdns13475:0crwdne13475:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onPrivatePremises": "crwdns14182:0crwdne14182:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onUnlockedPrivatePremises": "crwdns14184:0crwdne14184:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onSecurePrivatePremises": "crwdns14186:0crwdne14186:0",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.other": "crwdns13477:0crwdne13477:0",
  "portal.renewals.detailConfirmation.yourDetails.classOfUse.social": "crwdns13479:0crwdne13479:0",
  "portal.renewals.detailConfirmation.yourDetails.classOfUse.socialCommuting": "crwdns13481:0crwdne13481:0",
  "portal.renewals.detailConfirmation.yourDetails.classOfUse.socialCommutingBusiness": "crwdns13483:0crwdne13483:0",
  "portal.renewals.detailConfirmation.yourCover": "crwdns13485:0crwdne13485:0",
  "portal.renewals.detailConfirmation.yourCover.coverType": "crwdns14121:0crwdne14121:0",
  "portal.renewals.detailConfirmation.yourCover.voluntaryExcess": "crwdns14123:0crwdne14123:0",
  "portal.renewals.detailConfirmation.yourCover.compulsoryExcess": "crwdns14125:0crwdne14125:0",
  "portal.renewals.detailConfirmation.yourCover.compulsoryExcess.renewal": "crwdns13977:0crwdne13977:0",
  "portal.renewals.detailConfirmation.yourCover.mileage": "crwdns14188:0crwdne14188:0",
  "portal.renewals.detailConfirmation.yourCover.businessMileage": "crwdns14190:0crwdne14190:0",
  "portal.renewals.detailConfirmation.yourCover.insuranceStart": "crwdns14129:0crwdne14129:0",
  "portal.renewals.detailConfirmation.yourClaims": "crwdns13497:0crwdne13497:0",
  "portal.renewals.detailConfirmation.yourClaims.atFault.noBlame": "crwdns13707:0crwdne13707:0",
  "portal.renewals.detailConfirmation.yourClaims.atFault.partial": "crwdns13709:0crwdne13709:0",
  "portal.renewals.detailConfirmation.yourClaims.atFault.pending": "crwdns13503:0crwdne13503:0",
  "portal.renewals.detailConfirmation.yourClaims.atFault.yes": "crwdns13711:0crwdne13711:0",
  "portal.renewals.detailConfirmation.yourClaims.settled": "crwdns13507:0crwdne13507:0",
  "portal.renewals.detailConfirmation.yourClaims.type": "crwdns13509:0crwdne13509:0",
  "portal.renewals.detailConfirmation.yourClaims.fault": "crwdns13713:0crwdne13713:0",
  "portal.renewals.detailConfirmation.yourClaims.description": "crwdns13513:0crwdne13513:0",
  "portal.renewals.detailConfirmation.yourClaims.policy": "crwdns13515:0crwdne13515:0",
  "portal.renewals.detailConfirmation.yourClaims.ncbAffected": "crwdns13517:0crwdne13517:0",
  "portal.renewals.detailConfirmation.yourClaims.injuries": "crwdns13519:0crwdne13519:0",
  "portal.renewals.detailConfirmation.yourClaims.cost": "crwdns14131:0crwdne14131:0",
  "portal.renewals.detailConfirmation.yourClaims.tableItem.title": "crwdns13523:0{count}crwdnd13523:0{max}crwdne13523:0",
  "portal.renewals.detailConfirmation.yourClaims.emptyState.title": "crwdns13525:0crwdne13525:0",
  "portal.renewals.detailConfirmation.yourClaims.emptyState.subtitle": "crwdns14133:0crwdne14133:0",
  "portal.renewals.detailConfirmation.additionalDrivers": "crwdns13529:0crwdne13529:0",
  "portal.renewals.detailConfirmation.additionalDrivers.tableItem.title": "crwdns13531:0{count}crwdnd13531:0{max}crwdne13531:0",
  "portal.renewals.detailConfirmation.additionalDrivers.title": "crwdns13533:0crwdne13533:0",
  "portal.renewals.detailConfirmation.additionalDrivers.firstName": "crwdns13535:0crwdne13535:0",
  "portal.renewals.detailConfirmation.additionalDrivers.surname": "crwdns13537:0crwdne13537:0",
  "portal.renewals.detailConfirmation.additionalDrivers.dateOfBirth": "crwdns13539:0crwdne13539:0",
  "portal.renewals.detailConfirmation.additionalDrivers.ukResidency.lengthOfTime": "crwdns13543:0crwdne13543:0",
  "portal.renewals.detailConfirmation.additionalDrivers.relationshipToPolicyHolder": "crwdns13545:0crwdne13545:0",
  "portal.renewals.detailConfirmation.additionalDrivers.relationshipStatus": "crwdns14135:0crwdne14135:0",
  "portal.renewals.detailConfirmation.additionalDrivers.employmentStatus": "crwdns14137:0crwdne14137:0",
  "portal.renewals.detailConfirmation.additionalDrivers.industry": "crwdns13551:0crwdne13551:0",
  "portal.renewals.detailConfirmation.additionalDrivers.jobTitle": "crwdns14139:0crwdne14139:0",
  "portal.renewals.detailConfirmation.additionalDrivers.retired": "crwdns13555:0crwdne13555:0",
  "portal.renewals.detailConfirmation.additionalDrivers.unemployed": "crwdns13557:0crwdne13557:0",
  "portal.renewals.detailConfirmation.additionalDrivers.secondJob": "crwdns13559:0crwdne13559:0",
  "portal.renewals.detailConfirmation.additionalDrivers.secondJobIndustry": "crwdns13561:0crwdne13561:0",
  "portal.renewals.detailConfirmation.additionalDrivers.secondJobTitle": "crwdns13563:0crwdne13563:0",
  "portal.renewals.detailConfirmation.additionalDrivers.carUsage": "crwdns13565:0crwdne13565:0",
  "portal.renewals.detailConfirmation.additionalDrivers.mainDriver": "crwdns13567:0crwdne13567:0",
  "portal.renewals.detailConfirmation.additionalDrivers.licence": "crwdns14141:0crwdne14141:0",
  "portal.renewals.detailConfirmation.additionalDrivers.licenceDuration": "crwdns13571:0crwdne13571:0",
  "portal.renewals.detailConfirmation.additionalDrivers.medicalConditions": "crwdns13573:0crwdne13573:0",
  "portal.renewals.detailConfirmation.additionalDrivers.claims": "crwdns13575:0crwdne13575:0",
  "portal.renewals.detailConfirmation.additionalDrivers.convictions": "crwdns13577:0crwdne13577:0",
  "portal.renewals.detailConfirmation.additionalDrivers.nonMotorConvictions": "crwdns13979:0crwdne13979:0",
  "portal.renewals.detailConfirmation.additionalDrivers.insuranceDeclined": "crwdns13581:0crwdne13581:0",
  "portal.renewals.detailConfirmation.additionalDrivers.emptyState.title": "crwdns13583:0crwdne13583:0",
  "portal.renewals.detailConfirmation.additionalDrivers.emptyState.subtitle": "crwdns14143:0crwdne14143:0",
  "portal.renewals.detailConfirmation.householdVehicles": "crwdns13587:0crwdne13587:0",
  "portal.renewals.detailConfirmation.householdVehicles.title": "crwdns13589:0crwdne13589:0",
  "portal.renewals.detailConfirmation.householdVehicles.subtitle": "crwdns13591:0crwdne13591:0",
  "portal.renewals.detailConfirmation.yourConvictions": "crwdns13981:0crwdne13981:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.title": "crwdns13983:0{count}crwdnd13983:0{max}crwdne13983:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.additionalDriverTitle": "crwdns13985:0{driverName}crwdnd13985:0{count}crwdnd13985:0{max}crwdne13985:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.type": "crwdns13987:0crwdne13987:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.date": "crwdns14145:0crwdne14145:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.fine": "crwdns13603:0crwdne13603:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.points": "crwdns13605:0crwdne13605:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.points.value": "crwdns13607:0{value}crwdne13607:0",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.drivingBan": "crwdns13609:0crwdne13609:0",
  "portal.renewals.detailConfirmation.yourConvictions.emptyState.title": "crwdns13989:0crwdne13989:0",
  "portal.renewals.detailConfirmation.yourConvictions.emptyState.subtitle": "crwdns14147:0crwdne14147:0",
  "portal.renewals.detailConfirmation.additionalDriverConvictions": "crwdns13991:0crwdne13991:0",
  "portal.renewals.detailConfirmation.additionalDriverConvictions.tableItem.title": "crwdns13993:0{name}crwdnd13993:0{count}crwdnd13993:0{max}crwdne13993:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims": "crwdns13619:0crwdne13619:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.noBlame": "crwdns13715:0crwdne13715:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.partial": "crwdns13717:0crwdne13717:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.pending": "crwdns13625:0crwdne13625:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.yes": "crwdns13719:0crwdne13719:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.settled": "crwdns13629:0crwdne13629:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.type": "crwdns13631:0crwdne13631:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.fault": "crwdns13721:0crwdne13721:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.description": "crwdns13635:0crwdne13635:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.policy": "crwdns13637:0crwdne13637:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.ncbAffected": "crwdns13639:0crwdne13639:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.injuries": "crwdns13641:0crwdne13641:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.cost": "crwdns14149:0crwdne14149:0",
  "portal.renewals.detailConfirmation.additionalDriverClaims.tableItem.title": "crwdns13645:0{name}crwdnd13645:0{count}crwdnd13645:0{max}crwdne13645:0",
  "portal.renewals.detailConfirmationSidebar.thisYearsPrice": "crwdns13647:0crwdne13647:0",
  "portal.renewals.detailConfirmationSidebar.monthlyPrice": "crwdns13649:0{installments}crwdnd13649:0{price}crwdne13649:0",
  "portal.renewals.detailConfirmationSidebar.needHelpFooter.needHelp": "crwdns14151:0crwdne14151:0",
  "portal.renewals.detailConfirmationSidebar.needHelpFooter.ourTeamAreHere": "crwdns14153:0crwdne14153:0",
  "portal.renewals.detailConfirmationSidebar.needHelpFooter.contactUs": "crwdns14155:0crwdne14155:0",
  "portal.renewals.detailConfirmation.buttonFooter.changeDetails": "crwdns13995:0crwdne13995:0",
  "portal.renewals.detailConfirmation.buttonFooter.update": "crwdns13997:0crwdne13997:0",
  "portal.renewals.detailConfirmation.buttonFooter.confirm": "crwdns13999:0crwdne13999:0",
  // Agent Portal
  "agentPortal.contactDetails.invalidEmail": "crwdns13657:0crwdne13657:0",
  "agentPortal.contactDetails.invalidPhoneNumber": "crwdns13659:0crwdne13659:0",
  "agentPortal.resetPassword.description": "crwdns13661:0crwdne13661:0",
  "agentPortal.resetPassword.acceptTerms": "crwdns13663:0crwdne13663:0"
};