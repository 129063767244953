import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {PAY_BY_CARD_PATH} from "../../../../../router/models/Routes";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../utils/analytics/PaymentAnalytics";
import {
  ApplePayPaymentToken,
  FullPolicyPaymentResult
} from "shared/dist/generated/graphql/resolvers-types";
import {
  useGooglePayPurchaseRebrokePolicy,
  useGooglePayPurchaseRenewal
} from "../../payment-processors/google-pay/GooglePayPolicyPaymentProcessor";
import {
  applePayPurchaseFullPolicyMutation,
  applePayPurchaseFullRenewalMutation
} from "../../payment-processors/apple-pay/ApplePayPolicyPaymentProcessor";
import {useAppSelector} from "../../../../../redux/Hooks";
import AnnualPaymentLanding from "../AnnualPaymentLanding";
import {
  rebrokeQuoteValuesSelector,
  renewalRequestSelector,
  totalPolicyCostSelector
} from "../../../shared/redux/RenewalRequestSelector";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {
  selectExistingPolicyForRenewal,
  selectSelectedQuoteType
} from "../../../../portal/renewals/redux/RenewalSelectors";
import {policyCanBeRenewedOrRebroked} from "../../../../portal/shared/utils/PolicyStatusUtils";

const RenewalAnnualPaymentLanding: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const existingPolicy = useAppSelector(selectExistingPolicyForRenewal);
  const totalUnfinancedAmountPayable = useAppSelector(totalPolicyCostSelector);
  const proposedRenewalRequest = useAppSelector(renewalRequestSelector(policyId));
  const navigate = useNavigate();
  const rebrokeQuoteValues = useAppSelector(rebrokeQuoteValuesSelector);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);

  if (!selectedQuoteType) throw new Error("No Quote Type has been selected");

  useEffect(() => {
    if (!policyCanBeRenewedOrRebroked(existingPolicy)) navigate(`/portal/policy/${policyId}/renewals`);
  }, [existingPolicy, navigate, policyId]);

  const {
    makePayment: makeRenewalPayment,
    loading: renewalPaymentLoading,
    paymentFailed: renewalPaymentFailed,
    resetPaymentFailed: resetRenewalPaymentFailed
  } = useGooglePayPurchaseRenewal(proposedRenewalRequest);

  const {
    makePayment: makeRebrokePayment,
    loading: rebrokePaymentLoading,
    paymentFailed: rebrokePaymentFailed,
    resetPaymentFailed: resetRebrokePaymentFailed
  } = useGooglePayPurchaseRebrokePolicy(rebrokeQuoteValues);

  const onRenewalPaymentWithApplePay = async (payment: ApplePayJS.ApplePayPayment): Promise<FullPolicyPaymentResult> => {
    return await applePayPurchaseFullRenewalMutation(payment, proposedRenewalRequest);
  };

  const onPaymentByCard = (): void => {
    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "annual.type.card",
      action: "Click",
    });
    navigate(PAY_BY_CARD_PATH);
  };

  if (selectedQuoteType === "REBROKE") {
    if (!rebrokeQuoteValues) throw new Error("No rebroke quote has been selected");

    const onRebrokePaymentWithApplePay = async (payment: ApplePayJS.ApplePayPayment): Promise<FullPolicyPaymentResult> => {
      return await applePayPurchaseFullPolicyMutation({
        payment: {
          token: payment.token.paymentData as ApplePayPaymentToken
        },
        proposerEmail: rebrokeQuoteValues.proposerEmail,
        proposerPhoneNumber: rebrokeQuoteValues.proposerPhoneNumber,
        quoteReference: rebrokeQuoteValues.quoteReference,
        quoteSequenceNumber: rebrokeQuoteValues.quoteSequenceNumber
      });
    };

    return <AnnualPaymentLanding
      loading={rebrokePaymentLoading}
      successPagePath={`/portal/policy/${policyId}/renewals/payment/success`}
      totalUnfinancedAmountPayable={totalUnfinancedAmountPayable}
      onPaymentByCard={onPaymentByCard}
      onPaymentWithApplePay={onRebrokePaymentWithApplePay}
      onPaymentWithGooglePay={makeRebrokePayment}
      paymentFailed={rebrokePaymentFailed}
      resetPaymentFailed={resetRebrokePaymentFailed}
    />;
  }

  return <AnnualPaymentLanding
    loading={renewalPaymentLoading}
    successPagePath={`/portal/policy/${policyId}/renewals/payment/success`}
    totalUnfinancedAmountPayable={totalUnfinancedAmountPayable}
    onPaymentByCard={onPaymentByCard}
    onPaymentWithApplePay={onRenewalPaymentWithApplePay}
    onPaymentWithGooglePay={makeRenewalPayment}
    paymentFailed={renewalPaymentFailed}
    resetPaymentFailed={resetRenewalPaymentFailed}
  />;
};

export default RenewalAnnualPaymentLanding;
